export const questionShowColumns = [
    {
        name: 'code',
        title: 'Code',
        value: true
    },
    {
        name: 'type',
        title: 'Loại',
        value: true
    },
    {
        name: 'title',
        title: 'Tiêu đề',
        value: true,
        alwayShow: true,
    },
    {
        name: 'content',
        title: 'Nội dung',
        value: true
    },
    {
        name: 'point',
        title: 'Số G',
        value: true
    },
    {
        name: 'createUser',
        title: 'Người tạo',
        value: true
    },
    {
        name: 'createdAt',
        title: 'Thời điểm tạo',
        value: true
    },
    {
        name: 'explanation',
        title: 'Giải thích',
        value: false
    },
    {
        name: 'fields',
        title: 'Fields',
        value: false
    },
    {
        name: 'tags',
        title: 'Tags',
        value: false
    },
    {
        name: 'keywords',
        title: 'Từ khóa',
        value: false
    },
    {
        name: 'groupId',
        title: 'Nhóm',
        value: false
    },
    {
        name: 'organizationId',
        title: 'Tổ chức',
        value: false
    },
    {
        name: 'countryId',
        title: 'Quốc gia',
        value: false
    },
    {
        name: 'languageId',
        title: 'Ngôn ngữ',
        value: false
    },
    {
        name: 'categoryId',
        title: 'Danh mục',
        value: false
    },
    {
        name: 'positionId',
        title: 'Vị trí',
        value: false
    },
    {
        name: 'status',
        title: 'Trạng thái',
        value: false
    },
    {
        name: 'subjects',
        title: 'Môn học',
        value: false
    },
]