<template>
  <v-container id="transaction" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <transaction-table :filter-form="filterForm" :transactions="transactions" />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FilterForm from "./components/FilterForm.vue";
import TransactionTable from "./components/TransactionTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const TransactionRepository = RepositoryFactory.get("transaction");

export default {
  components: { FilterForm, TransactionTable },
  data() {
    return {
      filterForm: {
        listSender: [],
        listReceiver: [],
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      transactions: [],
      loading: true,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getTransactionList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getTransactionList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getTransactionList();
    // setInterval fetch transactions data
    // this.pollTransactions();

    Bus.$on("transaction-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getTransactionList();
    });
    Bus.$on("transaction-reload", () => {
      this.getTransactionList();
    });
    Bus.$on("transaction-reload-interval", () => {
      this.fetchTransactions();
    });
  },
  beforeDestroy() {
    Bus.$off("transaction-list-filter");
  },
  methods: {
    pollTransactions() {
      this.polling = setInterval(() => {
        Bus.$emit("transaction-reload-interval");
      }, 5000);
    },
    async fetchTransactions() {
      const params = {
        perPage: this.limit,
        page: this.currentPage,
        listSender: this.filterForm.listSender,
        listReceiver: this.filterForm.listReceiver,
      };
      const response = await TransactionRepository.list(params);
      if (response.success) {
        this.transactions = _.get(response, "data.items", []);
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      }
    },
    async getTransactionList() {
      this.loading = true;
      const params = {
        perPage: this.limit,
        page: this.currentPage,
        listSender: this.filterForm.listSender,
        listReceiver: this.filterForm.listReceiver,
      };

      const loader = this.$loading.show();
      const response = await TransactionRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.transactions = _.get(response, "data.items", []);
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
  },
};
</script>
