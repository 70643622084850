<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Tên"
              >
                <v-text-field
                  v-model="age.name"
                  label="Tiêu đề"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Từ tuổi"
                rules="required|min:0"
              >
                <v-text-field
                  v-model="age.min"
                  label="Từ tuổi *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đến tuổi"
                rules="required|max:100"
              >
                <v-text-field
                  v-model="age.max"
                  label="Đến tuổi *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveData)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const AgeRepository = RepositoryFactory.get("age");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      age: {
        name: "",
        min: "",
        max: "",
      },
      isCreate: true,
      pathologies: [],
      errorMessage: "",
    };
  },
  created() {
    Bus.$on("age-show-form", (age) => {
      this.showModal = true;
      this.age = _.cloneDeep(age);
      if (age.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveData() {
      const age = {
        name: _.trim(this.age.name),
        min: +this.age.min,
        max: +this.age.max,
      };
      if (this.isCreate) {
        const loader = this.$loading.show();
        const response = await AgeRepository.create(age);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("age-list-reload");
          });
        }
        loader.hide();
      } else {
        const loader = this.$loading.show();
        const response = await AgeRepository.update(this.age.id, age);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("age-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
