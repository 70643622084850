<template>
  <v-container id="question-list" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <div class="text-sm-right mb-4 mr-4">
      <v-btn
        dark
        color="default"
        v-if="checkAuthorization('question_delete') && questions.filter(i => i.isSelected).length"
        class="mx-0 mr-4"
        @click="deleteQuestions()"
      >
        <v-icon>mdi-delete-forever</v-icon>Xóa
      </v-btn>
      <v-btn
        v-if="checkAuthorization('question_create')"
        class="primary mx-0 mr-4"
        @click="gotoNewPage"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
      <v-btn
        v-if="checkAuthorization('question_download_upload')"
        @click="chooseFile"
        style="margin-right: 10px"
        color="success"
        dark
        class="primary mx-0 mr-4"
      >
        <v-icon class="mr-1">mdi-folder-upload</v-icon> Import
      </v-btn>
      <input
        style="display: none"
        class="file-input"
        ref="questionFileInput"
        type="file"
        @input="showPopup"
        accept=".xlsx"
      />
      <!-- <v-menu
          :internal-activator="true"
          v-model="filterForm.openRowMenu"
          content-class="menu__customer--filter no-contain overflow-visible"
          :close-on-content-click="false"
          offset-y
      >
        <template #activator="{ on: onMenu }">
          <v-tooltip bottom>
            <template #activator="{ on: onTooltip }">
              <v-btn
                  min-width="45"
                  class=""
                  dark
                  v-on="{ ...onMenu, ...onTooltip }"
              >
                <v-icon>mdi-view-column</v-icon> Cột hiển thị
              </v-btn>
            </template>

            <span>Cột hiển thị</span>
          </v-tooltip>
        </template>
        <card-row-attribute
            :filterForm="filterForm"
            :questionShowColumns="questionShowColumns"
        />
      </v-menu> -->
    </div>
    <v-card>
      <v-tabs v-model="tab" grow @change="getQuestionList()">
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="(item, key) in tabs" :key="key">
          {{ item }}
        </v-tab>

        <v-tab-item v-for="(item, key) in tabs" :key="key">
          <question-table
              v-if="questions.length"
              :questions="questions"
              :questionShowColumns="questionShowColumns"
          />
          <common-no-result v-if="!loading && totalItem === 0" />
          <v-row class="mt-0">
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6">
              <v-pagination
                v-if="totalItem > limit"
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem, limit)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import FilterForm from "./components/FilterForm";
import QuestionTable from "./components/QuestionTable";
import CardRowAttribute from "./components/CardRowAttribute";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { questionShowColumns } from "@/pages/questions/question/components/contanst";

const QuestionRepository = RepositoryFactory.get("question");

export default {
  components: {
    QuestionTable,
    FilterForm,
    CardRowAttribute
  },
  data() {
    return {
      filterForm: {
        openRowMenu: false,
      },
      currentPage: 1,
      limit: 50,
      totalItem: 0,
      questions: [],
      loading: false,
      tabs: ["Đang hoạt động", "Ngừng hoạt động"],
      tab: 0,
      questionShowColumns,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getQuestionList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getQuestionList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  created() {
    this.getQuestionList();
    Bus.$on("question-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getQuestionList();
    });

    Bus.$on("question-list-reload", () => {
      this.getQuestionList();
    });
    Bus.$on('question-change-row-attribute', cloneRowAttributes => {
      this.questionShowColumns = cloneRowAttributes.map(s => ({ ...s }));
    });
    Bus.$on('click-select-all-question', (value) => {
      this.questions = this.questions.map(questionIt => ({
        ...questionIt,
        isSelected: !!value
      }));
    });
  },
  beforeDestroy() {
    Bus.$off("question-list-filter");
    Bus.$off("question-change-row-attribute");
    Bus.$off("question-list-reload");
    Bus.$off("click-select-all-question");
  },
  methods: {
    gotoNewPage() {
      this.$router.push({
        name: "CreateQuestion",
      });
    },
    chooseFile() {
      this.$refs.questionFileInput.click();
    },
    showPopup() {
      this.$swal({
        title: "Import câu hỏi",
        text: "Bạn có thực sự chắc chắn ?",
        confirmButtonText: "Đồng ý",
        showCancelButton: true,
        cancelButtonText: "Hủy bỏ",
      }).then(async (res) => {
        if (res.value) {
          await this.onSelectQuestionFile();
        } else {
          this.$refs.questionFileInput.value = "";
        }
      });
    },
    async onSelectQuestionFile() {
      const loader = this.$loading.show();
      const input = this.$refs.questionFileInput;
      const files = input.files;
      if (files && files[0]) {
        const questionFile = files[0];
        const uploadResponse = await QuestionRepository.uploadQuestion(
          questionFile
        );
        loader.hide();
        this.$refs.questionFileInput.value = "";

        const message = _.get(uploadResponse, "data.message", "");
        const title =
          message === "Uploaded successfully"
            ? "Import câu hỏi thành công"
            : message;
        const errorMessage = _.get(uploadResponse, "data.errors", [message])
          .map((err, index) => `<div style="text-align: left; display: inline-block;"> <p style="font-weight: 600;">${index + 1}.<p/> ${err} <div>`)
          .join("<br />");

        if (_.get(uploadResponse, "success", false)) {
          this.$swal({
            title,
            ...(_.get(uploadResponse, "data.errors", []).length && {
              html: errorMessage,
            }),
          });
        } else {
          this.$swal({
            type: "error",
            title: message,
            html: errorMessage,
          });
        }
      }
    },
    async getQuestionList() {
      this.loading = this.$loading.show();
      const response = await QuestionRepository.list({
        ...this.filterForm,
        perPage: this.limit,
        page: this.currentPage,
        openRowMenu: undefined,
        status: this.tab === 0 ? 'ACTIVE' : 'INACTIVE'
      });

      if (response.success) {
        this.errorMessage = "";
        this.questions = _.get(response, "data.items", []).map(item => {
          return {
            ...item,
            showEdit: {
              type: false,
              code: false
            },
            isSelected: false
          }
        });
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      } else {
        this.errorMessage = _.get(response, "message", "");
      }
      this.loading.hide();
    },
    async deleteQuestions () {
        this.$swal({
          title: 'Xóa toàn bộ câu hỏi',
          text: 'Bạn có thực sự muốn xoá các câu hỏi này?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
        }).then(async (result) => {
          if (result.value) {
            const loader = this.$loading.show();
            const selectQuestions = this.questions.filter(i => i.isSelected);
            const response = await QuestionRepository.deleteManyItems(selectQuestions.map(s => s.id))
            loader.hide()
            if (response.success) {
              this.$swal({
                  title: 'Xoá thành công !',
                  confirmButtonText: 'Đồng ý',
                },
              ).then(() => {
                Bus.$emit('question-list-reload')
              })
            } else {
              this.$swal({
                type: 'error',
                title: 'Lỗi !',
                text: _.get(response, 'message', ''),
              })
            }
          }
        })
      },
  },
};
</script>
