<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('notification_create')"
        class="primary mx-0"
        @click="addNewNotification"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Người dùng</th>
            <th>Avatar</th>
            <th>Tên người dùng</th>
            <th>Số điện thoại</th>
            <th>Email</th>
            <th>Tiêu đề</th>
            <th>Nội dung</th>
            <th>Thời điểm</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(notification, index) in notifications">
            <tr :key="notification.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(notification, "userId") }}</td>
              <td>
                <v-list-item-avatar class="align-self-center" color="grey">
                  <img
                    v-if="_.get(notification, 'userInfo.imageUrl', false)"
                    class=""
                    :src="_.get(notification, 'userInfo.imageUrl', '')"
                  />
                  <v-img v-else class="" src="@/assets/default-avatar.jpg" />
                </v-list-item-avatar>
              </td>
              <td>{{ _.get(notification, "userInfo.userName") }}</td>
              <td>{{ _.get(notification, "userInfo.phoneNumber") }}</td>
              <td>{{ _.get(notification, "userInfo.email") }}</td>
              <td>{{ _.get(notification, "title") }}</td>
              <td>{{ _.get(notification, "content") }}</td>
              <td>{{ formatDateTime(notification.createdAt) }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('notification_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editNotification(notification)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('notification_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(notification)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <notification-form :types="types" />
  </div>
</template>
<script>
import NotificationForm from "./NotificationForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const NotificationRepository = RepositoryFactory.get("notification");
import moment from "moment";

export default {
  components: { NotificationForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedNotification: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("notification_update") ||
        this.checkAuthorization("notification_delete")
      );
    },
  },
  methods: {
    formatDateTime(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return null;
    },
    addNewNotification() {
      this.selectedNotification = {};
      Bus.$emit("notification-show-form", this.selectedNotification);
    },
    editNotification(notification) {
      this.selectedNotification = notification;
      Bus.$emit("notification-show-form", this.selectedNotification);
    },
    async onClickDelete(notification) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await NotificationRepository.deleteOneItem(
            notification.id
          );
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("notification-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
