<template>
  <div>
    <div v-if="checkAuthorization('role_create')" class="text-sm-right mb-4">
      <v-btn class="primary mx-0" @click="addNewRole">
        <v-icon>mdi-plus</v-icon>Thêm mới
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên quyền</th>
            <th>Mô tả</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(role, index) in roles">
            <tr :key="role.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(role, "name", "") }}</td>
              <td>{{ _.get(role, "description", "") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('role_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editRole(role)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin quyền</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('role_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="deleteRole(role)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa quyền</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const RoleRepository = RepositoryFactory.get("role");
export default {
  created() {},
  data() {
    return {
      modalAlert: false,
      errorMessage: "",
      tabIndex: 0,
    };
  },
  props: {
    roles: {
      type: Array,
      default: () => [],
    },
    filterForm: {
      default: () => ({}),
    },
  },
  computed: {
    showAction() {
      return (
        this.checkAuthorization("role_delete") ||
        this.checkAuthorization("role_update")
      );
    },
  },
  methods: {
    editRole(role) {
      this.$router.push({
        name: "EditRole",
        params: {
          id: _.get(role, "id", 0),
        },
      });
    },
    addNewRole() {
      this.$router.push({
        // name: 'CreateRole',
        path: "create-role",
      });
    },
    deleteRole(role) {
      this.$swal({
        title: "Xoá quyền",
        text: "Bạn có thực sự muốn xoá quyền này?",
        type: "warn",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Huỷ bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const response = await RoleRepository.delete(_.get(role, "id", 0));
          loader.hide();
          if (response.success) {
            this.$swal({
              title: "Xoá thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("role-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(response, "message", ""),
            });
          }
        }
      });
    },
    changeTab() {
      this.currentPage = 1;
      Bus.$emit("role-list-filter", this.filterForm);
    },
  },
};
</script>

<style scoped>
.v-tab {
  max-width: unset;
  width: 50%;
}
</style>