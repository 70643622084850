<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template> Cập nhật bình luận </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Nội dung"
              >
                <v-textarea
                  v-model="comment.content"
                  label="Nội dung *"
                  :error-messages="errors"
                  color="primary"
                  rows="10"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveComment)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const CommentRepository = RepositoryFactory.get("comment");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      comment: {
        content: "",
      },
      isCreate: true,
      errorMessage: "",
    };
  },
  created() {
    Bus.$on("comment-show-form", (comment) => {
      this.showModal = true;
      this.comment = _.cloneDeep(comment);
      if (comment.id) this.isCreate = false;
      else this.isCreate = true;
    });

    Bus.$on("update-comment", (comment) => {
      this.isCreate = false;
      this.comment = _.cloneDeep(comment);
      this.saveComment();
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveComment() {
      const comment = {
        content: _.trim(this.comment.content),
      };
      const loader = this.$loading.show();
      const response = await CommentRepository.update(this.comment.id, comment);
      if (!response.success) {
        this.errorMessage = _.get(response, "message", "");
      } else {
        this.showModal = false;
        this.errorMessage = "";
        this.$swal({
          title: "Cập nhật thành công",
          confirmButtonText: "Đồng ý",
        }).then(() => {
          Bus.$emit("comment-list-reload");
        });
      }
      loader.hide();
    },
  },
  beforeDestroy() {
    Bus.$off("update-comment");
    Bus.$off("comment-show-form");
  },
};
</script>
