<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('social_create')"
        class="primary mx-0"
        @click="addNewSocial"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Tên MXH</th>
            <th>Hình ảnh</th>
            <th>Thứ tự (tăng dần)</th>
            <th>Liên kết</th>
            <th>Trạng thái</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(social, index) in socials">
            <tr :key="social.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(social, "title") }}</td>
              <td>
                <div class="d-inline-flex align-center">
                  <v-img :src="_.get(social, 'iconUrl')" max-width="50" />
                </div>
              </td>
              <td>{{ _.get(social, "stt") }}</td>
              <td>{{ _.get(social, "link") }}</td>
              <td>
                <v-chip :color="getStatusColor(social.status)" dark>
                  {{ getStatusText(social.status) }}
                </v-chip>
              </td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('social_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editSocial(social)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('social_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(social)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <social-form />
  </div>
</template>
<script>
import SocialForm from "./SocialForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const SocialRepository = RepositoryFactory.get("social");
import { socialStatusColor } from "../../../helpers/constants";

export default {
  components: { SocialForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    socials: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedSocial: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("social_update") ||
        this.checkAuthorization("social_delete")
      );
    },
  },
  methods: {
    getStatusColor(status) {
      return socialStatusColor[status] || "gray";
    },
    getStatusText(status) {
      return status == "ACTIVE" ? "Hoạt động" : "Tạm ngưng";
    },
    addNewSocial() {
      this.selectedSocial = {};
      Bus.$emit("social-show-form", this.selectedSocial);
    },
    editSocial(social) {
      this.selectedSocial = social;
      Bus.$emit("social-show-form", this.selectedSocial);
    },
    async onClickDelete(social) {
      this.$swal({
        title: "Xóa social",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await SocialRepository.delete(social.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("social-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
