<template>
    <v-container id="document-training" fluid tag="section">
        <v-card class="mt-2">
            <v-row class="mx-0">
                <v-col cols="4" class="document-tree">
                    <training-tree />
                </v-col>
                <v-col cols="8">
                    <training-content />
                </v-col>
            </v-row>
        </v-card>

        <folder-form />
        <file-form />
        <full-screen-dialog />
    </v-container>  
</template>

<script>
import TrainingContent from './components/TrainingContent'
import TrainingTree from './components/TrainingTree'
import FullScreenDialog from './components/FullScreenDialog'
import FolderForm from './components/FolderForm'
import FileForm from './components/FileForm'
export default {
    components: {
        TrainingContent,
        TrainingTree,
        FullScreenDialog,
        FolderForm,
        FileForm
    },
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
.document-tree {
    border-right: 3px solid #e3e3e3
}
</style>