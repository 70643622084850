<template>
  <v-container id="customer-profile" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <div class="text-sm-right mb-4">
      <!-- <v-btn
        v-if="checkAuthorization('customer_create')"
        class="primary mx-0"
        @click="addNewCustomer"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn> -->
    </div>
    <v-card>
      <v-tabs v-model="tab" grow @change="getCustomerList()">
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="(item, key) in tabs" :key="key">
          {{ item }}
        </v-tab>

        <v-tab-item v-for="(item, key) in tabs" :key="key">
          <customer-table
            v-if="users.length"
            :filter-form="filterForm"
            :users="users"
          />
          <common-no-result v-if="!loading && totalItem === 0" />
          <v-row class="mt-0">
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6">
              <v-pagination
                v-if="totalItem > limit"
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <reset-password-form />
  </v-container>
</template>

<script>
import ResetPasswordForm from "./components/ResetPasswordForm";
import FilterForm from "./components/FilterForm.vue";
import CustomerTable from "./components/CustomerTable";
import RepositoryFactory from "../../repositories/RepositoryFactory";

const CustomerRepository = RepositoryFactory.get("customer");

export default {
  components: {
    CustomerTable,
    FilterForm,
    ResetPasswordForm,
  },
  data() {
    return {
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      users: [],
      loading: false,
      tabs: ["Đang hoạt động", "Ngừng hoạt động", "Chờ phê duyệt"],
      tab: 0,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getCustomerList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getCustomerList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  created() {
    this.getCustomerList();
    Bus.$on("customer-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getCustomerList();
    });

    Bus.$on("customer-list-reload", () => {
      this.getCustomerList();
    });
  },
  beforeDestroy() {
    Bus.$off("customer-list-filter");
  },
  methods: {
    addNewCustomer() {
      const route = this.$router.resolve({
        name: "CreateCustomer",
      });
      window.open(route.href, "_self");
    },
    async getCustomerList() {
      this.loading = true;
      const params = {
        perPage: this.limit,
        page: this.currentPage,
        userName: this.filterForm.keyword,
        position: this.filterForm.position,
        status: this.tab == 0 ? "ACTIVE" : this.tab == 1 ? "INACTIVE" : "DRAFF",
        phoneNumber: this.filterForm.phoneNumber,
        email: this.filterForm.email,
      };
      const loader = this.$loading.show();
      const response = await CustomerRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.users = _.get(response, "data.items", []);
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
  },
};
</script>
