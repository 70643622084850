<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Tạo yêu cầu </template>
          <template v-else> Cập nhật yêu cầu </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6 pt-6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Người dùng *"
          >
            <v-autocomplete
              v-model="recharge.userId"
              :items="customers"
              :error-messages="errors"
              item-text="userName"
              item-value="id"
              label="Người dùng *"
              color="primary"
              no-data-text="Không có dữ liệu"
              readonly="isReadOnly(isCreate)"
            >
            </v-autocomplete>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required"
            name="Số G"
          >
            <v-text-field
              v-model="recharge.totalPoint"
              label="Số G *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required"
            name="Số tiền tương ứng"
          >
            <v-text-field
              v-model="recharge.totalMoney"
              label="Số tiền tương ứng *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" class="mb-2" name="Nội dung">
            <v-textarea
              v-model="recharge.content"
              label="Nội dung"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }">
            <v-select
              v-model="recharge.status"
              label="Trạng thái"
              no-data-text="Không có dữ liệu"
              :items="rechargeStatusOptions"
              item-text="text"
              item-value="value"
              :error-messages="errors"
              color="primary"
              readonly="isReadOnly(isCreate)"
            >
              <template v-slot:item="{ attrs, item, on }">
                <v-list-item
                  v-bind="attrs"
                  active-class="secondary elevation-4 white--text"
                  class="mx-3 mb-3 v-sheet"
                  elevation="0"
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveRecharge)">
            Cập nhật
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  rechargeStatusOptions,
  PAGE_LIMIT,
  rechargeStatus,
} from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const RechargeRepository = RepositoryFactory.get("recharge");
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      recharge: {},
      isCreate: true,
      errorMessage: "",
      statusOptions: [],
      inputFile: [],
      icons: [],
      customers: [],
      rechargeStatusOptions: rechargeStatusOptions,
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  async mounted() {
    await this.getCustomerAll();
  },
  async created() {
    Bus.$on("recharge-show-form", (recharge) => {
      this.showModal = true;
      this.recharge = _.cloneDeep(recharge);
      if (recharge.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    isReadOnly(status) {
      return !status;
    },
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async getCustomerAll() {
      let res = await CustomerRepository.list({ perPage: PAGE_LIMIT });
      if (res.success) {
        const listCustomers = _.get(res, "data.items", []);
        this.customers = listCustomers.map((o) => {
          return {
            id: o.id,
            userName: `${o.userName} - ${o.phoneNumber} - ${o.email}`,
          };
        });
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(res, "message", ""),
        });
      }
    },
    async saveRecharge() {
      const recharge = {
        totalMoney: _.trim(this.recharge.totalMoney),
        totalPoint: _.trim(this.recharge.totalPoint),
        content: _.trim(this.recharge.content),
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await RechargeRepository.create({
          userId: this.recharge.userId,
          ...recharge,
        });
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("recharge-reload");
          });
        }
      } else {
        response = await RechargeRepository.update(this.recharge.id, recharge);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("recharge-reload");
          });
        }
      }
    },
  },
};
</script>
