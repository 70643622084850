<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('group_create')"
        class="primary mx-0"
        @click="addNewGroup"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Id</th>
            <th>Ảnh bìa</th>
            <th>Tên nhóm</th>
            <th>Mô tả</th>
            <th>Tổng thành viên</th>
            <th>Tổng câu hỏi</th>
            <th>Quản trị viên</th>
            <th>Trạng thái</th>
            <th>Thời điểm tạo</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(group, index) in groups">
            <tr :key="group.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(group, "id") }}</td>
              <td class="group-image">
                <img
                  v-if="_.get(group, 'imageUrl')"
                  class=""
                  :src="_.get(group, 'imageUrl', '')"
                  width="108"
                  height="108"
                />
              </td>
              <td>{{ _.get(group, "name") }}</td>
              <td>{{ _.get(group, "description") }}</td>
              <td>{{ _.get(group, "totalMember") }}</td>
              <td>{{ _.get(group, "totalQuestion") }}</td>
              <td>{{ _.get(group, "owner.userName") }}</td>
              <td>
                <v-chip :color="getStatusColor(group.status)" dark>
                  {{ getStatusText(group.status) }}
                </v-chip>
              </td>
              <td>{{ formatDateTime(group.createdAt) }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('group_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="viewMember(group, 'add')"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </template>
                    <span>Thêm thành viên</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('group_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="viewMember(group, 'view')"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-eye</v-icon></v-btn
                      >
                    </template>
                    <span>Xem thành viên</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('group_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editGroup(group)"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('group_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(group)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <group-form />
    <view-member-modal />
  </div>
</template>
<script>
import GroupForm from "./GroupForm.vue";
import ViewMemberModal from "./ViewMemberModal";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const GroupRepository = RepositoryFactory.get("group");
import moment from "moment";
import { groupStatusColor } from "../../../helpers/constants";

export default {
  components: { GroupForm, ViewMemberModal },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedGroup: {},
      modalAlert: false,
      errorMessage: "",
      groupStatusColor: {},
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("group_update") ||
        this.checkAuthorization("group_delete")
      );
    },
  },
  methods: {
    getStatusColor(status) {
      return groupStatusColor[status] || "gray";
    },
    getStatusText(status) {
      return status == "PUBLIC" ? "Công khai" : "Riêng tư";
    },
    formatDateTime(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return null;
    },
    addNewGroup() {
      this.selectedGroup = {};
      Bus.$emit("group-show-form", this.selectedGroup);
    },
    editGroup(group) {
      this.selectedGroup = group;
      Bus.$emit("group-show-form", this.selectedGroup);
    },
    async onClickDelete(group) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await GroupRepository.delete(group.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công!",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("group-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    viewMember(group, type) {
      Bus.$emit("view-member-show-modal", group, type);
    },
  },
};
</script>

<style scoped>
/deep/.group-image > img {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  border: 4px solid rgb(255, 255, 255);
  border-radius: 100%;
}
</style>