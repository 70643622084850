<template>
  <base-material-card outlined icon="mdi-account-outline">
    <template v-slot:after-heading>
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <div class="font-weight-light card-title mt-2">
        Chỉnh sửa hồ sơ
        <span class="body-1">— Hoàn thành hồ sơ của bạn</span>
      </div>
    </template>

    <v-form>
      <validation-observer v-slot="{ handleSubmit }">
        <v-container class="py-0">
          <v-row>
            <v-col cols="12" md="3">
              <div class="edit-profile-avatar text-center">
                <div class="choose-image" @click="chooseImage">
                  <img
                    v-if="_.get(user, 'avatar.id', null)"
                    :src="_.get(user, 'avatar.filePath')"
                    style="width: 100%"
                  />
                  <v-img v-else src="../../../assets/default-avatar.jpg" />
                </div>
                <input
                  ref="fileInput"
                  style="display: none"
                  type="file"
                  accept="image/*"
                  @input="onSelectFile"
                />
              </div>
            </v-col>
            <v-col cols="12" md="9">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.username"
                    disabled
                    class="purple-input"
                    label="Tên tài khoản"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email|max:255"
                    name="Địa chỉ email"
                  >
                    <v-text-field
                      v-model="user.email"
                      label="Địa chỉ email *"
                      :error-messages="errors"
                      class="purple-input"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:255"
                    name="Họ và tên đệm"
                  >
                    <v-text-field
                      v-model="user.lastName"
                      label="Họ và tên đệm *"
                      class="purple-input"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:255"
                    name="Tên"
                  >
                    <v-text-field
                      v-model="user.firstName"
                      label="Tên *"
                      class="purple-input"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="user.birthday"
                        color="primary"
                        label="Ngày sinh"
                        prepend-icon="mdi-calendar-outline"
                        readonly
                        class="ct-field-birthday"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="user.birthday"
                      color="secondary"
                      scrollable
                      :max="currentDate"
                      @change="
                        $refs.menu.save(user.birthday);
                        menu = false;
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Giới tính"
                  >
                    <v-autocomplete
                      v-model="user.gender"
                      attach
                      :items="userGenderOptions"
                      :error-messages="errors"
                      item-text="text"
                      item-value="value"
                      label="Giới tính"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    :value="
                      _.get(
                        userPositionOptions.find(
                          (position) => position.value === user.position
                        ),
                        'text',
                        ''
                      )
                    "
                    disabled
                    label="Chức danh"
                    class="purple-input"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{ regex: regex.isVNPhoneMobile, max: 255 }"
                    name="Số điện thoại"
                  >
                    <v-text-field
                      v-if="isAdmin()"
                      v-model="user.phone"
                      class="purple-input"
                      label="Điện thoại"
                      :error-messages="errors"
                    />
                    <v-text-field
                      v-else
                      v-model="user.phone"
                      class="purple-input"
                      label="Điện thoại"
                      :error-messages="errors"
                      readonly
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn class="mr-2" @click="cancelUpdateProfile"> Huỷ bỏ </v-btn>
              <v-btn
                color="primary"
                class="mr-0"
                @click.prevent="handleSubmit(updateProfile)"
              >
                Cập nhật
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </validation-observer>
    </v-form>
  </base-material-card>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import {
  regex,
  userGenderOptions,
  userPositionOptions,
  userPositions,
} from "../../../helpers/constants";
const ImageRepository = RepositoryFactory.get("image");
const AuthRepository = RepositoryFactory.get("auth");
export default {
  name: "EditProfileForm",
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      errorMessage: "",
      regex,
      userGenderOptions,
      userPositionOptions,
      datePicker: false,
      loading: false,
      menu: false,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Kích thước hình đại diện phải nhỏ hơn 2 MB!",
      ],
      // birthday: this.birthday,
    };
  },
  computed: {
    currentDate() {
      const d = new Date();
      return d.toISOString().slice(0, 10);
    },
  },
  created() {},
  methods: {
    cancelUpdateProfile() {
      this.$router.go({
        path: "/profile",
      });
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    async onSelectFile() {
      const loader = this.$loading.show();
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files.length) {
        await this.uploadOneFile(files[0]);
      }
      loader.hide();
    },
    async uploadOneFile(file) {
      const uploadResponse = await ImageRepository.upload(file);
      if (_.get(uploadResponse, "success", false)) {
        const newImage = _.get(uploadResponse, "data", {});
        const avatar = {
          id: _.get(newImage, "id", null),
          filePath: _.get(newImage, "filePath", ""),
        };
        this.$set(this.user, "avatar", avatar);
        this.$set(this.user, "avatarId", avatar.id);
      } else {
        this.$swal("Upload ảnh đại diện không thành công");
      }
    },
    async updateProfile() {
      const postResponse = {
        avatarId: this.user.avatarId,
        firstName: _.trim(this.user.firstName),
        lastName: _.trim(this.user.lastName),
        phone: this.user.phone,
        email: this.user.email,
        birthday: this.user.birthday,
        gender: this.user.gender,
      };
      const loader = this.$loading.show();
      const response = await AuthRepository.updateProfile(postResponse);
      loader.hide();
      if (_.get(response, "success", false)) {
        this.errorMessage = "";
        this.$swal({
          title: "Cập nhật thông tin thành công !",
          confirmButtonText: "Đồng Ý",
        }).then(() => {
          // window.location.reload()
          this.$router.go({
            path: "/profile",
          });
        });
      } else {
        this.errorMessage = _.get(response, "message", "");
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.v-card--custom
  .v-card--material
    overflow: visible

.ct-field-birthday
  -ms-flex-direction: row-reverse
  flex-direction: row-reverse

.edit-profile-avatar
  .choose-image
    position: relative
    width: 220px
    height: 220px
    margin: auto
    display: -webkit-box
    display: -ms-flexbox
    display: flex
    margin-top: 25px
    -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12)
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12)
</style>
