import { render, staticRenderFns } from "./SupportForm.vue?vue&type=template&id=2aca5b97&scoped=true"
import script from "./SupportForm.vue?vue&type=script&lang=js"
export * from "./SupportForm.vue?vue&type=script&lang=js"
import style0 from "./SupportForm.vue?vue&type=style&index=0&id=2aca5b97&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aca5b97",
  null
  
)

export default component.exports