<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col cols="6" sm="6">
                  <v-text-field
                    v-model="filterForm.name"
                    label="Tìm kiếm theo tên"
                    placeholder="Tìm kiếm theo tên"
                    clearable
                    @keyup.enter="searchGroup"
                  />
                </v-col>
                <v-col cols="6" sm="6">
                  <v-text-field
                    v-model="filterForm.description"
                    label="Tìm kiếm theo mô tả"
                    placeholder="Tìm kiếm theo mô tả"
                    clearable
                    @keyup.enter="searchGroup"
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchGroup">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    searchGroup() {
      Bus.$emit("group-list-filter", this.filterForm);
    },
  },
};
</script>
