<template>
  <div>
    <!-- <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('withdraw_create')"
        class="primary mx-0"
        @click="addNewWithdraw"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div> -->

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Avatar</th>
            <th>Tài khoản</th>
            <th>Ảnh tài khoản nhận tiền</th>
            <th>Số G</th>
            <th>Số tiền tương ứng</th>
            <th>Nội dung</th>
            <th>Thời điểm</th>
            <th>Trạng thái</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(withdraw, index) in withdraws">
            <tr :key="withdraw.id">
              <td>{{ index + 1 }}</td>
              <td>
                <v-list-item-avatar class="align-self-center" color="grey">
                  <img
                    v-if="_.get(withdraw, 'owner.imageUrl', false)"
                    class=""
                    :src="_.get(withdraw, 'owner.imageUrl', '')"
                  />
                  <v-img v-else class="" src="@/assets/default-avatar.jpg" />
                </v-list-item-avatar>
              </td>
              <td
                v-if="_.get(withdraw, 'owner', false)"
                style="min-width: 230px; text-align: center"
              >
                <div style="padding-top: 15px">
                  <div>
                    <span
                      style="padding: 10px; display: flex; align-item: center"
                      ><v-icon
                        color="white"
                        style="padding-right: 10px; color: black !important"
                        >mdi-account-box-outline</v-icon
                      >
                      {{ _.get(withdraw, "owner.userName") }}</span
                    >
                  </div>
                  <div>
                    <span
                      style="padding: 10px; display: flex; align-item: center"
                      ><v-icon
                        color="white"
                        style="padding-right: 10px; color: black !important"
                        >mdi-cast</v-icon
                      >
                      {{ _.get(withdraw, "owner.phoneNumber") }}</span
                    >
                  </div>
                  <div style="padding: 10px; display: flex; align-item: center">
                    <span
                      ><v-icon
                        color="white"
                        style="padding-right: 8px; color: black !important"
                        >mdi-email-outline</v-icon
                      >
                      {{ _.get(withdraw, "owner.email") }}</span
                    >
                  </div>
                  <div
                    style="
                      padding: 10px;
                      display: flex;
                      align-item: center;
                      cursor: pointer;
                    "
                  >
                    <v-icon
                      color="white"
                      style="padding-right: 8px; color: black !important"
                      >mdi-wallet</v-icon
                    >
                    {{ _.get(withdraw, "owner.id") }}
                  </div>
                  <div
                    style="
                      padding: 10px;
                      display: flex;
                      align-item: center;
                      cursor: pointer;
                    "
                  >
                    <v-icon
                      color="white"
                      style="padding-right: 8px; color: black !important"
                      >mdi-cash</v-icon
                    >
                    {{ _.get(withdraw, "owner.totalPoint") }} G
                  </div>
                </div>
              </td>
              <td v-else>System</td>

              <td style="padding: 10px 0; cursor: pointer">
                <a
                  v-if="_.get(withdraw, 'imageUrls', false)"
                  href=""
                  target="_blank"
                >
                  <v-img
                    max-height="400"
                    width="200"
                    :src="_.get(withdraw, 'imageUrls', '')[0]"
                  />
                </a>
              </td>

              <td>{{ _.get(withdraw, "totalPoint") }}</td>
              <td>{{ formatPrice(_.get(withdraw, "totalMoney")) }} VND</td>

              <td>{{ _.get(withdraw, "content") }}</td>
              <td>{{ _.get(withdraw, "createdAt") }}</td>
              <td>
                <v-chip :color="getColor(withdraw.status)" dark>
                  {{ withdrawStatus[withdraw.status] }}
                </v-chip>
              </td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('withdraw_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="handleApproveWithdraw(withdraw)"
                        size="25"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        v-if="withdraw.status == withdrawStatus.PENDING"
                        ><v-icon>mdi-account-check</v-icon></v-btn
                      >
                    </template>
                    <span>Xử lý yêu câu</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('withdraw_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editWithdraw(withdraw)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        v-if="withdraw.status == withdrawStatus.PENDING"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('withdraw_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(withdraw)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        v-if="withdraw.status == withdrawStatus.PENDING"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <withdraw-form />
    <change-status-form />
  </div>
</template>
<script>
import WithdrawForm from "./WithdrawForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const WithdrawRepository = RepositoryFactory.get("withdraw");
import ChangeStatusForm from "./ChangeStatusForm.vue";

import {
  withdrawStatus,
  withdrawStatusColor,
} from "../../../helpers/constants";
import moment from "moment";

export default {
  components: { WithdrawForm, ChangeStatusForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    withdraws: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedWithdraw: {},
      modalAlert: false,
      errorMessage: "",
      withdrawStatus,
      withdrawStatusColor,
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("withdraw_update") ||
        this.checkAuthorization("withdraw_delete")
      );
    },
  },
  methods: {
    getColor(status) {
      return withdrawStatusColor[status] || "gray";
    },
    formatDateTime(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return null;
    },
    addNewWithdraw() {
      this.selectedWithdraw = {};
      Bus.$emit("withdraw-show-form", this.selectedWithdraw);
    },
    editWithdraw(withdraw) {
      this.selectedWithdraw = withdraw;
      Bus.$emit("withdraw-show-form", this.selectedWithdraw);
    },
    handleApproveWithdraw(withdraw) {
      this.selectedWithdraw = withdraw;
      Bus.$emit("withdraw-show-form-handle-status", this.selectedWithdraw);
    },
    async onClickDelete(withdraw) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await WithdrawRepository.deleteOneItem(withdraw.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("withdraw-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
