<template>
  <div>
    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th class="th-comment">Bình luận</th>
            <th>Avatar</th>
            <th>Tài khoản</th>
            <th>Thời điểm</th>
            <th class="th-question">Câu hỏi</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(comment, index) in comments">
            <tr :key="comment.id">
              <td>{{ index + 1 }}</td>
              <td class="th-comment">{{ _.get(comment, "content") }}</td>
              <td>
                <img
                  v-if="_.get(comment, 'owner.imageUrl')"
                  class=""
                  :src="_.get(comment, 'owner.imageUrl', '')"
                  width="50"
                  height="50"
                />
              </td>
              <td>{{ _.get(comment, "owner.userName") }}</td>
              <td>{{ _.get(comment, "createdAt") }}</td>
              <td class="th-question">
                <a
                  :href="`/questions/question-list/edit/${comment.questionId}`"
                  target="_blank"
                  class="question-content"
                >
                  <vue-mathjax
                    style="font-size: 16px"
                    :formula="
                      comment.question &&
                      comment.question.question &&
                      comment.question.question.length > 150
                        ? comment.question.question.slice(0, 150) + '...'
                        : comment.question.question
                    "
                    :safe="false"
                  ></vue-mathjax
                ></a>
              </td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('answer_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editComment(comment)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('answer_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="onClickDelete(comment)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <comment-form />
  </div>
</template>
<script>
import CommentForm from "./CommentForm.vue";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
import { VueMathjax } from "vue-mathjax";

const CommentRepository = RepositoryFactory.get("comment");
export default {
  components: { CommentForm, VueMathjax },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedComment: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("answer_update") ||
        this.checkAuthorization("answer_delete")
      );
    },
  },
  methods: {
    addNewComment() {
      this.selectedComment = {};
      Bus.$emit("comment-show-form", this.selectedComment);
    },
    editComment(comment) {
      this.selectedComment = comment;
      Bus.$emit("comment-show-form", this.selectedComment);
    },
    async onClickDelete(comment) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await CommentRepository.delete(comment.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("comment-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    updateComment(comment, errors) {
      if (!errors.length) {
        Bus.$emit("update-comment", comment);
      }
    },
  },
};
</script>

<style lang="css">
.th-comment,
.th-question {
  max-width: 300px;
  text-align: justify;
}
.question-content {
  text-decoration: none !important;
  color: black !important;
}
.question-content:hover {
  color: rgb(4, 0, 255) !important;
}
</style>
