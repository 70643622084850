<template>
  <v-container id="group" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <group-table :groups="groups" />
    <common-no-result v-if="!loading && totalItem === 0" />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FilterForm from "./components/FilterForm.vue";
import GroupTable from "./components/GroupTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";

const GroupRepository = RepositoryFactory.get("group");
export default {
  components: { FilterForm, GroupTable },
  data() {
    return {
      filterForm: {
        keyword: "",
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      groups: [],
      loading: false,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getGroupList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getGroupList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getGroupList();
    Bus.$on("group-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getGroupList();
    });
    Bus.$on("group-list-reload", () => {
      this.getGroupList();
    });
  },
  beforeDestroy() {
    Bus.$off("group-list-filter");
  },
  methods: {
    async getGroupList() {
      this.loading = true;
      const params = {
        perPage: this.limit,
        page: this.currentPage,
        name: this.filterForm.name,
        description: this.filterForm.description,
      };
      const loader = this.$loading.show();
      const response = await GroupRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.groups = _.get(response, "data.items", []);
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
  },
};
</script>
