<template>
  <v-dialog v-model="showModal" max-width="1000" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">{{ errorMessage }}</v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">Thêm mới thành viên</template>
          <template v-else>Cập nhật thông tin thành viên</template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12" md="4">
              <div class="upload-image upload-image--user">
                <v-tooltip bottom v-if="_.get(user, 'imageUrl', null)">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="delete-img"
                      @click="deleteImage"
                      icon
                      small
                      color="default"
                      v-on="on"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </template>
                  <span>Xóa ảnh đại diện</span>
                </v-tooltip>
                <img
                  v-if="_.get(user, 'imageUrl', null)"
                  :src="_.get(user, 'imageUrl')"
                  @click="chooseImage"
                  style="width: 100%; border-radius: 100%"
                />
                <v-img
                  v-else
                  src="@/assets/default-avatar.jpg"
                  @click="chooseImage"
                />
                <input
                  ref="fileInput"
                  style="display: none"
                  type="file"
                  accept="image/*"
                  @input="onSelectFile"
                />
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    :rules="{
                      required: true,
                      max: 50,
                    }"
                    name="Tên đăng nhập"
                  >
                    <v-text-field
                      v-model="user.userName"
                      label="Tên đăng nhập *"
                      :error-messages="errors"
                      color="primary"
                      :readonly="!isCreate"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    name="Số điện thoại"
                    :rules="{ regex: regex.isVNPhoneMobile, required: true }"
                  >
                    <v-text-field
                      v-model="user.phoneNumber"
                      label="Số điện thoại *"
                      :error-messages="errors"
                      color="primary"
                      type="number"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    rules="required|max:50"
                    name="Họ tên"
                  >
                    <v-text-field
                      v-model="user.name"
                      label="Họ tên *"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    rules="required"
                    name="Chức danh"
                  >
                    <v-autocomplete
                      v-model="user.position"
                      :items="userPositionOptions"
                      :error-messages="errors"
                      item-text="text"
                      item-value="value"
                      label="Chức danh *"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="isCreate" cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{
                      required: true,
                      min: 6,
                      regex: regex.isPassword,
                      max: 20,
                    }"
                    name="Mật khẩu"
                    vid="password"
                  >
                    <v-text-field
                      v-model="user.password"
                      label="Mật khẩu *"
                      :error-messages="errors"
                      color="primary"
                      :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="() => (visiblePassword = !visiblePassword)"
                      :type="visiblePassword ? 'password' : 'text'"
                    />
                  </validation-provider>
                </v-col>
                <v-col v-if="isCreate" cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Nhập lại mật khẩu"
                    rules="required|confirmed:password"
                  >
                    <v-text-field
                      v-model="user.confirm"
                      label="Xác nhận lại mật khẩu *"
                      :error-messages="errors"
                      color="primary"
                      :append-icon="
                        visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="
                        () => (visibleConfirmPassword = !visibleConfirmPassword)
                      "
                      :type="visibleConfirmPassword ? 'password' : 'text'"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    name="Email"
                    :rules="{
                      max: 255,
                      email: true,
                      regex: regex.isEmail,
                    }"
                  >
                    <v-text-field
                      v-model="user.email"
                      label="Email"
                      :error-messages="errors"
                      color="primary"
                      type="email"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Nhóm quyền"
                  >
                    <v-autocomplete
                      v-model="user.roleId"
                      :items="roles"
                      :error-messages="errors"
                      item-text="name"
                      item-value="id"
                      label="Thiết lập nhóm quyền *"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveUser)"
            >Lưu lại</v-btn
          >
          <v-spacer />

          <v-btn color="primary" @click="hideModal">Hủy bỏ</v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import { regex, userPositionOptions } from "../../../helpers/constants";
const ManagerRepository = RepositoryFactory.get("manager");
const RoleRepository = RepositoryFactory.get("role");
const ImageRepository = RepositoryFactory.get("image");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    departments: {
      default: () => [],
    },
  },
  data() {
    return {
      regex,
      showModal: false,
      user: {},
      isCreate: true,
      roles: [],
      userPositionOptions,
      errorMessage: "",
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Kích thước hình đại diện phải nhỏ hơn 2 MB!",
      ],
      visibleConfirmPassword: true,
      visiblePassword: true,
    };
  },
  created() {
    this.getRoleList();
    Bus.$on("manager-show-manager-form", (user) => {
      this.user = _.cloneDeep(user);
      if (user.id) this.isCreate = false;
      else this.isCreate = true;
      this.showModal = true;
    });
  },
  methods: {
    deleteImage() {
      this.$set(this.user, "imageUrl", null);
    },
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },

    async getRoleList() {
      const response = await RoleRepository.list({ perPage: 1000 });
      if (_.get(response, "success", false)) {
        this.roles = _.get(response, "data.items");
      }
    },
    chooseImage() {
      this.$refs.fileInput.click();
    },
    async onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files.length) {
        await this.uploadOneFile(files[0]);
      }
    },
    async uploadOneFile(file) {
      const uploadResponse = await ImageRepository.upload(file);
      if (_.get(uploadResponse, "success", false)) {
        const imageUrl = _.get(uploadResponse, "data.data.path", "");
        if (imageUrl) this.$set(this.user, "imageUrl", imageUrl);
      } else {
        this.$swal("Upload ảnh đại diện không thành công");
      }
    },
    async saveUser() {
      if (this.isCreate) {
        const manager = {
          password: this.user.password,
          name: _.trim(this.user.name),
          userName: _.trim(this.user.userName),
          position: this.user.position,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber,
          roleId: this.user.roleId,
          imageUrl: this.user.imageUrl,
        };
        const loader = this.$loading.show();
        const response = await ManagerRepository.create(manager);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm tài khoản thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("manager-list-reload");
          });
        }
      } else {
        const manager = {
          name: _.trim(this.user.name),
          userName: _.trim(this.user.userName),
          position: this.user.position,
          email: this.user.email,
          phoneNumber: this.user.phoneNumber,
          roleId: this.user.roleId,
          imageUrl: this.user.imageUrl,
        };
        const loader = this.$loading.show();
        const response = await ManagerRepository.update(this.user.id, manager);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật tài khoản thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("manager-list-reload");
          });
        }
      }
    },
  },
};
</script>
