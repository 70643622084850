<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('field_create')"
        class="primary mx-0"
        @click="addNewField"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Code</th>
            <th>Icon</th>
            <th>Lĩnh vực</th>
            <th>Đường dẫn</th>
            <th>Thứ tự ưu tiên</th>
            <th>Danh mục</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(field, index) in fields">
            <tr :key="field.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(field, "code") }}</td>
              <td>
                <img
                  v-if="_.get(field, 'imageUrl')"
                  class=""
                  :src="_.get(field, 'imageUrl', '')"
                  width="108"
                  height="108"
                />
              </td>
              <td>{{ _.get(field, "name") }}</td>
              <td>{{ _.get(field, "slug") }}</td>
              <td>{{ _.get(field, "priority") }}</td>
              <td>{{ _.get(field, "categoryName") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('field_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editField(field)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin đơn vị</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('field_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="onClickDelete(field)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <field-form />
  </div>
</template>
<script>
import FieldForm from "./FieldForm.vue";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const FieldRepository = RepositoryFactory.get("field");
export default {
  components: { FieldForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedField: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("field_update") ||
        this.checkAuthorization("field_delete")
      );
    },
  },
  methods: {
    addNewField() {
      this.selectedField = {};
      Bus.$emit("field-show-form", this.selectedField);
    },
    editField(field) {
      this.selectedField = field;
      Bus.$emit("field-show-form", this.selectedField);
    },
    async onClickDelete(field) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await FieldRepository.delete(field.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("field-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
