<template>
  <v-container id="social" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <social-table :filter-form="filterForm" :socials="socials" />
    <v-row class="mt-4">
      <v-col cols="12" sm="6" />
      <v-col cols="12" sm="6">
        <v-pagination
          v-if="totalItem > limit"
          v-model="currentPage"
          class="justify-sm-end"
          :length="getTotalItem(totalItem, limit)"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FilterForm from "./components/FilterForm.vue";
import SocialTable from "./components/SocialTable.vue";
import RepositoryFactory from "../../repositories/RepositoryFactory";
const SocialRepository = RepositoryFactory.get("social");

export default {
  components: { FilterForm, SocialTable },
  data() {
    return {
      filterForm: {
        title: "",
        status: undefined,
      },
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      socials: [],
      loading: true,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getSocialList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getSocialList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  async created() {
    this.getSocialList();
    Bus.$on("social-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getSocialList();
    });
    Bus.$on("social-reload", () => {
      this.getSocialList();
    });
  },
  beforeDestroy() {
    Bus.$off("social-list-filter");
  },
  methods: {
    async getSocialList() {
      this.loading = true;
      const params = {
        limit: this.limit,
        page: this.currentPage,
        title: this.filterForm.title,
        status: this.filterForm.status,
      };

      const loader = this.$loading.show();
      const response = await SocialRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.socials = _.get(response, "data.items", []);
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      }
    },
  },
};
</script>
