<template>
  <v-card class="text-center">
    <template v-if="errorMessage">
      <v-alert type="error">{{ errorMessage }}</v-alert>
    </template>
    <validation-observer ref="validator" v-slot="{ handleSubmit }">
      <v-card-title class="text-sm-left font-weight-bold">
        <template v-if="isCreate">Thêm mới người dùng</template>
        <template v-else>Cập nhật thông tin</template>
        <v-spacer />
        <v-icon aria-label="Close" @click="cancelCreateOrUpdate"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="px-6">
        <v-row>
          <v-col cols="12" md="4">
            <div class="upload-image upload-image--user">
              <v-tooltip bottom v-if="_.get(user, 'avatar.id', null)">
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="delete-img"
                    @click="deleteImage"
                    icon
                    small
                    color="default"
                    v-on="on"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <span>Xóa ảnh đại diện</span>
              </v-tooltip>
              <img
                v-if="_.get(user, 'imageUrl', null)"
                :src="_.get(user, 'imageUrl')"
                @click="chooseImage"
                style="width: 100%"
              />
              <v-img
                v-else
                src="@/assets/default-avatar.jpg"
                @click="chooseImage"
              />
              <input
                ref="fileInput"
                style="display: none"
                type="file"
                accept="image/*"
                @input="onSelectFile"
              />
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  :rules="{
                    required: true,
                    max: 50,
                  }"
                  name="Tên tài khoản"
                >
                  <v-text-field
                    v-model="user.userName"
                    label="Tên tài khoản *"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  :rules="{
                    required: true,
                    max: 50,
                  }"
                  name="Họ và tên"
                >
                  <v-text-field
                    v-model="user.name"
                    label="Họ và tên *"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Số điện thoại"
                  :rules="{ regex: regex.isVNPhoneMobile, required: true }"
                >
                  <v-text-field
                    v-model="user.phoneNumber"
                    label="Số điện thoại *"
                    :error-messages="errors"
                    color="primary"
                    type="number"
                    disabled
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Email"
                  :rules="{
                    max: 255,
                    email: true,
                    regex: regex.isEmail,
                  }"
                >
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    :error-messages="errors"
                    color="primary"
                    type="email"
                    disabled
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Mã cá nhân"
                  :rules="{
                    max: 8,
                  }"
                >
                  <v-text-field
                    v-model="user.code"
                    label="Mã cá nhân"
                    :error-messages="errors"
                    color="primary"
                    disabled
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Mã người giới thiệu"
                  :rules="{
                    max: 8,
                  }"
                >
                  <v-text-field
                    v-model="user.referralCode"
                    label="Mã người giới thiệu"
                    :error-messages="errors"
                    color="primary"
                    disabled
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Giới tính"
                >
                  <v-autocomplete
                    v-model="user.gender"
                    attach
                    :items="userGenderOptions"
                    :error-messages="errors"
                    item-text="text"
                    item-value="value"
                    label="Giới tính *"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-menu
                  ref="datePicker"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="user.birthday"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="user.birthday"
                      color="primary"
                      label="Ngày sinh"
                      prepend-icon="mdi-calendar-outline"
                      readonly
                      v-on="on"
                    />
                  </template>

                  <v-date-picker
                    v-model="user.birthday"
                    color="secondary"
                    scrollable
                    @change="
                      $refs.datePicker.save(user.birthday), (datePicker = false)
                    "
                    :max="currentDate"
                  ></v-date-picker>
                </v-menu>
              </v-col> -->
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Độ tuổi"
                >
                  <v-text-field
                    v-model="user.age"
                    label="Độ tuổi"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Mô tả"
                >
                  <v-text-field
                    v-model="user.description"
                    label="Mô tả"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  name="Mục tiêu"
                >
                  <v-text-field
                    v-model="user.target"
                    label="Mục tiêu"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <validation-provider class="mb-2" name="Tổng G">
              <v-text-field
                v-model="user.totalPoint"
                label="Tổng G"
                color="primary"
                disabled
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="3">
            <validation-provider class="mb-2" name="Tổng G">
              <v-text-field
                v-model="user.totalHeart"
                label="Tổng tim"
                color="primary"
                disabled
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="3">
            <validation-provider class="mb-2" name="Tổng G">
              <v-text-field
                v-model="user.totalGroup"
                label="Số nhóm đã tạo"
                color="primary"
                disabled
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="3">
            <validation-provider class="mb-2" name="Tổng G">
              <v-text-field
                v-model="user.totalQuestions"
                label="Tổng số câu hỏi"
                color="primary"
                disabled
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <validation-provider
              v-slot="{ errors }"
              name="Chức danh"
              class="mb-2"
              :rules="{
                required: true,
              }"
            >
              <v-autocomplete
                    v-model="user.position"
                    attach
                    :items="customerPositionOptions"
                    :error-messages="errors"
                    item-text="text"
                    item-value="value"
                    label="Chức danh"
                    color="primary"
                  />
              </v-autocomplete>
            </validation-provider>
          </v-col>

          <v-col cols="12" md="4">
            <v-checkbox v-model="user.isBlueCheckmark" label="Trao tích xanh" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="group-btn--form">
        <v-btn color="success" @click.prevent="handleSubmit(saveCustomer)"
          >Lưu lại</v-btn
        >
        <v-spacer />

        <v-btn color="primary" @click="cancelCreateOrUpdate">Hủy bỏ</v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
import {
  regex,
  userGenderOptions,
  customerPositionOptions,
} from "../../../helpers/constants";

const ImageRepository = RepositoryFactory.get("image");
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  $_veeValidate: {
    validator: "new",
  },
  data() {
    return {
      datePicker: false,
      regex: regex,
      user: {},
      isCreate: true,
      roles: [],
      userGenderOptions,
      customerPositionOptions,
      errorMessage: "",
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Kích thước hình đại diện phải nhỏ hơn 2 MB!",
      ],
      visibleConfirmPassword: true,
      visiblePassword: true,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.isCreate = false;
      this.getCustomerDetail();
    }
    delete this.user.password;
  },
  computed: {
    currentDate() {
      const d = new Date();
      return d.toISOString().slice(0, 10);
    },
  },
  watch: {},
  methods: {
    async getCustomerDetail() {
      this.loading = this.$loading.show();
      const response = await CustomerRepository.detail(this.$route.params.id);
      this.loading.hide();
      if (response.success) {
        this.user = _.get(response, "data", {});
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    cancelCreateOrUpdate() {
      this.$router.push({
        name: "CustomerList",
      });
    },

    deleteImage() {
      this.$set(this.user, "imageUrl", null);
    },

    chooseImage() {
      this.$refs.fileInput.click();
    },

    async onSelectFile() {
      const loader = this.$loading.show();
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files.length) {
        await this.uploadOneFile(files[0]);
      }
      loader.hide();
    },

    async uploadOneFile(file) {
      const uploadResponse = await ImageRepository.upload(file);
      if (_.get(uploadResponse, "success", false)) {
        const imageUrl = _.get(uploadResponse, "data.data.path", "");
        if (imageUrl) this.$set(this.user, "imageUrl", imageUrl);
      } else {
        this.$swal("Upload ảnh đại diện không thành công");
      }
    },

    async saveCustomer() {
      if (this.isCreate) {
      } else {
        const loader = this.$loading.show();
        const response = await CustomerRepository.update(this.user.id, {
          userName: _.trim(this.user.userName),
          name: _.trim(this.user.name),
          position: this.user.position,
          description: this.user.description,
          gender: this.user.gender,
          imageUrl: this.user.imageUrl,
          target: this.user.target,
          age: this.user.age,
          isBlueCheckmark: this.user.isBlueCheckmark,
        });
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.$router.push({
              name: "CustomerList",
            });
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.service-form-pictures {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
}
.group--product {
  display: flex;
  flex-wrap: wrap;
}
.display-image-block {
  position: relative;
  width: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
}
.delete-image-block {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 9;
}
.delete-image-block-icon {
  border-radius: 50%;
  background: #c7c7c7;
}
.v-card--material {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
.display-none {
  display: none;
}
.thumbnail-validation {
  .v-text-field__slot {
    display: none !important;
  }
}
.v-input--radio-group__input .v-input--selection-controls__ripple {
  left: -12px;
}
.errors-span-required-content {
  display: inline-block;
  height: 20px;
  width: 100%;
  text-align: left;
  color: #ff5252;
  font-size: 12px;
}
.title-span-content {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
}
</style>