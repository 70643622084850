<template>
  <v-card
      class="mx-auto overflow-visible"
      width="800px"
      style="margin-top: 0px; margin-bottom: 0px;"
      outlined
  >
    <v-list-item three-line>
      <v-list-item-content class="overflow-visible">
        <v-row class="align-center">
          <v-col cols="12" md="6">
            <v-list-item-title class="display-2 mb-2">Lựa chọn cột hiển thị</v-list-item-title>
          </v-col>
          <v-col cols="12" md="6" class="text-right">
            <v-icon aria-label="Close" @click="closeRowAttribute">mdi-close</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <template v-for="(attribute, index) in cloneRowAttributes">
            <v-col cols="12" md="3" class="pt-0 pb-0" :key="index">
              <v-checkbox class="ma-0" v-model="attribute.value" :label="attribute.title"/>
            </v-col>
          </template>
        </v-row>

        <div class="text-center mb-4">
          <v-btn
              class="success mx-0"
              style="margin-right: 10px !important;"
              @click="applyRowAttribute"
          >Áp dụng</v-btn>
          <v-btn class="mx-0" @click="closeRowAttribute">Đóng</v-btn>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>

export default {
  props: {
    filterForm: {
      default: () => ({})
    },
    questionShowColumns: {
      default: () => ({}),
    },
  },
  data() {
    return {
      cloneRowAttributes: []
    };
  },
  computed: {

  },
  created() {
    this.cloneRowAttributes = this.questionShowColumns.map(s => ({ ...s }));
  },
  methods: {
    applyRowAttribute() {
      const filterFormTemp = this.filterForm;
      filterFormTemp.openRowMenu = false;
      Bus.$emit('question-change-row-attribute', this.cloneRowAttributes.map(s => ({ ...s })));
    },
    closeRowAttribute() {
      const filterFormTemp = this.filterForm;
      filterFormTemp.openRowMenu = false;
    }
  }
};
</script>
