<template>
    <div v-if="!_.isEmpty(trainingData)">
        <h2 class="d-flex px-3">
            <v-icon size="30px" v-if="trainingData.type == 'folder'" style="color: #ffad33" class="mr-2">
                mdi-folder-open
            </v-icon>
            <v-icon size="30px" v-if="trainingData.type == 'text'" style="color: #0066ff" class="mr-2">
                mdi-file-document
            </v-icon>
            <v-icon size="30px" v-if="trainingData.type == 'pdf'" style="color: #ff3300" class="mr-2">
                mdi-file-pdf
            </v-icon>
            <v-icon size="30px" v-if="trainingData.type == 'video'" style="color: #ff3300" class="mr-2">
                mdi-play-circle
            </v-icon>
            <v-icon size="30px" v-if="trainingData.type == 'qna'" style="color: #0066ff" class="mr-2">
                mdi-help-circle
            </v-icon>
            <div>
                {{ trainingData.title }}
            </div>
            <v-spacer />
            <div class="training-content-btn">
                <v-tooltip bottom v-if="showFs">
                    <template v-slot:activator="{ on }">
                        <v-icon 
                                size="25px" 
                                class="mr-2 icon-cursor" 
                                v-on="on"
                                @click="showFullScreen()">
                            mdi-crop-free
                        </v-icon>
                    </template>
                    <span>Toàn màn hình</span>
                </v-tooltip>
                <v-tooltip
                    v-if="(checkAuthorization('training-sale_update') && showEdit) ||
                      (checkAuthorization('training-manager_update') && showEdit)"
                    bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon
                                size="25px" 
                                class="mr-2 icon-cursor" 
                                v-on="on"
                                @click="editTraining()">
                            mdi-pencil
                        </v-icon>
                    </template>
                    <span>Cập nhật</span>
                </v-tooltip>
                <v-tooltip
                    v-if="(checkAuthorization('training-sale_delete') && trainingData.typePermission == 'sale') ||
                      (checkAuthorization('training-manager_delete') && trainingData.typePermission == 'manager')"
                    bottom>
                    <template v-slot:activator="{ on }">
                        <v-icon size="25px" class="icon-cursor" v-on="on" @click="deleteTraining()">mdi-delete</v-icon>
                    </template>
                    <span>Xóa</span>
                </v-tooltip>
            </div>
        </h2>

        <div class="traning-content-container">
            <div v-if="trainingData.type == 'text'" v-html="trainingData.content">
            </div>
            <div v-if="trainingData.type == 'qna'">
                <v-expansion-panels multiple>
                    <v-expansion-panel
                        @change="autoGrowHack = !autoGrowHack"
                        v-for="(item, index) in trainingData.content" :key="index">
                        <v-expansion-panel-header>
                            <div class="d-flex">
                                <v-icon class="mr-2" style="color: #0066ff" size="25px">mdi-help-circle</v-icon>
                                <div style="font-weight: 500" class="mt-1">{{ item.question }}</div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex">
                                <v-icon class="mr-2" color="success">mdi-check-underline-circle</v-icon>
<!--                                <div style="font-size: 16px">{{ item.answer }}</div>-->
                              <v-textarea
                                  v-model="item.answer"
                                  rows="1"
                                  :key="autoGrowHack"
                                  auto-grow
                                  flat
                                  solo
                                  readonly
                                  hide-details
                              >
                              </v-textarea>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
            <div v-if="trainingData.type == 'video'">
                <iframe 
                        width="100%"
                        height="500px"
                        :src="trainingData.content" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                </iframe>
            </div>
            <div v-if="trainingData.type == 'folder'">
                <div class="d-flex">
                    <v-icon class="mr-2" style="color: #ffad33" size="25px">mdi-alert-decagram</v-icon>
                    {{ trainingData.content }}
                </div>
              <v-card-title>Mô tả:</v-card-title>
              <v-card-text>{{trainingData.description}}</v-card-text>
            </div>
            <div v-if="trainingData.type == 'pdf'" style="height: 100%">
                <embed 
                        :src="trainingData.content" 
                        width="100%" 
                        height="100%"
                        type="application/pdf"/>
            </div>
        </div>
    </div>
</template>

<script>
import RepositoryFactory from '@/repositories/RepositoryFactory'
const TrainingRepository = RepositoryFactory.get('training')

export default {
    data() {
        return {
            trainingData: {},
            showFs: true,
            showEdit: true,
            autoGrowHack: false
        }
    },
    created() {
        Bus.$on('get-file-training-content', id => {
            this.getContent(id)
        })

        Bus.$on('get-file-for-update', id => {
            this.getContent(id, true)
        })

        Bus.$on('delete-training-from-tree', item => {
            this.trainingData = item
            this.deleteTraining()
        })

      Bus.$on('get-folder-training-content', item => {
        this.trainingData = item;
      })
    },
    methods: {
        showFullScreen() {
            Bus.$emit('show-full-screen-training=content', this.trainingData)
        },
        editTraining() {
            if (this.trainingData.type == 'folder') {
                Bus.$emit('show-folder-edit-form', this.trainingData)
            } else {
                Bus.$emit('show-file-edit-form', this.trainingData)
            }
        },
        async getContent(id, showUpdate = false) {
            const loading = this.$loading.show()
            let res = await TrainingRepository.detail(id) 
            if (res.success) {
                this.trainingData = _.get(res, 'data', {})
                if (this.trainingData.type == 'qna') {
                    this.trainingData.content = JSON.parse(_.get(res, 'data.content', '{}'))
                }

                if (['video'].includes(this.trainingData.type)) {
                    this.showFs = false
                } else {
                    this.showFs = true
                }

                if (['pdf'].includes(this.trainingData.type)) {
                    this.showEdit = false
                } else {
                    this.showEdit = true
                }

                if (showUpdate) {
                    Bus.$emit('show-file-edit-form', _.cloneDeep(this.trainingData))
                }
            } else {
                this.trainingData = {}
            }
            loading.hide()
        },
        async deleteTraining() {
            const loading = this.$loading.show()
            let res = {}
            if (this.trainingData.type == 'folder') {
                res = await TrainingRepository.deleteFolder(this.trainingData.id)
            } else {
                res = await TrainingRepository.deleteFile(this.trainingData.id)
            }
             
            if (res.success) {
                this.trainingData = {}
                Bus.$emit('training-document-reload')
            } 
            loading.hide()
        }
    },
    updated() {
      document.querySelectorAll( 'oembed[url]' ).forEach( element => {
        if(element.childNodes.length ==0) {
          iframely.load( element, element.attributes.url.value );
        }
      });
    }
}
</script>

<style scoped>
.traning-content-container {
    border: 2px solid #bfbfbf;
    height: calc(100vh - 158px);
    margin-top: 10px;
    padding: 15px 30px;
    border-radius: 10px;
    overflow: auto;
}

.icon-cursor {
    cursor: pointer;
}

/deep/ .v-expansion-panel-header {
    padding: 10px;
    background-color: #e6f2ff;
    border: 1px solid #e2e2e2;
    font-size: 16px;
    min-height: 40px;
}

/deep/ .v-expansion-panel-content__wrap {
    padding: 10px;
    border: 1px solid #e2e2e2;
}

/deep/ ul {
    padding-left: 24px !important;
}

/deep/ ul li {
    list-style: disc;
}

/deep/ ol {
    padding-left: 24px !important;
}

/deep/ .traning-content-container table {
  width: 100%;
  border-collapse: collapse;
}
/deep/ .traning-content-container table td{
  border: 2px solid black;
  padding: 4px;
}
</style>