<template>
  <div>
    <!-- <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('transaction_create')"
        class="primary mx-0"
        @click="addNewTransaction"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div> -->

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Mã giao dịch</th>
            <th>Mã tham chiếu</th>
            <th>Loại giao dịch</th>
            <th>Từ tài khoản</th>
            <th>Đến tài khoản</th>
            <th>Số G</th>
            <th>Số tiền tương ứng</th>
            <th>Thời điểm</th>
            <th>Trạng thái</th>
            <!-- <th v-if="showAction">Thao tác</th> -->
          </tr>
        </thead>
        <tbody>
          <template v-for="transaction in transactions">
            <tr :key="transaction.id">
              <td>
                {{ _.get(transaction, "id") }}
              </td>
              <td>
                {{ _.get(transaction, "referenceId") }}
              </td>
              <td>
                <v-chip :color="getTransactionTypeColor(transaction.type)" dark>
                  {{ transaction.type }}
                </v-chip>
              </td>

              <td
                v-if="_.get(transaction, 'meta.fromInfo', false)"
                style="min-width: 230px; text-align: center"
              >
                <div style="padding-top: 15px">
                  <div>
                    <span
                      style="padding: 10px; display: flex; align-item: center"
                      ><v-icon
                        color="white"
                        style="padding-right: 10px; color: black !important"
                        >mdi-account-box-outline</v-icon
                      >
                      {{ _.get(transaction, "meta.fromInfo.userName") }}</span
                    >
                  </div>
                  <div>
                    <span
                      style="padding: 10px; display: flex; align-item: center"
                      ><v-icon
                        color="white"
                        style="padding-right: 10px; color: black !important"
                        >mdi-cast</v-icon
                      >
                      {{
                        _.get(transaction, "meta.fromInfo.phoneNumber")
                      }}</span
                    >
                  </div>
                  <div style="padding: 10px; display: flex; align-item: center">
                    <span
                      ><v-icon
                        color="white"
                        style="padding-right: 8px; color: black !important"
                        >mdi-email-outline</v-icon
                      >
                      {{ _.get(transaction, "meta.fromInfo.email") }}</span
                    >
                  </div>

                  <div
                    style="
                      padding: 10px;
                      display: flex;
                      align-item: center;
                      cursor: pointer;
                    "
                  >
                    <v-icon
                      color="white"
                      style="padding-right: 8px; color: black !important"
                      >mdi-wallet</v-icon
                    >
                    {{ _.get(transaction, "meta.fromInfo.id") }}
                  </div>
                  <div
                    style="
                      padding: 10px;
                      display: flex;
                      align-item: center;
                      cursor: pointer;
                    "
                  >
                    <v-icon
                      color="white"
                      style="padding-right: 8px; color: black !important"
                      >mdi-cash</v-icon
                    >
                    {{ _.get(transaction, "meta.fromInfo.totalPoint") }} G
                  </div>
                </div>
              </td>
              <td v-else>System</td>

              <td
                v-if="_.get(transaction, 'meta.toInfo', false)"
                style="min-width: 230px; text-align: center"
              >
                <div style="padding-top: 15px">
                  <div>
                    <span
                      style="padding: 10px; display: flex; align-item: center"
                      ><v-icon
                        color="white"
                        style="padding-right: 10px; color: black !important"
                        >mdi-account-box-outline</v-icon
                      >
                      {{ _.get(transaction, "meta.toInfo.userName") }}</span
                    >
                  </div>
                  <div>
                    <span
                      style="padding: 10px; display: flex; align-item: center"
                      ><v-icon
                        color="white"
                        style="padding-right: 10px; color: black !important"
                        >mdi-cast</v-icon
                      >
                      {{ _.get(transaction, "meta.toInfo.phoneNumber") }}</span
                    >
                  </div>
                  <div style="padding: 10px; display: flex; align-item: center">
                    <span
                      ><v-icon
                        color="white"
                        style="padding-right: 8px; color: black !important"
                        >mdi-email-outline</v-icon
                      >
                      {{ _.get(transaction, "meta.toInfo.email") }}</span
                    >
                  </div>
                  <div
                    style="
                      padding: 10px;
                      display: flex;
                      align-item: center;
                      cursor: pointer;
                    "
                  >
                    <v-icon
                      color="white"
                      style="padding-right: 8px; color: black !important"
                      >mdi-wallet</v-icon
                    >
                    {{ _.get(transaction, "meta.toInfo.id") }}
                  </div>
                  <div
                    style="
                      padding: 10px;
                      display: flex;
                      align-item: center;
                      cursor: pointer;
                    "
                  >
                    <v-icon
                      color="white"
                      style="padding-right: 8px; color: black !important"
                      >mdi-cash</v-icon
                    >
                    {{ _.get(transaction, "meta.toInfo.totalPoint") }} G
                  </div>
                </div>
              </td>
              <td v-else>System</td>
              <td>{{ transaction.point }}</td>
              <td>{{ formatPrice(transaction.point * 1000) }} VND</td>
              <td>{{ formatDateTime(transaction.createdAt) }}</td>
              <td>
                <v-chip :color="getStatusColor(transaction.status)" dark>
                  {{ transaction.status }}
                </v-chip>
              </td>
              <!-- <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('transaction_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editTransaction(transaction)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('transaction_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(transaction)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td> -->
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <transaction-form :types="types" />
  </div>
</template>
<script>
import TransactionForm from "./TransactionForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const TransactionRepository = RepositoryFactory.get("transaction");
import {
  transactionStatus,
  transactionStatusColor,
  transactionType,
  transactionTypeColor,
} from "../../../helpers/constants";
import moment from "moment";

export default {
  components: { TransactionForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    transactions: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTransaction: {},
      modalAlert: false,
      errorMessage: "",
      transactionStatus,
      transactionStatusColor,
      transactionType,
      transactionTypeColor,
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("transaction_update") ||
        this.checkAuthorization("transaction_delete")
      );
    },
  },
  methods: {
    getStatusColor(status) {
      return transactionStatusColor[status] || "gray";
    },
    getTransactionTypeColor(type) {
      return transactionTypeColor[type] || "gray";
    },
    formatDateTime(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      }
      return null;
    },
    addNewTransaction() {
      this.selectedTransaction = {};
      Bus.$emit("transaction-show-form", this.selectedTransaction);
    },
    editTransaction(transaction) {
      this.selectedTransaction = transaction;
      Bus.$emit("transaction-show-form", this.selectedTransaction);
    },
    async onClickDelete(transaction) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await TransactionRepository.deleteOneItem(
            transaction.id
          );
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("transaction-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
