<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên danh mục"
              >
                <v-text-field
                  v-model="category.name"
                  label="Tên danh mục *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="category.slug"
                  label="Đường dẫn *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Code"
                rules="max:255"
              >
                <v-text-field
                  v-model="category.code"
                  label="Code"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveCategory)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const CategoryRepository = RepositoryFactory.get("category");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      category: {
        name: "",
        slug: "",
        code: "",
        imageUrl: "",
      },
      isCreate: true,
      categories: [],
      errorMessage: "",
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ],
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["image", "code-block"],
      ],
    };
  },
  created() {
    Bus.$on("category-show-form", (category) => {
      this.showModal = true;
      this.category = _.cloneDeep(category);
      if (category.id) this.isCreate = false;
      else this.isCreate = true;
    });

    Bus.$on("update-category", (category) => {
      this.isCreate = false;
      this.category = _.cloneDeep(category);
      this.saveCategory()
    });
        
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveCategory() {
      if (this.isCreate) {
        const category = {
          name: _.trim(this.category.name),
          slug: _.trim(this.category.slug),
          code: _.trim(this.category.code),
        };
        const loader = this.$loading.show();
        const response = await CategoryRepository.create(category);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("category-list-reload");
          });
        }
        loader.hide();
      } else {
        const category = {
          name: _.trim(this.category.name),
          slug: _.trim(this.category.slug),
          code: _.trim(this.category.code),
        };
        const loader = this.$loading.show();
        const response = await CategoryRepository.update(
          this.category.id,
          category
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("category-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
  beforeDestroy () {
      Bus.$off('update-category');
      Bus.$off('category-show-form')
  },
};
</script>
