<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm câu hỏi</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0 overflow-visible" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0 overflow-visible">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="filterForm.title"
                    label="Tìm kiếm theo tiêu đề câu hỏi"
                    placeholder="Tiêu đề câu hỏi...."
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="filterForm.question"
                    label="Tìm kiếm theo nội dung câu hỏi"
                    placeholder="Nội dung câu hỏi...."
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.tags"
                    :items="serviceTags"
                    item-text="name"
                    item-value="id"
                    label="Nhãn"
                    color="primary"
                    multiple
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                    <template #prepend-item>
                      <common-select-all
                        v-model="filterForm.tags"
                        :item="filterForm.tags"
                        :items="serviceTags.map((s) => s.id)"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--  -->
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.status"
                    :items="serviceQuestionStatus"
                    item-text="text"
                    item-value="code"
                    label="Trạng thái"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.groupId"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    label="Nhóm"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.organizationId"
                    :items="organizations"
                    item-text="name"
                    item-value="id"
                    label="Tổ chức"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.countryId"
                    :items="countries"
                    item-text="name"
                    item-value="id"
                    label="Quốc gia"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.languageId"
                    :items="languages"
                    item-text="name"
                    item-value="id"
                    label="Ngôn ngữ"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.categoryId"
                    :items="categories"
                    item-text="name"
                    item-value="id"
                    label="Danh mục"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.positionId"
                    :items="positions"
                    item-text="name"
                    item-value="id"
                    label="Vị trí"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.type"
                    :items="types"
                    item-text="name"
                    item-value="code"
                    label="Loại câu hỏi"
                    color="primary"
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.fields"
                    :items="fields"
                    item-text="name"
                    item-value="id"
                    label="Lĩnh vực"
                    color="primary"
                    multiple
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                    <template #prepend-item>
                      <common-select-all
                        v-model="filterForm.fields"
                        :item="filterForm.fields"
                        :items="fields.map((s) => s.id)"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.ageRanges"
                    :items="ageRanges"
                    item-text="name"
                    item-value="id"
                    label="Độ tuổi"
                    color="primary"
                    multiple
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                    <template #prepend-item>
                      <common-select-all
                        v-model="filterForm.ageRanges"
                        :item="filterForm.ageRanges"
                        :items="ageRanges.map((s) => s.id)"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.subjects"
                    :items="subjects"
                    item-text="name"
                    item-value="id"
                    label="Môn học"
                    color="primary"
                    multiple
                    no-data-text="Không có dữ liệu"
                    clearable
                  >
                    <template #prepend-item>
                      <common-select-all
                        v-model="filterForm.subjects"
                        :item="filterForm.subjects"
                        :items="subjects.map((s) => s.id)"
                      />
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchQuestion">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import {
  questionStatusOptions,
  FIRST_PAGE,
  STATUS_ACTIVE,
  PAGE_LIMIT,
} from "../../../../helpers/constants";
import * as moment from "moment";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const ManagerRepository = RepositoryFactory.get("manager");
const CustomerRepository = RepositoryFactory.get("customer");
const FieldRepository = RepositoryFactory.get("field");
const GroupRepository = RepositoryFactory.get("group");
const OrganizationRepository = RepositoryFactory.get("organization");
const CategoryRepository = RepositoryFactory.get("category");
const LanguageRepository = RepositoryFactory.get("language");
const AgeRepository = RepositoryFactory.get("age");
const PositionRepository = RepositoryFactory.get("position");
const TagRepository = RepositoryFactory.get("tag");
const CountryRepository = RepositoryFactory.get("country");
const SubjectRepository = RepositoryFactory.get("subject");
export default {
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      defaultRange: {
        startDate: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        endDate: moment().endOf("isoWeek").format("YYYY-MM-DD"),
      },
      fields: [],
      groups: [],
      ageRanges: [],
      categories: [],
      languages: [],
      organizations: [],
      positions: [],
      countries: [],
      subjects: [],
      types: [
        {
          code: "TRAC_NGHIEM",
          name: "Trắc nghiệm",
        },
        {
          code: "TU_LUAN",
          name: "Tự luận",
        },
      ],
      serviceQuestionStatus: questionStatusOptions,
      serviceTags: [],
    };
  },
  async created() {
    const loader = this.$loading.show();
    await Promise.all([
      this.getListField(),
      this.getListTags(),
      this.getListSubjects(),
      this.getListGroups(),
      this.getListPositions(),
      this.getListOrganizations(),
      this.getListCountries(),
      this.getListLanguages(),
      this.getListAgeRanges(),
      this.getListTags(),
      this.getListCategories(),
    ]);
    loader.hide();
  },
  methods: {
    searchQuestion() {
      Bus.$emit("question-list-filter", this.filterForm);
    },

    async getUsers() {
      let res = await CustomerRepository.list();
      if (res.success) {
        this.users = _.get(res, "data.items", []);
      } else {
        this.users = [];
      }
    },
    async getListField() {
      const response = await FieldRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.fields = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListGroups() {
      const response = await GroupRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.groups = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListPositions() {
      const response = await PositionRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.positions = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListOrganizations() {
      const response = await OrganizationRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.organizations = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListCategories() {
      const response = await CategoryRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.categories = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListCountries() {
      const response = await CountryRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.countries = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListLanguages() {
      const response = await LanguageRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.languages = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListAgeRanges() {
      const response = await AgeRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.ageRanges = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListSubjects() {
      const response = await SubjectRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.subjects = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListTags() {
      const listTag = await TagRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (listTag.success) {
        this.serviceTags = _.get(listTag, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(listTag, "message", ""),
        });
      }
    },
    changeDateRange(dateRange) {
      // this.filterForm.createdAt = dateRange.startDate
      // this.filterForm.endDateTime = dateRange.endDate
    },
  },
};
</script>
<style scoped>
.hiden-filter-siteId {
  display: none;
}
</style>