<template>
    <v-dialog v-model="showModal" persistent max-width="60%">
        <v-card>
            <v-card-title class="text-sm-left font-weight-bold pt-3">
                <template v-if="isCreate">
                    Tạo folder
                </template>
                <template v-else>
                    Cập nhật folder
                </template>
                <v-spacer />
                <v-icon aria-label="Close" @click="closeModal()">mdi-close</v-icon>
            </v-card-title>
            <validation-observer v-slot="{ handleSubmit }">
                <v-card-text class="pt-0">
                    <v-row>
                        <v-col cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    class="mb-2"
                                    rules="required"
                                    name="Tiêu đề">
                                <v-text-field
                                        v-model.trim="folder.title"
                                        :error-messages="errors"
                                        label="Tiêu đề *"
                                        placeholder="Nhập tiêu đề">
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    class="mb-2"
                                    name="Ghi chú">
                                <v-textarea
                                        v-model.trim="folder.description"
                                        :error-messages="errors"
                                        label="Ghi chú"
                                        placeholder="Nhập ghi chú"
                                        rows="3"
                                        auto-grow
                                        hide-details>
                                </v-textarea>
                            </validation-provider>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center pt-0">
                    <v-btn
                            color="success"
                            @click.prevent="handleSubmit(saveFolder)"
                            height="30px">
                        Lưu
                    </v-btn>
                    <v-btn
                            color="error"
                            @click="closeModal()"
                            height="30px">
                        Hủy
                    </v-btn>
                </v-card-actions>
            </validation-observer>
        </v-card>
    </v-dialog>
</template>

<script>
import RepositoryFactory from '@/repositories/RepositoryFactory'
const TrainingRepository = RepositoryFactory.get('training')

export default {
    data() {
        return {
            showModal: false,
            folder: {
                name: "",
                description: ""
            },
            isCreate: true,
            parentId: 0,
            type: ''
        }
    },
    created() {
        Bus.$on('show-folder-create-form', (parentId, typePermission) => {
            this.folder = {
                title: "",
                description: ""
            }
            this.parentId = parentId
            this.isCreate = true
            this.showModal = true
            this.type = typePermission
        })

        Bus.$on('show-folder-edit-form', folder => {
            this.folder = _.cloneDeep(folder)
            this.parentId = folder.parentId
            this.isCreate = false
            this.showModal = true
        })
    },
    methods: {
        closeModal() {
            this.showModal = false
        },
        async saveFolder() {
            let folder = {
                title: this.folder.title,
                description: this.folder.description,
                parentId: this.parentId == 0 ? null : this.parentId,
                typePermission: this.type
            }

            if (this.isCreate) {
                let res = await TrainingRepository.createFolder(folder)
                if (res.success) {
                    this.closeModal()
                    Bus.$emit('training-document-reload')
                } else {
                    this.$swal({
                        title: 'Lỗi!',
                        text: _.get(res, 'message', '')
                    })
                }
            } else {
                let res = await TrainingRepository.updateFolder(this.folder.id, folder)
                if (res.success) {
                    this.closeModal()
                    Bus.$emit('training-document-reload')
                } else {
                    this.$swal({
                        title: 'Lỗi!',
                        text: _.get(res, 'message', '')
                    })
                }
            }
            // update title item to show when update folder
            folder.type = 'folder';
            Bus.$emit('get-folder-training-content', folder)
        }
    }
}
</script>

<style scoped>

</style>