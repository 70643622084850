<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('bank_create')"
        class="primary mx-0"
        @click="addNewBank"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Bước 1</th>
            <th>QR</th>
            <th>Tên tài khoản</th>
            <th>Ngân hàng</th>
            <th>Địa chỉ</th>
            <th>Bước 2</th>
            <th>Mô tả bước 2</th>
            <th>Video hướng dẫn</th>
            <th>Bước 3</th>
            <th>Mô tả bước 3</th>
            <th>Số điện thoại liên hệ</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="bank in banks">
            <tr :key="bank.id">
              <td>{{ _.get(bank, "stepOne") }}</td>
              <td>
                <div class="d-inline-flex align-center">
                  <a :href="`${bank.qrUrl}`" target="_blank">
                    <v-img :src="_.get(bank, 'qrUrl')" max-width="358" />
                  </a>
                </div>
              </td>
              <td>{{ _.get(bank, "bankAccountFullName") }}</td>
              <td>{{ _.get(bank, "bankAccountNumber") }}</td>
              <td>{{ _.get(bank, "address") }}</td>
              <td>{{ _.get(bank, "stepTwo") }}</td>
              <td>{{ _.get(bank, "stepTwoDescription") }}</td>
              <td>{{ _.get(bank, "youtubeUrl") }}</td>
              <td>{{ _.get(bank, "stepThree") }}</td>
              <td>{{ _.get(bank, "stepThreeDescription") }}</td>
              <td>{{ _.get(bank, "contactPhoneNumber") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip bottom v-if="checkAuthorization('bank_update')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editBank(bank)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="checkAuthorization('bank_delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        @click="onClickDelete(bank)"
                        size="22"
                        icon
                        small
                        dark
                        color="red"
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <bank-form />
  </div>
</template>
<script>
import BankForm from "./BankForm.vue";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const BankRepository = RepositoryFactory.get("bank");

export default {
  components: { BankForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    banks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedBank: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("bank_update") ||
        this.checkAuthorization("bank_delete")
      );
    },
  },
  methods: {
    getStatusColor(status) {
      return "gray";
    },
    getStatusText(status) {
      return status == "ACTIVE" ? "Hoạt động" : "Tạm ngưng";
    },
    addNewBank() {
      this.selectedBank = {};
      Bus.$emit("bank-show-form", this.selectedBank);
    },
    editBank(bank) {
      this.selectedBank = bank;
      Bus.$emit("bank-show-form", this.selectedBank);
    },
    async onClickDelete(bank) {
      this.$swal({
        title: "Xóa bank",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await BankRepository.delete(bank.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("bank-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
  },
};
</script>
