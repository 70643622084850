var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1000","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',{staticClass:"text-center"},[(_vm.errorMessage)?[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))])]:_vm._e(),_c('validation-observer',{ref:"validator",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-card-title',{staticClass:"text-sm-left font-weight-bold"},[(_vm.isCreate)?[_vm._v("Thêm mới thành viên")]:[_vm._v("Cập nhật thông tin thành viên")],_c('v-spacer'),_c('v-icon',{attrs:{"aria-label":"Close"},on:{"click":_vm.hideModal}},[_vm._v("mdi-close")])],2),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"upload-image upload-image--user"},[(_vm._.get(_vm.user, 'imageUrl', null))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"delete-img",attrs:{"icon":"","small":"","color":"default"},on:{"click":_vm.deleteImage}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Xóa ảnh đại diện")])]):_vm._e(),(_vm._.get(_vm.user, 'imageUrl', null))?_c('img',{staticStyle:{"width":"100%","border-radius":"100%"},attrs:{"src":_vm._.get(_vm.user, 'imageUrl')},on:{"click":_vm.chooseImage}}):_c('v-img',{attrs:{"src":require("@/assets/default-avatar.jpg")},on:{"click":_vm.chooseImage}}),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"input":_vm.onSelectFile}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":{
                    required: true,
                    max: 50,
                  },"name":"Tên đăng nhập"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Tên đăng nhập *","error-messages":errors,"color":"primary","readonly":!_vm.isCreate},model:{value:(_vm.user.userName),callback:function ($$v) {_vm.$set(_vm.user, "userName", $$v)},expression:"user.userName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Số điện thoại","rules":{ regex: _vm.regex.isVNPhoneMobile, required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Số điện thoại *","error-messages":errors,"color":"primary","type":"number"},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required|max:50","name":"Họ tên"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Họ tên *","error-messages":errors,"color":"primary"},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"rules":"required","name":"Chức danh"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.userPositionOptions,"error-messages":errors,"item-text":"text","item-value":"value","label":"Chức danh *","color":"primary"},model:{value:(_vm.user.position),callback:function ($$v) {_vm.$set(_vm.user, "position", $$v)},expression:"user.position"}})]}}],null,true)})],1),(_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":{
                    required: true,
                    min: 6,
                    regex: _vm.regex.isPassword,
                    max: 20,
                  },"name":"Mật khẩu","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Mật khẩu *","error-messages":errors,"color":"primary","append-icon":_vm.visiblePassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visiblePassword ? 'password' : 'text'},on:{"click:append":() => (_vm.visiblePassword = !_vm.visiblePassword)},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1):_vm._e(),(_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Nhập lại mật khẩu","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Xác nhận lại mật khẩu *","error-messages":errors,"color":"primary","append-icon":_vm.visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.visibleConfirmPassword ? 'password' : 'text'},on:{"click:append":() => (_vm.visibleConfirmPassword = !_vm.visibleConfirmPassword)},model:{value:(_vm.user.confirm),callback:function ($$v) {_vm.$set(_vm.user, "confirm", $$v)},expression:"user.confirm"}})]}}],null,true)})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Email","rules":{
                    max: 255,
                    email: true,
                    regex: _vm.regex.isEmail,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"label":"Email","error-messages":errors,"color":"primary","type":"email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nhóm quyền"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-autocomplete',{attrs:{"items":_vm.roles,"error-messages":errors,"item-text":"name","item-value":"id","label":"Thiết lập nhóm quyền *","color":"primary"},model:{value:(_vm.user.roleId),callback:function ($$v) {_vm.$set(_vm.user, "roleId", $$v)},expression:"user.roleId"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"group-btn--form"},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.saveUser)}}},[_vm._v("Lưu lại")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.hideModal}},[_vm._v("Hủy bỏ")])],1)]}}])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }