<template>
  <div>
    <validation-observer v-slot="{}" ref="validator">
      <v-simple-table class="table-layout pa-5">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="th-select pr-0">
                <v-checkbox
                  @change="handleSelectAll()"
                  v-model="selectAll"
                ></v-checkbox>
              </th>
              <th class="th-id">#</th>
              <template v-for="(column, index) in questionShowColumns">
                <th :key="index" v-show="column.value">{{ column.title }}</th>
              </template>
              <th v-if="showAction">Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(question, index) in questions">
              <tr :key="question.id">
                <td class="th-select pr-0">
                  <v-checkbox
                    @change="handleSelectOne(question)"
                    v-model="question.isSelected"
                  ></v-checkbox>
                </td>
                <td class="th-id">{{ index + 1 }}</td>
                <td v-show="checkShowQuestionColumn('code')">
                  <span
                    :class="!question.code ? 'expand-span' : ''"
                    v-if="!question.showEdit.code"
                    @dblclick="enableEditAttribute(question, 'code')"
                  >
                    {{ _.get(question, "code") }}
                  </span>
                  <validation-provider
                    v-else
                    v-slot="{ errors }"
                    class="mb-2"
                    rules="required|max:255"
                    name="Mã câu hỏi"
                  >
                    <v-text-field
                      @dblclick="enableEditAttribute(question, 'code')"
                      v-model="question.code"
                      :error-messages="errors"
                      color="primary"
                      @keyup.enter="updateQuestion(question, errors)"
                    />
                  </validation-provider>
                </td>
                <td v-show="checkShowQuestionColumn('type')">
                  <v-chip :color="getQuestionTypeColor(question.type)" dark>
                    {{ getQuestionTypeText(question.type) }}
                  </v-chip>
                </td>
                <td v-show="checkShowQuestionColumn('title')">
                  <vue-mathjax
                    style="font-size: 16px"
                    :formula="question.title"
                    :safe="false"
                  ></vue-mathjax>
                </td>
                <td v-show="checkShowQuestionColumn('content')">
                  <vue-mathjax
                    style="font-size: 16px"
                    :formula="
                      question.question.length > 150
                        ? question.question.slice(0, 150) + '...'
                        : question.question
                    "
                    :safe="false"
                  ></vue-mathjax>
                </td>
                <td v-show="checkShowQuestionColumn('point')">
                  {{ _.get(question, "point") }}
                </td>
                <td v-show="checkShowQuestionColumn('createUser')">
                  {{ _.get(question, "owner.userName", "") }}
                </td>
                <td v-show="checkShowQuestionColumn('createdAt')">
                  {{ formatDateTime(question.createdAt) }}
                </td>
                <td v-if="showAction">
                  <div class="action">
                    <v-tooltip
                      bottom
                      v-if="checkAuthorization('question_update')"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="mr-2"
                          @click="editQuestion(question)"
                          size="22"
                          icon
                          small
                          color="info"
                          dark
                          v-on="on"
                          ><v-icon>mdi-pencil</v-icon></v-btn
                        >
                      </template>
                      <span>Cập nhật</span>
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-if="checkAuthorization('question_delete')"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="onClickDelete(question)"
                          size="22"
                          icon
                          small
                          dark
                          color="red"
                          v-on="on"
                          ><v-icon>mdi-window-close</v-icon></v-btn
                        >
                      </template>
                      <span>Xóa</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </validation-observer>
  </div>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const questionRepository = RepositoryFactory.get("question");
import moment from "moment";
import { VueMathjax } from "vue-mathjax";
import { questionTypeColor } from "../../../../helpers/constants";

export default {
  components: { VueMathjax },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    questions: {
      type: Array,
      default: () => [],
    },
    questionShowColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedQuestion: {},
      questionTypeColor: {},
      modalAlert: false,
      errorMessage: "",
      selectAll: false,
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("question_update") ||
        this.checkAuthorization("question_delete")
      );
    },
  },
  methods: {
    getQuestionTypeColor(status) {
      return questionTypeColor[status] || "gray";
    },
    getQuestionTypeText(status) {
      return status == "TU_LUAN" ? "Tự luận" : "Trắc nghiệm";
    },
    handleSelectAll() {
      Bus.$emit("click-select-all-question", this.selectAll);
    },
    handleSelectOne(ques) {
      if (this.selectAll && !ques.isSelected) {
        this.selectAll = false;
      }

      if (!this.selectAll && this.questions.every((item) => item.isSelected)) {
        this.selectAll = true;
      }
    },
    formatDateTime(date) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:mm");
      }
      return null;
    },
    editQuestion(question) {
      // const route = this.$router.push({
      //   name: "EditQuestion",
      //   params: {
      //     id: _.get(question, "id", 0),
      //   },
      // });
      window.open(`/questions/question-list/edit/${question.id}`, "_blank");
    },
    async onClickDelete(question) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await questionRepository.deleteOneItem(question.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("question-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    enableEditAttribute(question, attr) {
      if (!question[attr] && question.showEdit[attr]) {
        return;
      }
      question.showEdit[attr] = !question.showEdit[attr];
    },
    async updateQuestion(question, errors) {
      if (errors.length || !question.id) {
        return;
      }
      const details = {
        code: _.get(question, "code", ""),
        type: _.get(question, "type", ""),
      };
      this.loading = this.$loading.show();
      const response = await questionRepository.update(
        _.get(question, "id", 0),
        details
      );
      this.loading.hide();
      if (response.error) {
        this.setAlertMessage(true, "error", _.get(response, "message"));
      } else {
        this.$swal({
          title: "Cập nhật thành công!",
          confirmButtonText: "Đồng ý",
        }).then(() => {
          Bus.$emit("question-list-reload");
        });
      }
    },
    checkShowQuestionColumn(attributeName) {
      return (
        this.questionShowColumns.findIndex(
          (attribute) => attribute.value && attribute.name == attributeName
        ) !== -1
      );
    },
  },
};
</script>
<style language="css" scoped>
/deep/.th-select,
.th-id {
  width: 20px !important;
  min-width: unset !important;
}
.expand-span {
  width: 100%;
  height: 100%;
  display: block;
}
</style>