<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên"
              >
                <v-text-field
                  v-model="organization.name"
                  label="Tên *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
                rules="required|max:255"
              >
                <v-text-field
                  v-model="organization.slug"
                  label="Đường dẫn *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveOrganization)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const OrganizationRepository = RepositoryFactory.get("organization");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      organization: {
        name: "",
        slug: "",
      },
      isCreate: true,
      pathologies: [],
      errorMessage: "",
    };
  },
  created() {
    Bus.$on("organization-show-form", (organization) => {
      this.showModal = true;
      this.organization = _.cloneDeep(organization);
      if (organization.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveOrganization() {
      if (this.isCreate) {
        const organization = {
          name: _.trim(this.organization.name),
          slug: _.trim(this.organization.slug),
        };
        const loader = this.$loading.show();
        const response = await OrganizationRepository.create(organization);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("organization-list-reload");
          });
        }
        loader.hide();
      } else {
        const organization = {
          name: _.trim(this.organization.name),
          slug: _.trim(this.organization.slug),
        };
        const loader = this.$loading.show();
        const response = await OrganizationRepository.update(
          this.organization.id,
          organization
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("organization-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
