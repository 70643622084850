<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Tạo nhóm mới </template>
          <template v-else> Cập nhật thông tin </template>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Tên nhóm"
              >
                <v-text-field
                  v-model="group.name"
                  label="Tên nhóm *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required"
                name="Mô tả nhóm"
              >
                <v-text-field
                  v-model="group.description"
                  label="Mô tả nhóm *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
              >
                <v-text-field
                  v-model="group.slug"
                  label="Đường dẫn"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Chủ sở hữu *"
              >
                <v-autocomplete
                  v-model="group.createdBy"
                  :items="customers"
                  :error-messages="errors"
                  item-text="userName"
                  item-value="id"
                  label="Chủ sở hữu *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <v-file-input
                v-model="inputFile"
                placeholder="Ảnh nhóm"
                label="Ảnh nhóm"
                prepend-icon="mdi-paperclip"
                :value="_.get(group, 'imageUrl', '')"
                @change="uploadOneFile"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12" md="12" v-if="isAdmin()">
              <validation-provider v-slot="{ errors }">
                <v-select
                  v-model="group.status"
                  label="Trạng thái"
                  no-data-text="Không có dữ liệu"
                  :items="groupStatusOptions"
                  item-text="text"
                  item-value="value"
                  :error-messages="errors"
                  color="primary"
                >
                  <template v-slot:item="{ attrs, item, on }">
                    <v-list-item
                      v-bind="attrs"
                      active-class="secondary elevation-4 white--text"
                      class="mx-3 mb-3 v-sheet"
                      elevation="0"
                      v-on="on"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveGroup)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import { groupStatusOptions, PAGE_LIMIT } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const GroupRepository = RepositoryFactory.get("group");
const ImageRepository = RepositoryFactory.get("image");
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      group: {
        name: "",
        slug: "",
        description: "",
        imageUrl: "",
        status: "",
        createdBy: "",
      },
      groupStatusOptions,
      isCreate: true,
      groups: [],
      customers: [],
      inputFile: [],
      errorMessage: "",
    };
  },
  async mounted() {
    await this.getCustomerAll();
  },
  created() {
    Bus.$on("group-show-form", (group) => {
      this.showModal = true;
      this.group = _.cloneDeep(group);
      if (group.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async getCustomerAll() {
      let res = await CustomerRepository.list({ perPage: PAGE_LIMIT });
      if (res.success) {
        const listCustomers = _.get(res, "data.items", []);
        this.customers = listCustomers.map((o) => {
          return {
            id: o.id,
            userName: `${o.userName} - ${o.phoneNumber} - ${o.email}`,
          };
        });
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(res, "message", ""),
        });
      }
    },
    async uploadOneFile(file) {
      const loading = this.$loading.show();
      const uploadResponse = await ImageRepository.upload(file);
      loading.hide();
      if (_.get(uploadResponse, "success", false)) {
        const imageUrl = _.get(uploadResponse, "data.data.path", "");
        if (imageUrl) this.$set(this.group, "imageUrl", imageUrl);
      } else {
        this.$swal("Upload ảnh không thành công");
      }
    },

    async saveGroup() {
      const group = {
        name: _.trim(this.group.name),
        slug: _.trim(this.group.slug),
        description: _.trim(this.group.description),
        imageUrl: this.group.imageUrl,
        status: _.trim(this.group.status),
        userId: this.group.createdBy,
      };
      if (this.isCreate) {
        const loader = this.$loading.show();
        const response = await GroupRepository.create(group);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("group-list-reload");
          });
        }
        loader.hide();
      } else {
        const loaderUpdate = this.$loading.show();
        const response = await GroupRepository.update(this.group.id, group);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("group-list-reload");
          });
        }
        loaderUpdate.hide();
      }
    },
  },
};
</script>
