<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.listSender"
                    :items="customers"
                    item-text="userName"
                    item-value="id"
                    label="Tìm kiếm theo người gửi"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="filterForm.listReceiver"
                    :items="customers"
                    item-text="userName"
                    item-value="id"
                    label="Tìm kiếm theo người nhận"
                    color="primary"
                    clearable
                    multiple
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchTransaction">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { PAGE_LIMIT } from "../../../helpers/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
  },
  data() {
    return {
      customers: [],
    };
  },
  async mounted() {
    await this.getCustomerAll();
  },
  methods: {
    searchTransaction() {
      Bus.$emit("transaction-list-filter", this.filterForm);
    },
    async getCustomerAll() {
      let res = await CustomerRepository.list({ perPage: PAGE_LIMIT });
      if (res.success) {
        const listCustomers = _.get(res, "data.items", []);
        this.customers = listCustomers.map((o) => {
          return {
            id: o.id,
            userName: `${o.userName} - ${o.phoneNumber} - ${o.email}`,
          };
        });
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(res, "message", ""),
        });
      }
    },
  },
};
</script>
