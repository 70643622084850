export const models = [
  {
    name: "dashboard_management",
    text: "Dashboard",
    permissions: [],
    level: 1,
  },
  {
    name: "dashboard",
    text: "Dashboard",
    permissions: ["read"],
    level: 2,
  },
  {
    name: "account_managment",
    text: "Tài khoản",
    permissions: [],
    level: 1,
  },
  {
    name: "customer",
    text: "Người dùng",
    permissions: ["read", "update", "delete"],
    level: 2,
  },
  {
    name: "manager",
    text: "Nội bộ",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "group_management",
    text: "Nhóm",
    permissions: [],
    level: 1,
  },
  {
    name: "group",
    text: "Nhóm",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "question_management",
    text: "Câu hỏi",
    permissions: [],
    level: 1,
  },
  {
    name: "question",
    text: "Câu hỏi",
    permissions: ["read", "create", "update", "delete", "download_upload"],
    level: 2,
  },
  {
    name: "answer",
    text: "Bình luận",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "category",
    text: "Danh mục",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "field",
    text: "Lĩnh vực",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "subject",
    text: "Môn học",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "country",
    text: "Quốc gia",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "language",
    text: "Ngôn ngữ",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "organization",
    text: "Tổ chức",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "position",
    text: "Vị trí - Chức vụ",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "age",
    text: "Độ tuổi",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "news_management",
    text: "Nhãn",
    permissions: [],
    level: 1,
  },
  {
    name: "tag",
    text: "Nhãn",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "transaction_management",
    text: "Giao dịch",
    permissions: [],
    level: 1,
  },
  {
    name: "recharge",
    text: "Quản lý nạp tiền",
    // permissions: ["read", "create", "update", "delete"],
    permissions: ["read", "update", "delete"],
    level: 2,
  },
  {
    name: "withdraw",
    text: "Quản lý rút tiền",
    // permissions: ["read", "create", "update", "delete"],
    permissions: ["read", "update", "delete"],
    level: 2,
  },
  {
    name: "transaction",
    text: "Quản lý giao dịch",
    // permissions: ["read", "create", "update", "delete"],
    permissions: ["read"],
    level: 2,
  },
  {
    name: "data_setting_management",
    text: "Cài đặt dữ liệu",
    permissions: [],
    level: 1,
  },
  {
    name: "role",
    text: "Phân quyền",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "notification",
    text: "Thông báo",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "social",
    text: "Mạng xã hội",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  {
    name: "bank",
    text: "Hướng dẫn nạp tiền",
    permissions: ["read", "create", "update", "delete"],
    level: 2,
  },
  // {
  //   name: "banner_management",
  //   text: "Quảng cáo",
  //   permissions: [],
  //   level: 1,
  // },
  // {
  //   name: "banner",
  //   text: "Banner",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "behavior_management",
  //   text: "Lịch sử hoạt động",
  //   permissions: [],
  //   level: 1,
  // },
  // {
  //   name: "behavior_customer",
  //   text: "Khách hàng",
  //   permissions: ["read"],
  //   level: 2,
  // },
  // {
  //   name: "icon",
  //   text: "Icon thông báo",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "notification_customer",
  //   text: "Thông báo tới khách hàng",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "training-sale",
  //   text: "Training sale",
  //   permissions: ["read", "update", "create", "delete", "download"],
  //   level: 2,
  // },
  // {
  //   name: "training-manager",
  //   text: "Training manager",
  //   permissions: ["read", "update", "create", "delete", "download"],
  //   level: 2,
  // },
  // {
  //   name: "information_policy_management",
  //   text: "Chính sách",
  //   permissions: [],
  //   level: 1,
  // },
  // {
  //   name: "question_answer",
  //   text: "Câu hỏi thường gặp",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "shipping_method",
  //   text: "Chính sách giao hàng",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "payment",
  //   text: "Phương thức thanh toán",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "regular",
  //   text: "Chính sách đổi trả",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "privacy_policy",
  //   text: "Chính sách bảo mật",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "about_us",
  //   text: "Giới thiệu công ty",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "site_config_management",
  //   text: "Website",
  //   permissions: [],
  //   level: 1,
  // },
  // {
  //   name: "filter_price",
  //   text: "Lọc theo giá",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "partner",
  //   text: "Đối tác",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "evaluation",
  //   text: "Cảm nhận khách hàng",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "contact_work_management",
  //   text: "Liên hệ",
  //   permissions: [],
  //   level: 1,
  // },
  // {
  //   name: "contact_work",
  //   text: "Phản hồi góp ý",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
  // {
  //   name: "consult",
  //   text: "Khách yêu cầu tư vấn",
  //   permissions: ["read", "create", "update", "delete"],
  //   level: 2,
  // },
];

export const actions = [
  {
    name: "read",
    text: "Truy cập",
  },
  {
    name: "create",
    text: "Thêm",
  },
  {
    name: "update",
    text: "Sửa",
  },
  {
    name: "delete",
    text: "Xóa",
  },
  {
    name: "download_upload",
    text: "Download/Upload",
  },
  {
    name: "view_all",
    text: "Xem tất cả",
  },
];

export function getPermissionByAction(action) {
  return models
    .filter((m) => m.permissions.includes(action))
    .map((model) => `${action}-${model.name}`);
}
