<template>
    <v-dialog v-model="showModal" persistent max-width="70%">
        <v-card>
            <v-card-title class="text-sm-left font-weight-bold pt-3">
                <template v-if="isCreate">
                    Tạo file
                </template>
                <template v-else>
                    Cập nhật file
                </template>
                <v-spacer />
                <v-icon aria-label="Close" @click="closeModal()">mdi-close</v-icon>
            </v-card-title>
            <validation-observer v-slot="{ handleSubmit }">
                <v-card-text class="pt-0">
                    <v-row>
                        <v-col cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    class="mb-2"
                                    rules="required"
                                    name="Định dạng">
                                <v-autocomplete
                                        v-model="file.type"
                                        :items="fileType"
                                        item-text="name"
                                        item-value="type"
                                        :error-messages="errors"
                                        label="Định dạng *"
                                        placeholder="Chọn định dạng">
                                </v-autocomplete>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12">
                            <validation-provider
                                    v-slot="{ errors }"
                                    class="mb-2"
                                    rules="required"
                                    name="Tiêu đề">
                                <v-text-field
                                        v-model="file.title"
                                        :error-messages="errors"
                                        label="Tiêu đề *"
                                        placeholder="Nhập tiêu đề">
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" v-if="file.type == 'text'">
                            <div :style="errorContent ? 'font-size: 12px; color: #ff5252' : 'font-size: 12px'">Nội dung *</div>
<!--                            <vue-editor v-model="content" class="my-2"></vue-editor>-->
                              <ckeditor :editor="editor" v-model="content" :config="editorConfig" @ready="onReady"></ckeditor>
                            <div v-show="errorContent" style="font-size: 12px; color: #ff5252">Nội dung là bắt buộc</div>
                        </v-col>
                        <v-col cols="12" v-if="file.type == 'video'">
                            <validation-provider
                                    v-slot="{ errors }"
                                    class="mb-2"
                                    :rules="{
                                        required: true,
                                        regex: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
                                    }"
                                    name="Link">
                                <v-text-field
                                        v-model.trim="link"
                                        :error-messages="errors"
                                        label="Link *"
                                        placeholder="Nhập link">
                                </v-text-field>
                            </validation-provider>
                        </v-col>
                        <v-col cols="12" v-if="file.type == 'qna'">
                            <div style="font-size: 12px" class="d-flex">
                                Nội dung *
                                <v-spacer />
                                <v-btn
                                        small
                                        color="info"
                                        height="22px"
                                        @click="addRow()">
                                    Thêm câu hỏi <v-icon size="18px" class="ml-1">mdi-plus</v-icon>
                                </v-btn>
                            </div>
                            <ul class="mt-2">
                                <template v-for="(item, index) in qna">
                                    <li :key="index">
                                        <div style="font-size: 12px" class="d-flex">
                                            Câu hỏi {{ index + 1 }}
                                            <v-spacer />
                                            <v-btn
                                                    small
                                                    color="error"
                                                    height="22px"
                                                    v-if="index > 0"
                                                    @click="deleteRow(item.id)">
                                                Xóa <v-icon size="18px" class="ml-1">mdi-delete</v-icon>
                                            </v-btn>
                                        </div>
                                        <validation-provider
                                                v-slot="{ errors }"
                                                class="mb-2"
                                                :rules="index == 0 ? {required: true} : {}"
                                                :name="'Câu hỏi ' + (index + 1)">
                                            <v-text-field
                                                    v-model="item.question"
                                                    :error-messages="errors"
                                                    placeholder="Nhập câu hỏi">
                                            </v-text-field>
                                        </validation-provider>
                                        <validation-provider
                                                v-slot="{ errors }"
                                                class="mb-2"
                                                :rules="index == 0 ? {required: true} : {}"
                                                :name="'Câu trả lời ' + (index + 1)">
                                            <v-textarea
                                                    v-model="item.answer"
                                                    :error-messages="errors"
                                                    placeholder="Nhập câu trả lời"
                                                    rows="1"
                                                    auto-grow>
                                            </v-textarea>
                                        </validation-provider>
                                    </li>
                                </template>
                            </ul>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="text-center pt-0">
                    <v-btn
                            color="success"
                            @click.prevent="handleSubmit(saveFile)"
                            height="30px">
                        Lưu
                    </v-btn>
                    <v-btn
                            color="error"
                            @click="closeModal()"
                            height="30px">
                        Hủy
                    </v-btn>
                </v-card-actions>
            </validation-observer>
        </v-card>
    </v-dialog>
</template>

<script>
// import { VueEditor } from "vue2-editor"
import CKEditor from '@ckeditor/ckeditor5-vue2';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import RepositoryFactory from '@/repositories/RepositoryFactory'
const TrainingRepository = RepositoryFactory.get('training')
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
    components: {
      ckeditor: CKEditor.component
    },
    data() {
        return {
            showModal: false,
            file: {
                type: '',
                title: ''
            },
            content: '',
            qna: [{
                id: (new Date()).getTime(),
                question: '',
                answer: ''
            }],
            link: '',
            isCreate: true,
            fileType: [
                {
                    name: 'Văn bản',
                    type: 'text'
                },
                {
                    name: 'Video/ghi âm',
                    type: 'video'
                },
                {
                    name: 'Q&A',
                    type: 'qna'
                }
            ],
            errorContent: false,
            parentId: 0,
            editor: DecoupledEditor,
            editorConfig: {
              // The configuration of the editor.
            },
            type: ''
        }
    },
    created() {
        Bus.$on('show-file-create-form', (parentId, typePermission) => {
            this.file = {
                type: '',
                title: ''
            },
            this.content = ''
            this.qna = [{
                id: (new Date()).getTime(),
                question: '',
                answer: ''
            }]
            this.link = ''
            this.parentId = parentId
            this.isCreate = true
            this.showModal = true
            this.type = typePermission
        })

        Bus.$on('show-file-edit-form', file => {
            this.file = _.cloneDeep(file)
            this.isCreate = false

            if (file.type == 'text') {
                this.content = file.content
            }

            if (file.type == 'video') {
                this.link = file.content
            }

            if (file.type == 'qna') {
                this.qna = []
                if (_.isArray(file.content) && file.content.length) {
                    file.content.forEach(item => {
                        this.qna.push({
                            id: (new Date()).getTime(),
                            ...item 
                        })
                    })
                } else {
                    this.qna = [{
                        id: (new Date()).getTime(),
                        question: '',
                        answer: ''
                    }]
                }
            }

            this.parentId = file.folderId

            this.showModal = true
        })
    },
    methods: {
        onReady( editor )  {
          // Insert the toolbar before the editable area.
          editor.ui.getEditableElement().parentElement.insertBefore(
              editor.ui.view.toolbar.element,
              editor.ui.getEditableElement()
          );
        },
        closeModal() {
            this.showModal = false
        },
        addRow() {
            const qna = _.cloneDeep(this.qna)
            qna.push({
                id: (new Date()).getTime(),
                question: '',
                answer: ''
            })
            this.$set(this, 'qna', qna)
        },
        deleteRow(id) {
            const index = this.qna.findIndex(u => u.id === id)
            if (index > -1) this.qna.splice(index, 1)
        },
        async saveFile() {
            let content = _.cloneDeep(this.content)
            if (this.file.type == 'qna') {
                content = JSON.stringify(this.qna.map(item => {
                    return {
                        question: item.question,
                        answer: item.answer
                    }
                }))
            } else if (this.file.type == 'video') {
                content = _.cloneDeep(this.link)
            }

            let file = {
                title: this.file.title,
                type: this.file.type,
                content,
                parentId: this.parentId == 0 ? null : this.parentId,
                typePermission: this.type
            }

            if (this.isCreate) {
                let res = await TrainingRepository.createFile(file)
                if (res.success) {
                    this.closeModal()
                    Bus.$emit('training-document-reload')
                } else {
                    this.$swal({
                        title: 'Lỗi!',
                        text: _.get(res, 'message', '')
                    })
                }
            } else {
                let res = await TrainingRepository.updateFile(this.file.id, file)
                if (res.success) {
                    this.closeModal()
                    Bus.$emit('training-document-reload')
                    Bus.$emit('get-file-training-content', this.file.id)
                } else {
                    this.$swal({
                        title: 'Lỗi!',
                        text: _.get(res, 'message', '')
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.ck-rounded-corners .ck.ck-editor__editable:not(.ck-editor__nested-editable), .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  border: 1px solid;
}
</style>