var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"training-document-tree"},[_c('div',{staticStyle:{"margin-top":"20px"}}),_c('v-tabs',{attrs:{"grow":""},on:{"change":_vm.changeTab},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.tabs),function(item,key){return _c('v-tab',{key:key},[_vm._v(" "+_vm._s(item)+" ")])})],2),_c('div',{staticClass:"document-tree-container"},_vm._l((_vm.tree),function(item,index){return _c('div',{key:index},[(item.type == 'folder')?[_c('div',{staticClass:"d-flex content-tree-note"},[_c('div',{staticClass:"tree-header-icon",on:{"click":function($event){item.isShow = !item.isShow;
              _vm.showContent(item, $event);}}},[(item.isShow)?_c('v-icon',{staticClass:"icon-rotate",staticStyle:{"margin-right":"2px"}},[_vm._v("mdi-play")]):_c('v-icon',{staticStyle:{"margin-right":"2px"}},[_vm._v("mdi-play")]),_c('v-icon',{staticStyle:{"color":"#ffad33"}},[_vm._v("mdi-folder-open")])],1),_c('div',{staticClass:"tree-header-name",on:{"click":function($event){item.isShow = !item.isShow;
              _vm.showContent(item, $event);}}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-spacer',{on:{"click":function($event){item.isShow = !item.isShow;
              _vm.showContent(item, $event);}}}),_c('div',{staticClass:"tree-action-btn"},[(
                (_vm.checkAuthorization('training-sale_update') &&
                  _vm.typePermission == 'sale') ||
                (_vm.checkAuthorization('training-manager_update') &&
                  _vm.typePermission == 'manager')
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"margin-right":"2px"},on:{"click":function($event){return _vm.increasedOrder(item)}}},on),[_vm._v("mdi-arrow-collapse-up")])]}}],null,true)},[_c('span',[_vm._v("Tăng bậc")])]):_vm._e(),(
                (_vm.checkAuthorization('training-sale_update') &&
                  _vm.typePermission == 'sale') ||
                (_vm.checkAuthorization('training-manager_update') &&
                  _vm.typePermission == 'manager')
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"margin-right":"2px"},on:{"click":function($event){return _vm.editTraining(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Cập nhật")])]):_vm._e(),(
                (_vm.checkAuthorization('training-sale_delete') &&
                  _vm.typePermission == 'sale') ||
                (_vm.checkAuthorization('training-manager_delete') &&
                  _vm.typePermission == 'manager')
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.deleteTraining(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Xóa")])]):_vm._e()],1)],1),_c('tree-note',{directives:[{name:"show",rawName:"v-show",value:(item.isShow),expression:"item.isShow"}],attrs:{"noteData":item.children,"keyName":index,"typePermission":"typePermission"}})]:[_c('div',{staticClass:"d-flex content-tree-note"},[_c('div',{staticClass:"tree-header-icon",on:{"click":function($event){return _vm.showContent(item, $event)}}},[(item.type == 'text')?_c('v-icon',{staticStyle:{"color":"#0066ff"}},[_vm._v("mdi-file-document")]):_vm._e(),(item.type == 'pdf')?_c('v-icon',{staticStyle:{"color":"#ff3300"}},[_vm._v("mdi-file-pdf")]):_vm._e(),(item.type == 'video')?_c('v-icon',{staticStyle:{"color":"#ff3300"}},[_vm._v("mdi-play-circle")]):_vm._e(),(item.type == 'qna')?_c('v-icon',{staticStyle:{"color":"#0066ff"}},[_vm._v("mdi-help-circle")]):_vm._e()],1),_c('div',{staticClass:"tree-header-name",on:{"click":function($event){return _vm.showContent(item, $event)}}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-spacer',{on:{"click":function($event){return _vm.showContent(item, $event)}}}),_c('div',{staticClass:"tree-action-btn"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"margin-right":"2px"},on:{"click":function($event){return _vm.increasedOrder(item)}}},on),[_vm._v("mdi-arrow-collapse-up")])]}}],null,true)},[_c('span',[_vm._v("Tăng bậc")])]),(
                (_vm.checkAuthorization('training-sale_update') &&
                  _vm.typePermission == 'sale' &&
                  !['pdf'].includes(item.type)) ||
                (_vm.checkAuthorization('training-manager_update') &&
                  _vm.typePermission == 'manager' &&
                  !['pdf'].includes(item.type))
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticStyle:{"margin-right":"2px"},on:{"click":function($event){return _vm.editTraining(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Cập nhật")])]):_vm._e(),(
                (_vm.checkAuthorization('training-sale_delete') &&
                  _vm.typePermission == 'sale') ||
                (_vm.checkAuthorization('training-manager_delete') &&
                  _vm.typePermission == 'manager')
              )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.deleteTraining(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Xóa")])]):_vm._e()],1)],1)]],2)}),0),_c('div',[_c('v-text-field',{staticClass:"search-file-name",attrs:{"outlined":"","hide-details":"","placeholder":"Nhập tên tệp","prepend-inner-icon":"mdi-magnify"},on:{"change":_vm.getTreeTraining,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getTreeTraining.apply(null, arguments)}},model:{value:(_vm.fileName),callback:function ($$v) {_vm.fileName=$$v},expression:"fileName"}})],1),_c('div',{staticClass:"training-action-btn d-flex justify-space-around"},[(
        (_vm.checkAuthorization('training-sale_download') &&
          _vm.typePermission == 'sale') ||
        (_vm.checkAuthorization('training-manager_download') &&
          _vm.typePermission == 'manager')
      )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"traning-btn-upload",staticStyle:{"padding":"0px 5px !important"},on:{"click":function($event){return _vm.clickUpload()}}},on),[_c('v-icon',{attrs:{"size":"25px"}},[_vm._v("mdi-upload")])],1)]}}],null,false,1684178313)},[_c('span',[_vm._v("Upload file PDF")])]):_vm._e(),[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":0,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [(
              (_vm.checkAuthorization('training-sale_create') &&
                _vm.typePermission == 'sale') ||
              (_vm.checkAuthorization('training-manager_create') &&
                _vm.typePermission == 'manager')
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-show-modal-count-interaction",attrs:{"small":"","color":"pink","dark":"","icon":""}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[_c('v-icon',{staticStyle:{"cursor":"pointer","color":"#ffad33"},attrs:{"size":"40px"}},[_vm._v(" mdi-folder-plus ")])],1)]}}],null,true)},[_c('span',[_vm._v("Tạo folder")])]):_vm._e()]}}])},[_c('v-list',_vm._l((_vm.typeCreate),function(item){return _c('v-list-item',{attrs:{"link":""}},[_c('span',{on:{"click":function($event){return _vm.createFolder(item.value)}}},[_vm._v("Tạo folder "+_vm._s(item.text))])])}),1)],1)],[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":0,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [(
              (_vm.checkAuthorization('training-sale_create') &&
                _vm.typePermission == 'sale') ||
              (_vm.checkAuthorization('training-manager_create') &&
                _vm.typePermission == 'manager')
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-show-modal-count-interaction",attrs:{"small":"","color":"pink","dark":"","icon":""}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[_c('v-icon',{staticStyle:{"cursor":"pointer","color":"#0066ff"},attrs:{"size":"35px"}},[_vm._v(" mdi-file-document-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Tạo file")])]):_vm._e()]}}])},[_c('v-list',_vm._l((_vm.typeCreate),function(item){return _c('v-list-item',{attrs:{"link":""}},[_c('span',{on:{"click":function($event){return _vm.createFile(item.value)}}},[_vm._v("Tạo file "+_vm._s(item.text))])])}),1)],1)],_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":"input-training-pdf"},on:{"change":function($event){return _vm.uploadFile()}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }