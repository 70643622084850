<template>
  <v-container id="edit-role" fluid tag="section">
    <v-card class="mx-auto pa-5" outlined>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(update)">
          <h3 style="text-transform: uppercase">Thông tin quyền</h3>

          <v-alert
            v-show="alertMessageText"
            v-model="alert"
            :type="alertMessageType"
            dismissible
          >
            {{ alertMessageText }}
          </v-alert>

          <v-col cols="12" md="12">
            <div class="mt-2 text-right">
              <v-btn
                class="mb-2"
                color="primary"
                @click.prevent="
                  handleSubmit(update);
                  scrollToError();
                "
              >
                {{ isNew ? "Tạo" : "Cập nhật" }}
              </v-btn>
              <v-btn class="mb-2" color="default" @click="goToList">
                Hủy bỏ
              </v-btn>
            </div>
          </v-col>

          <v-row>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Tên quyền"
                rules="required|max:255"
              >
                <v-text-field
                  label="Tên quyền *"
                  color="primary"
                  :error-messages="errors"
                  v-model="role.name"
                />
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <v-textarea
                label="Mô tả quyền"
                color="primary"
                v-model="role.description"
              />
            </v-col>

            <v-col cols="12" md="12">
              <h3 style="text-transform: uppercase; text-align: left">
                Chọn quyền
              </h3>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th rowspan="2"></th>
                      <th
                        v-for="action in actions"
                        :key="action.name"
                        style="text-transform: uppercase; text-align: left"
                      >
                        {{ action.text }}
                      </th>
                    </tr>
                    <tr>
                      <th v-for="action in actions" :key="action.name">
                        <v-checkbox
                          v-model="selectAll"
                          :value="action.name"
                          @change="onSelectAll(action)"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="model in models" :key="model.name">
                      <td v-bind:class="'permission-list level-' + model.level">
                        <v-icon v-if="model.level == '2'">mdi-lastpass</v-icon>
                        <v-icon v-if="model.level == '3'" class="ml-5"
                          >mdi-lastpass</v-icon
                        >
                        {{ model.text }}
                      </td>
                      <td v-for="action in actions" :key="action.name">
                        <v-checkbox
                          hide-details
                          class="shrink mr-2 mt-0"
                          v-model="permissions"
                          :value="`${action.name}-${model.name}`"
                          @change="
                            changeCheckBox(model.name, action.name, actions)
                          "
                          v-if="model.permissions.includes(action.name)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>

<script>
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { models, actions, getPermissionByAction } from "@/helpers/permission";

const RoleRepository = RepositoryFactory.get("role");

export default {
  data() {
    return {
      isNew: true,
      role: {},
      permissions: [],
      alert: true,
      alertMessageType: "success",
      alertMessageText: null,
      loading: false,
      models,
      actions,
      selectAll: [],
    };
  },
  created() {
    let id = 0;
    if ("id" in this.$route.params) {
      id = this.$route.params.id;
    }
    this.role.id = id;
    this.isNew = !id;
    this.fetchRoleDetail();
  },
  methods: {
    changeCheckBox(modelName, actionName, actions = []) {
      const valueReadCheckBox = `read-${modelName}`;
      const valueAllCheckBox = `view_all-${modelName}`;
      const valueCheckBox = `${actionName}-${modelName}`;

      if (
        valueCheckBox === "read-contact_change_user" &&
        !this.permissions.includes(valueCheckBox)
      ) {
        this.unCheckBox("contact", "create");
        return;
      }

      if (
        valueCheckBox === "read-contact_change_phone" &&
        !this.permissions.includes(valueCheckBox)
      ) {
        this.unCheckBox("contact", "create");
        return;
      }

      if (actionName === "read" || actionName === "view_all") {
        return;
      }
      if (!this.permissions.includes(valueCheckBox)) {
        return;
      }

      if (valueCheckBox === "create-contact") {
        this.selectCheckBox("contact_change_user", "read");
        this.selectCheckBox("contact_change_phone", "read");
      }

      if (
        this.permissions.includes(valueAllCheckBox) ||
        this.permissions.includes(valueReadCheckBox)
      ) {
        return;
      }

      this.permissions.push(valueReadCheckBox);
    },
    unCheckBox(modelName, actionName) {
      const valueCheckBox = `${actionName}-${modelName}`;
      const indexCheckbox = this.permissions.indexOf(valueCheckBox);
      if (indexCheckbox !== -1) {
        this.permissions.splice(indexCheckbox, 1);
      }
    },
    selectCheckBox(modelName, actionName) {
      const valueCheckBox = `${actionName}-${modelName}`;
      const indexCheckbox = this.permissions.indexOf(valueCheckBox);
      if (indexCheckbox == -1) {
        this.permissions.push(valueCheckBox);
      }
    },
    onSelectAll(action) {
      const permissions = getPermissionByAction(action.name);
      if (this.selectAll.includes(action.name)) {
        // select all
        this.permissions.push(...permissions);
        this.permissions = [...new Set(this.permissions)];
        this.models.forEach((model) => {
          this.changeCheckBox(model.name, action.name, this.actions);
        });
      } else {
        // uncheck all
        this.permissions = this.permissions.filter(function (item) {
          return item.indexOf(action.name + "-") != 0;
        });
      }
    },
    goToList() {
      this.$router.push({
        name: "RoleList",
      });
    },
    setAlertMessage(alert, type, text) {
      this.alert = alert;
      this.alertMessageType = type;
      this.alertMessageText = text;
    },
    async fetchRoleDetail() {
      if (_.get(this.role, "id", 0)) {
        this.loading = this.$loading.show();
        const response = await RoleRepository.detail(_.get(this.role, "id", 0));
        this.loading.hide();

        if (response.error) {
          this.setAlertMessage(
            true,
            "error",
            "Lấy thông tin chi tiết Role thất bại!"
          );
        } else {
          this.role = response.data ? response.data : {};
          const { permissions = [] } = this.role;
          const permissionsResult = [];
          permissions.forEach((dataPermission) => {
            let checkExist = this.models.find(
              (m) => m.name == dataPermission.model
            );
            if (
              checkExist &&
              checkExist.permissions.includes(dataPermission.action)
            ) {
              permissionsResult.push(
                `${dataPermission.action}-${dataPermission.model}`
              );
            }
          });
          this.permissions = permissionsResult;
        }
      }
    },
    async update() {
      let checkRole = false;
      for (let permission of this.permissions) {
        let pTemp = permission.split("-") || [];
        const action = _.get(pTemp, "[0]", "");
        if (action !== "read" && action !== "view_all") {
          pTemp.splice(0, 1);
          const model = pTemp.join("-");
          const valueRead = `read-${model}`;
          const valueAll = `view_all-${model}`;
          if (
            !this.permissions.includes(valueAll) &&
            !this.permissions.includes(valueRead)
          ) {
            checkRole = true;
          }
        }
      }

      if (checkRole) {
        return this.setAlertMessage(
          true,
          "error",
          "Vui lòng chọn quyền truy cập khi thêm mấy quyền khác."
        );
      }

      const role = {
        name: _.get(this.role, "name", ""),
        description: _.get(this.role, "description", ""),
        permissions: this.permissions.map((p) => {
          let pTemp = p.split("-") || [];
          const action = _.get(pTemp, "[0]", "");
          pTemp.splice(0, 1);
          const model = pTemp.join("-");
          return {
            action,
            model,
          };
        }),
        status: "ACTIVE",
      };

      this.loading = this.$loading.show();

      if (this.isNew) {
        const response = await RoleRepository.create(role);
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Thêm quyền thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.$router.push({
              name: "RoleList",
            });
          });
        }
      } else {
        const response = await RoleRepository.update(
          _.get(this.role, "id", 0),
          role
        );
        if (response.error) {
          this.loading.hide();
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.loading.hide();
          this.$swal({
            title: "Cập nhật quyền thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.$router.push({
              name: "RoleList",
            });
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.permission-list {
  &.level-2 > i,
  &.level-3 > i {
    transform: rotateY(180deg);
  }
}

.permission-list.level-1 {
  font-weight: 700;
  text-transform: uppercase;
}
</style>