<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{}" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold mb-8">
          <template v-if="type == 'view'"> Danh sách thành viên </template>
          <template v-else> Thêm thành viên </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row class="member-list-body">
            <v-col class="pb-0" cols="6" v-for="(mem, index) in listMembers" :key="'mem' + index">
              <v-checkbox
                class="ma-0"
                hide-details
                @change="saveMember(mem)"
                v-model="mem.value"
              >
                <template @click.stop v-slot:label>
                  <div style="display: flex" class="align-center mr-4">
                    <v-img
                      v-if="mem.imageUrl"
                      style="flex: none; border-radius: 50%"
                      width="40"
                      height="40"
                      :alt="`${mem.name}`"
                      :src="mem.imageUrl"
                    />
                    <v-img
                      v-else
                      style="flex: none; border-radius: 50%"
                      width="40"
                      height="40"
                      src="../../../assets/default-avatar.jpg"
                    ></v-img>
                  </div>
                  {{ mem.userName }}
                </template>
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row class="mt-4" v-if="totalItem > limit">
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6">
              <v-pagination
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form justify-center">
          <v-btn color="primary" @click="hideModal"> Đóng </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const GroupRepository = RepositoryFactory.get("group");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      errorMessage: "",
      showModal: false,
      listMembers: [],
      totalItem: 0,
      limit: 10,
      currentPage: 0,
      type: "",
      group: {},
    };
  },
  created() {
    Bus.$on("view-member-show-modal", (group, type) => {
      this.group = group;
      this.type = type;
      this.showModal = true;
      this.getListMembers(this.group.id);
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.errorMessage = "";
      this.listMembers = [];
    },
    async saveMember(member) {
      let response = null;
      let body = {
        userId: member.id,
        groupId: this.group.id,
      };
      const loader = this.$loading.show();
      if (member.value) {
        response = await GroupRepository.addOneMember(body);
      } else {
        response = await GroupRepository.removeOneMember(body);
      }
      loader.hide();
      if (response.success) {
        this.$swal({
          type: "success",
          title: "Success!",
          text: member.value ? "Đã thêm thành viên!" : "Đã xóa thành viên!",
        });
        Bus.$emit("group-list-reload");
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListMembers(groupId) {
      let response = {};
      let params = {
        page: this.currentPage,
        perPage: this.limit,
      };
      const loader = this.$loading.show();
      if (this.type == "view") {
        response = await GroupRepository.listMembers(groupId, params);
      } else {
        response = await GroupRepository.listMembersForSelect(groupId, params);
      }
      loader.hide();
      if (response.success) {
        this.listMembers = _.get(response, "data.items", []).map((item) => ({
          ...item,
          value: this.type == "view" ? true : false,
        }));
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
  },
  beforeDestroy() {
    Bus.$off("view-member-show-modal");
  },
  watch: {
    currentPage: {
      handler() {
        this.getListMembers(this.group.id);
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getListMembers(this.group.id);
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
};
</script>