<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới </template>
          <template v-else> Cập nhật thông tin </template>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên môn học"
              >
                <v-text-field
                  v-model="subject.name"
                  label="Tên môn học*"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
              >
                <v-text-field
                  v-model="subject.slug"
                  label="Đường dẫn"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="max:255"
                name="Code"
              >
                <v-text-field
                  v-model="subject.code"
                  label="Code"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveSubject)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const SubjectRepository = RepositoryFactory.get("subject");
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      subject: {
        name: "",
        slug: "",
        code: "",
      },
      isCreate: true,
      subjects: [],
      errorMessage: "",
    };
  },
  created() {
    Bus.$on("subject-show-form", (subject) => {
      this.showModal = true;
      this.subject = _.cloneDeep(subject);
      if (subject.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveSubject() {
      const subject = {
        name: _.trim(this.subject.name),
        slug: _.trim(this.subject.slug),
        code: _.trim(this.subject.code),
      };
      if (this.isCreate) {
        const loader = this.$loading.show();
        const response = await SubjectRepository.create(subject);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("subject-list-reload");
          });
        }
        loader.hide();
      } else {
        const loader = this.$loading.show();
        const response = await SubjectRepository.update(
          this.subject.id,
          subject
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("subject-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
