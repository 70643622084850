<template>
  <v-dialog v-model="showModal" max-width="700" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer v-slot="{ handleSubmit }" ref="validator">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mới</template>
          <template v-else> Cập nhật thông tin</template>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="required|max:255"
                name="Tên quốc gia"
              >
                <v-text-field
                  v-model="language.name"
                  label="Tên *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                rules="max:255"
                name="Code"
              >
                <v-text-field
                  v-model="language.code"
                  label="Code"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn"
              >
                <v-text-field
                  v-model="language.slug"
                  label="Đường dẫn"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Đường dẫn icon"
              >
                <v-text-field
                  v-model="language.imageUrl"
                  label="Đường dẫn icon"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(savelanguage)">
            Lưu lại
          </v-btn>
          <v-spacer />

          <v-btn color="primary" @click="hideModal"> Hủy bỏ </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const LanguageRepository = RepositoryFactory.get("language");
export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      language: {
        name: "",
        type: "",
      },
      isCreate: true,
      errorMessage: "",
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ],
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ],
        ["image", "code-block"],
      ],
    };
  },
  created() {
    Bus.$on("language-show-form", (language) => {
      this.showModal = true;
      this.language = _.cloneDeep(language);
      if (language.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async savelanguage() {
      if (this.isCreate) {
        const language = {
          name: _.trim(this.language.name),
          slug: _.trim(this.language.slug),
          code: _.trim(this.language.code),
          imageUrl: _.trim(this.language.imageUrl),
        };
        const loader = this.$loading.show();
        const response = await LanguageRepository.create(language);
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("language-list-reload");
          });
        }
        loader.hide();
      } else {
        const language = {
          name: _.trim(this.language.name),
          slug: _.trim(this.language.slug),
          code: _.trim(this.language.code),
          imageUrl: _.trim(this.language.imageUrl),
        };
        const loader = this.$loading.show();
        const response = await LanguageRepository.update(
          this.language.id,
          language
        );
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.showModal = false;
          this.errorMessage = "";
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("language-list-reload");
          });
        }
        loader.hide();
      }
    },
  },
};
</script>
