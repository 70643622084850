<template>
    <ul>
        <li v-for="(item, index) in noteData" :key="keyName + '_' + index">
            <template v-if="item.type == 'folder'">
                <div class="d-flex content-tree-note">
                    <div class="tree-header-icon" @click="item.isShow = !item.isShow; showContent(item, $event)">
                        <v-icon style="margin-right: 2px" class="icon-rotate" v-if="item.isShow">mdi-play</v-icon>
                        <v-icon style="margin-right: 2px" v-else>mdi-play</v-icon>
                        <v-icon style="color: #ffad33">mdi-folder-open</v-icon>
                    </div>
                    <div class="tree-header-name" @click="item.isShow = !item.isShow; showContent(item, $event)">
                        {{ item.title }}
                    </div>
                    <v-spacer @click="item.isShow = !item.isShow; showContent(item, $event)"/>
                    <div class="tree-action-btn">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon style="margin-right: 2px" v-on="on" @click="increasedOrder(item)">mdi-arrow-collapse-up</v-icon>
                            </template>
                            <span>Tăng bậc</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(checkAuthorization('training-sale_update') && typePermission == 'sale') ||
                              (checkAuthorization('training-manager_update') && typePermission == 'manager')"
                            bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon style="margin-right: 2px" v-on="on" @click="editTraining(item)">mdi-pencil</v-icon>
                            </template>
                            <span>Cập nhật</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(checkAuthorization('training-sale_delete') && typePermission == 'sale') ||
                                        (checkAuthorization('training-manager_delete') && typePermission == 'manager')"
                            bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteTraining(item)">mdi-delete</v-icon>
                            </template>
                            <span>Xóa</span>
                        </v-tooltip>
                    </div>
                </div>
                <tree-note-in-training v-show="item.isShow" :noteData="item.children" :keyName="keyName + '_' + index" />
            </template>
            <template v-else>
                    <div class="d-flex content-tree-note">
                        <div class="tree-header-icon" @click="showContent(item, $event)">
                            <v-icon style="color: #0066ff" v-if="item.type == 'text'">mdi-file-document</v-icon>
                            <v-icon style="color: #ff3300" v-if="item.type == 'pdf'">mdi-file-pdf</v-icon>
                            <v-icon style="color: #ff3300" v-if="item.type == 'video'">mdi-play-circle</v-icon>
                            <v-icon style="color: #0066ff" v-if="item.type == 'qna'">mdi-help-circle</v-icon>
                        </div>
                    <div class="tree-header-name" @click="showContent(item, $event)">
                        {{ item.title }}
                    </div>
                    <v-spacer @click="showContent(item, $event)"/>
                    <div class="tree-action-btn">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon style="margin-right: 2px" v-on="on" @click="increasedOrder(item)">mdi-arrow-collapse-up</v-icon>
                            </template>
                            <span>Tăng bậc</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(checkAuthorization('training-sale_update') && typePermission == 'sale' && !['pdf'].includes(item.type)) ||
                                (checkAuthorization('training-manager_update') && typePermission == 'manager' && !['pdf'].includes(item.type))"
                            bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon style="margin-right: 2px" v-on="on" @click="editTraining(item)">mdi-pencil</v-icon>
                            </template>
                            <span>Cập nhật</span>
                        </v-tooltip>
                        <v-tooltip
                            v-if="(checkAuthorization('training-sale_delete') && typePermission == 'sale') ||
                                        (checkAuthorization('training-manager_delete') && typePermission == 'manager')"
                            bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon v-on="on" @click="deleteTraining(item)">mdi-delete</v-icon>
                            </template>
                            <span>Xóa</span>
                        </v-tooltip>
                    </div>
                </div>
            </template>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'tree-note-in-training',
    props: {
        noteData: {
            type: Array,
            default: () => {[]}
        },
        keyName: {
            default: ''
        },
        typePermission: {
            type: String,
            default: ''
        }
    },
    methods: {
        showContent(item, e) {
            let element = e.target

            if (!element.className.includes('content-tree-note')) {
                element = e.target.closest('.content-tree-note')
            }

            if (!element.className.includes('active')) {
                let removeActive = document.getElementsByClassName('content-tree-note active')
                if (removeActive.length) {
                    removeActive[0].classList.remove('active')
                }
                element.classList.add('active')
            }

            if (item.type != 'folder') {
                Bus.$emit('change-parentId-for-create', item.folderId)
                Bus.$emit('get-file-training-content', item.id)
            } else {
                Bus.$emit('change-parentId-for-create', item.id)
                Bus.$emit('get-folder-training-content', item)
            }
        },
        editTraining(item) {
            if (item.type == 'folder') {
                Bus.$emit('show-folder-edit-form', item)
            } else {
                Bus.$emit('get-file-for-update', item.id)
            }
        },
        deleteTraining(item) {
            Bus.$emit('delete-training-from-tree', item)
        },
        increasedOrder(item) {
            Bus.$emit('increased-order-in-training-tree', item)
        }
    }
}
</script>

<style scoped>
ul {
    padding-left: 20px !important;
}
.tree-header-icon {
    min-width: 52px;
    padding: 0px 5px;
    text-align: right;
}

.icon-rotate {
    transform: rotate(90deg);
}

.content-tree-note {
    font-size: 16px;
    margin: 4px 0px;
}

.content-tree-note:hover, .content-tree-note.active {
    background-color: #e6f2ff;
    cursor: pointer;
}

.content-tree-note .tree-action-btn {
    display: none;
}

.content-tree-note:hover .tree-action-btn, .content-tree-note.active .tree-action-btn {
    display: inherit;
}

.tree-header-name {
    max-width: calc(100% - 130px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>