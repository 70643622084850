<template>
  <div>
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('category_create')"
        class="primary mx-0"
        @click="addNewCategory"
      >
        <v-icon>mdi-plus</v-icon>Thêm
      </v-btn>
    </div>

  <validation-observer v-slot="{ handleSubmit }" ref="validator">
    <v-simple-table class="table-layout pa-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="th-id">#</th>
            <th>Code</th>
            <th>Tên hãng</th>
            <th>Đường dẫn</th>
            <th v-if="showAction">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(category, index) in categories">
            <tr :key="category.id">
              <td>{{ index + 1 }}</td>
              <td>{{ _.get(category, "code") }}</td>
              <td v-if="!category.showEdit.name"
                  @dblclick="enableEditAttribute(category, 'name')">{{ _.get(category, "name") }}</td>
              <div v-else>
                <validation-provider
                  v-slot="{ errors }"
                  class="mb-2"
                  rules="required|max:255"
                  name="Tên danh mục"
                >
                  <v-text-field
                    @dblclick="enableEditAttribute(category, 'name')"
                    v-model="category.name"
                    :error-messages="errors"
                    color="primary"
                    @keyup.enter="handleSubmit(updateCategory(category, errors))"
                  />
                </validation-provider>
              </div>
              <td>{{ _.get(category, "slug") }}</td>
              <td v-if="showAction">
                <div class="action">
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('category_update')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="editCategory(category)"
                        size="22"
                        icon
                        small
                        color="primary"
                        dark
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    <span>Cập nhật thông tin</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    v-if="checkAuthorization('category_delete')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="mr-2"
                        @click="onClickDelete(category)"
                        size="22"
                        icon
                        small
                        color="info"
                        dark
                        v-on="on"
                        ><v-icon>mdi-window-close</v-icon></v-btn
                      >
                    </template>
                    <span>Xóa</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </validation-observer>
    <category-form />
  </div>
</template>
<script>
import CategoryForm from "./CategoryForm.vue";
import RepositoryFactory from "../../../../repositories/RepositoryFactory";
const categoryRepository = RepositoryFactory.get("category");
export default {
  components: { CategoryForm },
  props: {
    filterForm: {
      type: Object,
      default: () => {},
    },
    categories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedCategory: {},
      modalAlert: false,
      errorMessage: "",
    };
  },
  created() {},
  computed: {
    showAction() {
      return (
        this.checkAuthorization("category_update") ||
        this.checkAuthorization("category_delete")
      );
    },
  },
  methods: {
    addNewCategory() {
      this.selectedCategory = {};
      Bus.$emit("category-show-form", this.selectedCategory);
    },
    editCategory(category) {
      this.selectedCategory = category;
      Bus.$emit("category-show-form", this.selectedCategory);
    },
    async onClickDelete(category) {
      this.$swal({
        title: "Xóa",
        text: "Bạn có thực sự muốn xóa ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Đồng ý",
        cancelButtonText: "Hủy bỏ",
      }).then(async (result) => {
        if (result.value) {
          const loader = this.$loading.show();
          const respond = await categoryRepository.delete(category.id);
          loader.hide();
          if (_.get(respond, "success", false)) {
            this.$swal({
              title: "Đã xóa thành công !",
              confirmButtonText: "Đồng ý",
            }).then(() => {
              Bus.$emit("category-list-reload");
            });
          } else {
            this.$swal({
              type: "error",
              title: "Lỗi !",
              text: _.get(respond, "message", ""),
            });
          }
        }
      });
    },
    enableEditAttribute(category, attr) {
      if (!category[attr]) {
        return
      }
      category.showEdit[attr] = !category.showEdit[attr];
    },
    updateCategory(category, errors) {
      if(!errors.length) {
       Bus.$emit('update-category', category) 
      }
    }
  },
};
</script>
