<template>
  <v-expansion-panels class="mb-4">
    <v-expansion-panel>
      <v-expansion-panel-header>Tìm kiếm khách hàng</v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card class="ma-0" outlined>
          <v-list-item class="pa-0" three-line>
            <v-list-item-content class="pa-0">
              <v-row>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-model="filterForm.keyword"
                    placeholder="Tên ..."
                    label="Tìm kiếm theo tên"
                    clearable
                    @keyup.enter="searchUser"
                  />
                </v-col>

                <v-col cols="12" sm="3">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    :rules="{
                      max: 11,
                    }"
                    name="Số điện thoại"
                  >
                    <v-text-field
                      v-model.trim="filterForm.phoneNumber"
                      label="Tìm kiếm theo số điện thoại"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="3">
                  <validation-provider
                    v-slot="{ errors }"
                    class="mb-2"
                    name="email"
                  >
                    <v-text-field
                      v-model.trim="filterForm.email"
                      label="Tìm kiếm theo email"
                      :error-messages="errors"
                      color="primary"
                    />
                  </validation-provider>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-autocomplete
                    v-model="filterForm.position"
                    :items="customerPositionOptions"
                    item-text="text"
                    item-value="value"
                    label="Tìm kiếm theo chức danh"
                    placeholder="Chức danh"
                    color="primary"
                    clearable
                  />
                </v-col>
              </v-row>
              <div class="text-center mb-4">
                <v-btn class="primary mx-0" @click="searchUser">
                  <v-icon>mdi-magnify</v-icon>Tìm kiếm
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { customerPositionOptions, regex } from "../../../helpers/constants";
export default {
  name: "FilterForm",
  props: {
    filterForm: {
      default: () => ({}),
    },
    departments: {
      default: () => [],
    },
  },
  data() {
    return {
      user: {},
      customerPositionOptions,
      regex,
    };
  },
  methods: {
    searchUser() {
      Bus.$emit("customer-list-filter", this.filterForm);
    },
  },
};
</script>
