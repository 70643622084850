<template>
  <div class="training-document-tree">
    <div style="margin-top: 20px"></div>
    <v-tabs v-model="tab" grow @change="changeTab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab v-for="(item, key) in tabs" :key="key">
        {{ item }}
      </v-tab>
    </v-tabs>
    <div class="document-tree-container">
      <div v-for="(item, index) in tree" :key="index">
        <template v-if="item.type == 'folder'">
          <div class="d-flex content-tree-note">
            <div
              class="tree-header-icon"
              @click="
                item.isShow = !item.isShow;
                showContent(item, $event);
              "
            >
              <v-icon
                style="margin-right: 2px"
                class="icon-rotate"
                v-if="item.isShow"
                >mdi-play</v-icon
              >
              <v-icon style="margin-right: 2px" v-else>mdi-play</v-icon>
              <v-icon style="color: #ffad33">mdi-folder-open</v-icon>
            </div>
            <div
              class="tree-header-name"
              @click="
                item.isShow = !item.isShow;
                showContent(item, $event);
              "
            >
              {{ item.title }}
            </div>
            <v-spacer
              @click="
                item.isShow = !item.isShow;
                showContent(item, $event);
              "
            />
            <div class="tree-action-btn">
              <v-tooltip
                v-if="
                  (checkAuthorization('training-sale_update') &&
                    typePermission == 'sale') ||
                  (checkAuthorization('training-manager_update') &&
                    typePermission == 'manager')
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    style="margin-right: 2px"
                    v-on="on"
                    @click="increasedOrder(item)"
                    >mdi-arrow-collapse-up</v-icon
                  >
                </template>
                <span>Tăng bậc</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  (checkAuthorization('training-sale_update') &&
                    typePermission == 'sale') ||
                  (checkAuthorization('training-manager_update') &&
                    typePermission == 'manager')
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    style="margin-right: 2px"
                    v-on="on"
                    @click="editTraining(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Cập nhật</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  (checkAuthorization('training-sale_delete') &&
                    typePermission == 'sale') ||
                  (checkAuthorization('training-manager_delete') &&
                    typePermission == 'manager')
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="deleteTraining(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Xóa</span>
              </v-tooltip>
            </div>
          </div>
          <tree-note
            v-show="item.isShow"
            :noteData="item.children"
            :keyName="index"
            typePermission="typePermission"
          />
        </template>
        <template v-else>
          <div class="d-flex content-tree-note">
            <div class="tree-header-icon" @click="showContent(item, $event)">
              <v-icon style="color: #0066ff" v-if="item.type == 'text'"
                >mdi-file-document</v-icon
              >
              <v-icon style="color: #ff3300" v-if="item.type == 'pdf'"
                >mdi-file-pdf</v-icon
              >
              <v-icon style="color: #ff3300" v-if="item.type == 'video'"
                >mdi-play-circle</v-icon
              >
              <v-icon style="color: #0066ff" v-if="item.type == 'qna'"
                >mdi-help-circle</v-icon
              >
            </div>
            <div class="tree-header-name" @click="showContent(item, $event)">
              {{ item.title }}
            </div>
            <v-spacer @click="showContent(item, $event)" />
            <div class="tree-action-btn">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    style="margin-right: 2px"
                    v-on="on"
                    @click="increasedOrder(item)"
                    >mdi-arrow-collapse-up</v-icon
                  >
                </template>
                <span>Tăng bậc</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  (checkAuthorization('training-sale_update') &&
                    typePermission == 'sale' &&
                    !['pdf'].includes(item.type)) ||
                  (checkAuthorization('training-manager_update') &&
                    typePermission == 'manager' &&
                    !['pdf'].includes(item.type))
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    style="margin-right: 2px"
                    v-on="on"
                    @click="editTraining(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
                <span>Cập nhật</span>
              </v-tooltip>
              <v-tooltip
                v-if="
                  (checkAuthorization('training-sale_delete') &&
                    typePermission == 'sale') ||
                  (checkAuthorization('training-manager_delete') &&
                    typePermission == 'manager')
                "
                bottom
              >
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="deleteTraining(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Xóa</span>
              </v-tooltip>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div>
      <v-text-field
        @change="getTreeTraining"
        v-model="fileName"
        outlined
        hide-details
        placeholder="Nhập tên tệp"
        prepend-inner-icon="mdi-magnify"
        class="search-file-name"
        @keyup.enter="getTreeTraining"
      />
    </div>
    <div class="training-action-btn d-flex justify-space-around">
      <v-tooltip
        v-if="
          (checkAuthorization('training-sale_download') &&
            typePermission == 'sale') ||
          (checkAuthorization('training-manager_download') &&
            typePermission == 'manager')
        "
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            class="traning-btn-upload"
            style="padding: 0px 5px !important"
            v-on="on"
            @click="clickUpload()"
          >
            <v-icon size="25px">mdi-upload</v-icon>
          </v-btn>
        </template>
        <span>Upload file PDF</span>
      </v-tooltip>
      <template>
        <v-menu :close-on-content-click="false" :nudge-width="0" offset-x>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip
              v-if="
                (checkAuthorization('training-sale_create') &&
                  typePermission == 'sale') ||
                (checkAuthorization('training-manager_create') &&
                  typePermission == 'manager')
              "
              bottom
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="btn-show-modal-count-interaction"
                  small
                  color="pink"
                  dark
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  icon
                >
                  <v-icon size="40px" style="cursor: pointer; color: #ffad33">
                    mdi-folder-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>Tạo folder</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item v-for="item in typeCreate" link>
              <span @click="createFolder(item.value)"
                >Tạo folder {{ item.text }}</span
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template>
        <v-menu :close-on-content-click="false" :nudge-width="0" offset-x>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip
              v-if="
                (checkAuthorization('training-sale_create') &&
                  typePermission == 'sale') ||
                (checkAuthorization('training-manager_create') &&
                  typePermission == 'manager')
              "
              bottom
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="btn-show-modal-count-interaction"
                  small
                  color="pink"
                  dark
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  icon
                >
                  <v-icon size="35px" style="cursor: pointer; color: #0066ff">
                    mdi-file-document-edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Tạo file</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item v-for="item in typeCreate" link>
              <span @click="createFile(item.value)"
                >Tạo file {{ item.text }}</span
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <input
        type="file"
        id="input-training-pdf"
        style="display: none"
        @change="uploadFile()"
      />
    </div>
  </div>
</template>

<script>
import TreeNote from "./TreeNote";
import RepositoryFactory from "@/repositories/RepositoryFactory";
const TrainingRepository = RepositoryFactory.get("training");

export default {
  components: { TreeNote },
  data() {
    return {
      tree: [],
      searchTree: [],
      fileName: "",
      tab: 0,
      tabs: [],
      parentId: 0,
      typePermission: "sale",
      typeCreate: [
        {
          text: "chính",
          value: 1,
        },
        {
          text: "phụ",
          value: 2,
        },
      ],
    };
  },
  created() {
    this.checkPermissionShowTab();
    // this.getTreeTraining()
    Bus.$on("change-parentId-for-create", (parentId) => {
      this.parentId = parentId;
    });

    Bus.$on("training-document-reload", () => {
      this.getTreeTraining();
    });

    Bus.$on("increased-order-in-training-tree", (item) => {
      this.increasedOrder(item);
    });
  },
  methods: {
    showContent(item, e) {
      let element = e.target;

      if (!element.className.includes("content-tree-note")) {
        element = e.target.closest(".content-tree-note");
      }

      if (!element.className.includes("active")) {
        let removeActive = document.getElementsByClassName(
          "content-tree-note active"
        );
        if (removeActive.length) {
          removeActive[0].classList.remove("active");
        }
        element.classList.add("active");
      }

      if (item.type != "folder") {
        this.parentId = item.folderId;
        Bus.$emit("get-file-training-content", item.id);
      } else {
        this.parentId = item.id;
        Bus.$emit("get-folder-training-content", item);
      }
    },
    createFolder(type) {
      if (type == 1) this.parentId = 0;
      Bus.$emit("show-folder-create-form", this.parentId, this.typePermission);
    },
    createFile(type) {
      if (type == 1) this.parentId = 0;
      Bus.$emit("show-file-create-form", this.parentId, this.typePermission);
    },
    clickUpload() {
      let element = document.getElementById("input-training-pdf");
      if (element) {
        element.click();
      }
    },
    async uploadFile() {
      let element = document.getElementById("input-training-pdf");
      if (element) {
        let files = element.files;

        if (files.length) {
          let uploadFile = files[0];

          if (uploadFile.type != "application/pdf") {
            this.$swal({
              title: "Lỗi!",
              text: "Vui lòng chọn file pdf.",
            });
            return;
          }

          let reader = new FileReader();
          let base64 = "";

          let me = this;
          reader.onload = async function (fileLoadedEvent) {
            base64 = fileLoadedEvent.target.result;
            let params = {
              title: uploadFile.name.replace(".pdf", ""),
              type: "pdf",
              parentId: me.parentId == 0 ? null : me.parentId,
              content: base64,
              typePermission: me.typePermission,
            };

            let res = await TrainingRepository.createFile(params);
            if (res.success) {
              me.getTreeTraining();
            } else {
              me.$swal({
                title: "Lỗi!",
                text: _.get(res, "message", ""),
              });
            }
          };

          await reader.readAsDataURL(uploadFile);
        }
      }
    },
    async getTreeTraining() {
      const params = {
        name: this.fileName,
        typePermission: this.typePermission,
      };
      const loading = this.$loading.show();
      let res = await TrainingRepository.list(params);
      if (res.success) {
        this.tree = _.get(res, "data", []);
      } else {
        this.tree = [];
      }
      loading.hide();
    },
    editTraining(item) {
      if (item.type == "folder") {
        Bus.$emit("show-folder-edit-form", item);
      } else {
        Bus.$emit("get-file-for-update", item.id);
      }
    },
    deleteTraining(item) {
      Bus.$emit("delete-training-from-tree", item);
    },
    async increasedOrder(item) {
      const loading = this.$loading.show();
      const params = {
        order: item.order,
      };

      if (item.type == "folder") {
        params.parentId = item.parentId;
        let res = await TrainingRepository.orderFolder(item.id, params);
        if (res.success) {
          loading.hide();
          this.getTreeTraining();
        } else {
          this.$swal({
            title: "Lỗi!",
            text: _.get(res, "message", ""),
          });
        }
      } else {
        params.parentId = item.folderId;
        let res = await TrainingRepository.orderFile(item.id, params);
        if (res.success) {
          loading.hide();
          this.getTreeTraining();
        } else {
          this.$swal({
            title: "Lỗi!",
            text: _.get(res, "message", ""),
          });
        }
      }
    },
    changeTab() {
      if (this.tabs.length > 1) {
        this.parentId = 0;
        if (this.tab === 0) {
          this.typePermission = "sale";
        } else {
          this.typePermission = "manager";
        }
        this.getTreeTraining();
      }
    },
    checkPermissionShowTab() {
      if (
        this.checkAuthorization("training-sale_read") &&
        this.checkAuthorization("training-manager_read")
      ) {
        this.tabs = ["Người bán hàng", "Quản lý"];
        this.getTreeTraining();
      } else if (this.checkAuthorization("training-sale_read")) {
        this.typePermission = "sale";
        this.tabs = ["Người bán hàng"];
        this.getTreeTraining();
      } else if (this.checkAuthorization("training-manager_read")) {
        this.typePermission = "manager";
        this.tabs = ["Quản lý"];
        this.getTreeTraining();
      }
    },
  },
};
</script>

<style scoped>
.training-document-tree {
  height: calc(100vh - 120px);
}

.tree-header-icon {
  min-width: 52px;
  padding: 0px 5px;
  text-align: right;
}

.icon-rotate {
  transform: rotate(90deg);
}

.content-tree-note {
  font-size: 16px;
  margin: 4px 0px;
}

.content-tree-note:hover,
.content-tree-note.active {
  background-color: #e6f2ff;
  cursor: pointer;
}

.content-tree-note .tree-action-btn {
  display: none;
}

.content-tree-note:hover .tree-action-btn,
.content-tree-note.active .tree-action-btn {
  display: inherit;
}

.tree-header-name {
  max-width: calc(100% - 130px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.search-file-name {
  border-radius: 20px !important;
  margin-top: 20px;
}

/deep/
  .v-text-field--outlined.search-file-name
  .v-input__control
  .v-input__slot {
  min-height: 30px;
}

/deep/ .v-text-field--outlined.search-file-name .v-input__prepend-inner {
  margin-top: 4px;
}

.training-action-btn {
  padding: 0px 20%;
  margin-top: 20px;
}

.traning-btn-upload {
  margin: 0px !important;
  /*margin-top: 4px !important;*/
  height: 32px !important;
  min-width: 40px !important;
  box-shadow: unset;
  background-color: #4caf50 !important;
}

.document-tree-container {
  max-height: calc(100vh - 330px);
  overflow: auto;
}
</style>