<template>
    <v-dialog v-model="showModal" max-width="85%">
        <v-card>
            <v-card-title class="text-sm-left font-weight-bold pt-3">
                <div class="d-flex">
                    <v-icon size="30px" v-if="trainingData.type == 'folder'" style="color: #ffad33; position: inherit" class="mr-2">
                        mdi-folder-open
                    </v-icon>
                    <v-icon size="30px" v-if="trainingData.type == 'text'" style="color: #0066ff; position: inherit" class="mr-2">
                        mdi-file-document
                    </v-icon>
                    <v-icon size="30px" v-if="trainingData.type == 'pdf'" style="color: #ff3300; position: inherit" class="mr-2">
                        mdi-file-pdf
                    </v-icon>
                    <v-icon size="30px" v-if="trainingData.type == 'video'" style="color: #ff3300; position: inherit" class="mr-2">
                        mdi-play-circle
                    </v-icon>
                    <v-icon size="30px" v-if="trainingData.type == 'qna'" style="color: #0066ff; position: inherit" class="mr-2">
                        mdi-help-circle
                    </v-icon>
                    {{ trainingData.title }}
                </div>
                <v-spacer />
                <v-icon aria-label="Close" @click="closeModal()">mdi-close</v-icon>
            </v-card-title>
            <v-card-text>
                <div class="text-content-container" v-if="trainingData.type == 'text'" v-html="trainingData.content">
                </div>
                <div v-if="trainingData.type == 'qna'">
                    <v-expansion-panels multiple>
                        <v-expansion-panel
                            @change="autoGrowHack = !autoGrowHack"
                            v-for="(item, index) in trainingData.content"
                            :key="index"
                        >
                            <v-expansion-panel-header>
                                <div class="d-flex">
                                    <v-icon class="mr-2" style="color: #0066ff" size="25px">mdi-help-circle</v-icon>
                                    <div style="font-weight: 500" class="mt-1">{{ item.question }}</div>
                                </div>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div class="d-flex">
                                    <v-icon class="mr-2" color="success">mdi-check-underline-circle</v-icon>
<!--                                    <div style="font-size: 16px">{{ item.answer }}</div>-->
                                    <v-textarea
                                        v-model="item.answer"
                                        rows="1"
                                        :key="autoGrowHack"
                                        auto-grow
                                        flat
                                        solo
                                        readonly
                                        hide-details
                                    >
                                    </v-textarea>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-if="trainingData.type == 'pdf'" style="height: 100%">
                  <embed
                      :src="trainingData.content"
                      width="100%"
                      height="100%"
                      type="application/pdf"/>
                </div>
                <div v-if="trainingData.type == 'folder'">
                    <div class="d-flex">
                        <v-icon class="mr-2" style="color: #ffad33" size="25px">mdi-alert-decagram</v-icon>
                        {{ trainingData.content }}
                    </div>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>    
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            trainingData: {},
            autoGrowHack: false
        }
    },
    created() {
        Bus.$on('show-full-screen-training=content', trainingData => {
            this.trainingData = trainingData
            this.showModal = true
        })
    },
    methods: {
        closeModal() {
            this.showModal = false
        }
    },
    updated() {
      document.querySelectorAll( 'oembed[url]' ).forEach( element => {
        if(element.childNodes.length ==0) {
          iframely.load( element, element.attributes.url.value );
        }
      });
    }
}
</script>

<style scoped>
/deep/ .v-expansion-panel-header {
    padding: 10px;
    background-color: #e6f2ff;
    border: 1px solid #e2e2e2;
    font-size: 16px;
    min-height: 40px;
}

/deep/ .v-expansion-panel-content__wrap {
    padding: 10px;
    border: 1px solid #e2e2e2;
}

/deep/ .text-content-container table {
  width: 100%;
  border-collapse: collapse;
}

/deep/ .text-content-container table td{
  border: 2px solid black;
  padding: 4px;
}

/deep/ .modal-show-full-screen {
  height: 100%;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  height: 100%;
}

/deep/ .v-dialog:not(.v-dialog--fullscreen) {
  height: 100%;
}

/deep/ .v-card__text {
  height: 90%;
  overflow-y: auto;
}
</style>