<template>
  <v-dialog
    v-model="showModalChangeStatus"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template> Cấp độ xử lý </template>
          <v-spacer />
          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6 pt-6">
          <validation-provider v-slot="{ errors }">
            <v-select
              v-model="withdraw.status"
              no-data-text="Không có dữ liệu"
              :items="withdrawStatusOptions"
              item-text="text"
              item-value="value"
              :error-messages="errors"
              color="primary"
            >
              <template v-slot:item="{ attrs, item, on }">
                <v-list-item
                  v-bind="attrs"
                  active-class="secondary elevation-4 white--text"
                  class="mx-3 mb-3 v-sheet"
                  elevation="0"
                  v-on="on"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text" />
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveWithdraw)">
            Cập nhật
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { withdrawStatusOptions } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const WithdrawRepository = RepositoryFactory.get("withdraw");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModalChangeStatus: false,
      withdraw: {},
      errorMessage: "",
      withdrawStatusOptions: withdrawStatusOptions,
    };
  },
  async created() {
    Bus.$on("withdraw-show-form-handle-status", (data) => {
      this.showModalChangeStatus = true;
      this.withdraw = _.cloneDeep(data);
    });
  },
  methods: {
    hideModal: function () {
      this.showModalChangeStatus = false;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveWithdraw() {
      const loader = this.$loading.show();
      const response = await WithdrawRepository.updateStatus(this.withdraw.id, {
        status: _.trim(this.withdraw.status),
      });
      loader.hide();
      if (!response.success) {
        this.errorMessage = _.get(response, "message", "");
      } else {
        this.errorMessage = "";
        this.showModalChangeStatus = false;
        this.$swal({
          title: "Cập nhật thành công",
          confirmButtonText: "Đồng ý",
        }).then(() => {
          Bus.$emit("withdraw-reload");
        });
      }
    },
  },
};
</script>
