<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mạng xã hội </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Bước 1"
          >
            <v-text-field
              v-model="bank.stepOne"
              label="Bước 1"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <v-file-input
            v-model="inputFile"
            placeholder="QR"
            label="QR"
            prepend-icon="mdi-paperclip"
            :value="_.get(bank, 'qrUrl', '')"
            @change="uploadOneFile"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Tên tài khoản"
          >
            <v-text-field
              v-model="bank.bankAccountFullName"
              label="Tên tài khoản"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Số tài khoản"
          >
            <v-text-field
              v-model="bank.bankAccountNumber"
              label="Số tài khoản"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Địa chỉ"
          >
            <v-text-field
              v-model="bank.address"
              label="Địa chỉ"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Bước 2"
          >
            <v-text-field
              v-model="bank.stepTwo"
              label="Bước 2"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Mô tả bước 2"
          >
            <v-text-field
              v-model="bank.stepTwoDescription"
              label="Mô tả bước 2"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider class="mb-2" name="Video hướng dẫn">
            <v-text-field
              v-model="bank.youtubeUrl"
              label="Video hướng dẫn"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Bước 3"
          >
            <v-text-field
              v-model="bank.stepThree"
              label="Bước 3"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Mô tả bước 3"
          >
            <v-text-field
              v-model="bank.stepThreeDescription"
              label="Mô tả bước 3"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:25"
            name="Số điên thoại liên hệ"
          >
            <v-text-field
              v-model="bank.contactPhoneNumber"
              label="Số điên thoại liên hệ"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveBank)">
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const ImageRepository = RepositoryFactory.get("image");
const BankRepository = RepositoryFactory.get("bank");

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      bank: {},
      isCreate: true,
      errorMessage: "",
      inputFile: [],
    };
  },
  created() {
    Bus.$on("bank-show-form", (bank) => {
      this.showModal = true;
      this.bank = _.cloneDeep(bank);
      if (bank.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveBank() {
      const bank = {
        stepOne: _.trim(this.bank.stepOne),
        bankAccountFullName: _.trim(this.bank.bankAccountFullName),
        bankAccountNumber: _.trim(this.bank.bankAccountNumber),
        qrUrl: _.trim(this.bank.qrUrl),
        address: _.trim(this.bank.address),
        stepTwo: _.trim(this.bank.stepTwo),
        stepTwoDescription: _.trim(this.bank.stepTwoDescription),
        youtubeUrl: _.trim(this.bank.youtubeUrl),
        stepThree: _.trim(this.bank.stepThree),
        stepThreeDescription: _.trim(this.bank.stepThreeDescription),
        contactPhoneNumber: _.trim(this.bank.contactPhoneNumber),
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await BankRepository.create(bank);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("bank-reload");
          });
        }
      } else {
        response = await BankRepository.update(this.bank.id, bank);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("bank-reload");
          });
        }
      }
    },
    async uploadOneFile(file) {
      const loading = this.$loading.show();
      const uploadResponse = await ImageRepository.upload(file);
      loading.hide();
      if (_.get(uploadResponse, "success", false)) {
        const imageUrl = _.get(uploadResponse, "data.data.path", "");
        if (imageUrl) this.$set(this.bank, "qrUrl", imageUrl);
      } else {
        this.$swal("Upload ảnh không thành công");
      }
    },
  },
};
</script>
