<template>
  <v-dialog v-model="showModal" max-width="1000" persistent>
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          Đặt lại mật khẩu
          <v-spacer />
        </v-card-title>
        <v-card-text class="px-6">
          <v-row>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  min: 6,
                  max: 20,
                  regex: regex.isPassword,
                }"
                name="Mật khẩu"
                vid="password"
                autocomplete="new-password"
              >
                <v-text-field
                  v-model="newPassword"
                  label="Mật khẩu *"
                  :error-messages="errors"
                  color="primary"
                  v-on:keyup.enter="resetPassword()"
                  :append-icon="visiblePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (visiblePassword = !visiblePassword)"
                  :type="visiblePassword ? 'password' : 'text'"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                v-slot="{ errors }"
                name="Nhập lại mật khẩu"
                rules="required|confirmed:password"
              >
                <v-text-field
                  v-model="confirmPassword"
                  label="Xác nhận lại mật khẩu *"
                  :error-messages="
                    errors.length ? 'Nhập lại mật khẩu không chính xác' : ''
                  "
                  color="primary"
                  v-on:keyup.enter="resetPassword()"
                  :append-icon="
                    visibleConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                  "
                  @click:append="
                    () => (visibleConfirmPassword = !visibleConfirmPassword)
                  "
                  :type="visibleConfirmPassword ? 'password' : 'text'"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="group-btn--form px-0">
          <v-btn color="success" @click.prevent="handleSubmit(resetPassword)">
            Lưu lại
          </v-btn>

          <v-btn color="primary" class="ml-0" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script>

import { regex } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const ManagerRepository = RepositoryFactory.get("manager");
export default {
  name: "ResetPasswordForm",
  data() {
    return {
      regex,
      showModal: false,
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
      user: {},
      visibleConfirmPassword: true,
      visiblePassword: true,
    };
  },
  created() {
    Bus.$on("reset-password-user", (user) => {
      this.showModal = true;
      this.user = user;
    });
  },
  methods: {
    async hideModal() {
      this.showModal = false;
      this.errorMessage = "";
      await this.$refs.validator.reset();
    },
    async resetPassword() {
      const loader = this.$loading.show();
      const response = await ManagerRepository.resetPassword(this.user.id, {
        password: this.newPassword,
      });
      loader.hide();
      if (_.get(response, "success", false)) {
        this.$swal({
          title: "Cập nhật mật khẩu thành công !",
          confirmButtonText: "Đồng Ý",
        }).then(() => {
          this.newPassword = "";
          this.confirmPassword = "";
          this.hideModal();
        });
      } else {
        this.errorMessage = _.get(response, "message", "");
      }
    },
  },
};
</script>
<style></style>
