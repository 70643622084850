<template>
  <v-container id="edit-question" fluid tag="section">
    <v-card class="mx-auto pa-5" outlined>
      <validation-observer v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(update)">
          <h3 style="padding-bottom: 35px">Thông tin câu hỏi</h3>
          <v-alert
            v-show="alertMessageText"
            v-model="alert"
            :type="alertMessageType"
            dismissible
          >
            {{ alertMessageText }}
          </v-alert>

          <v-row>
            <v-col cols="12" md="12" v-if="isNew === true">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn tác giả"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.userId"
                  :items="customers"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Tác giả *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12" v-if="isNew === false">
              <validation-provider v-slot="{ errors }" name="Tác giả">
                <v-autocomplete
                  v-model="details.userId"
                  :items="customers"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Tác giả"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                  readonly
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn trạng thái"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.status"
                  :items="questionStatusOptions"
                  :error-messages="errors"
                  item-text="text"
                  item-value="code"
                  label="Trạng thái *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn loại câu hỏi"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.type"
                  :items="types"
                  :error-messages="errors"
                  item-text="name"
                  item-value="code"
                  label="Loại câu hỏi *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn nhóm"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.groupId"
                  :items="groups"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Nhóm *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn lĩnh vực"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.fields"
                  :items="fields"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Lĩnh vực *"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.fields"
                      :item="details.fields"
                      :items="fields.map((s) => s.id)"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Tiêu đề câu hỏi"
                :rules="{
                  required: true,
                }"
              >
                <v-text-field
                  v-model="details.title"
                  label="Tiêu đề câu hỏi *"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" name="Mã câu hỏi">
                <v-text-field
                  v-model="details.code"
                  label="Mã câu hỏi"
                  :error-messages="errors"
                  color="primary"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Nội dung câu hỏi *</span
              >
              <validation-provider name="Nội dung câu hỏi">
                <template>
                  <ckeditor
                    id="ckeditor-question"
                    v-model="details.question"
                    :config="editorConfig"
                  ></ckeditor>
                  <div style="margin-top: 30px">
                    <h4 style="margin-bottom: 10px">
                      Xem trước Nội dung câu hỏi:
                    </h4>
                    <vue-mathjax
                      id="question"
                      :formula="preProcessingQuestionContent(details.question)"
                      :safe="false"
                    ></vue-mathjax>
                  </div>
                </template>
              </validation-provider>
            </v-col>

            <template v-if="details.type == 'TRAC_NGHIEM'">
              <v-col cols="12" md="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Đáp án đúng"
                  :rules="{ required: true, numeric: true }"
                >
                  <v-text-field
                    v-model="details.key"
                    label="Key *"
                    :error-messages="errors"
                    color="primary"
                  />
                </validation-provider>
              </v-col>
              <v-col>
                <div class="group-input">
                  <ul>
                    <li class="title">
                      <h3 class="text-left">Nhập đáp án</h3>
                    </li>
                    <template v-for="(ans, index) in details.answers">
                      <li :key="index" class="list-input">
                        <validation-provider
                          v-slot="{ errors }"
                          class="mb-2"
                          :rules="{ required: true }"
                          name="Đáp án"
                          vid="answer"
                        >
                          <v-text-field
                            v-model.trim="ans.content"
                            label="Đáp án"
                            color="primary"
                            :error-messages="errors"
                            clearable
                          />
                        </validation-provider>
                        <v-icon
                          size="large"
                          v-if="index > 0"
                          class="group-input-close"
                          @click="deleteRow(index)"
                        >
                          mdi-delete
                        </v-icon>
                      </li>
                      <div class="mb-8">
                        <h4 style="margin-bottom: 10px">Xem trước:</h4>
                        <vue-mathjax
                          :formula="preProcessingText(ans.content)"
                          :safe="false"
                        ></vue-mathjax>
                      </div>
                    </template>
                  </ul>
                  <v-btn color="primary" @click="addRow">
                    Thêm đáp án
                    <v-icon class=""> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </v-col>
            </template>

            <v-col cols="12" sm="12" style="margin-top: 30px">
              <span
                class="title-span-content text-left"
                style="display: inline-block; width: 100%; margin-bottom: 10px"
                >Giải thích</span
              >
              <validation-provider v-slot="{}" name="Giải thích">
                <!-- <vue-editor
                  id="vue-editor-explain"
                  v-model="details.explanation"
                  :editor-toolbar="customToolbar"
                  :editorOptions="editorSettings"
                /> -->
                <v-textarea
                  v-model="details.explanation"
                  label="Giải thích"
                  color="primary"
                />
                <div>
                  <h4 style="margin-bottom: 10px; margin-top: 30px">
                    Xem trước Giải thích:
                  </h4>
                  <vue-mathjax
                    id="explanation"
                    :formula="preProcessingText(details.explanation)"
                    :safe="false"
                  ></vue-mathjax>
                </div>
              </validation-provider>
            </v-col>

            <!--  -->
            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                class="mb-2"
                name="Điểm"
                :rules="{ min: 0, numeric: true, required: true }"
              >
                <v-text-field
                  v-model="details.point"
                  :error-messages="errors"
                  label="Điểm *"
                  color="primary"
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Chọn tổ chức"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.organizationId"
                  :items="organizations"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Tổ chức *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Vị trí"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.positionId"
                  :items="positions"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Vị trí *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Môn học"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.subjects"
                  :items="subjects"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Môn học *"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.subjects"
                      :item="details.subjects"
                      :items="subjects.map((s) => s.id)"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Quốc gia"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.countryId"
                  :items="countries"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Quốc gia *"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" name="Gắn nhãn">
                <v-autocomplete
                  v-model="details.tags"
                  :items="serviceTags"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Gắn nhãn"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.tags"
                      :item="details.tags"
                      :items="serviceTags.map((s) => s.id)"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider
                v-slot="{ errors }"
                name="Danh mục"
                :rules="{
                  required: true,
                }"
              >
                <v-autocomplete
                  v-model="details.categoryId"
                  :items="categories"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Danh mục"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" name="Chọn ngôn ngữ">
                <v-autocomplete
                  v-model="details.languageId"
                  :items="languages"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Ngôn ngữ"
                  color="primary"
                  no-data-text="Không có dữ liệu"
                >
                </v-autocomplete>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="12">
              <validation-provider v-slot="{ errors }" name="Độ tuổi">
                <v-autocomplete
                  v-model="details.ageRanges"
                  :items="ageRanges"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  label="Độ tuổi"
                  color="primary"
                  multiple
                  no-data-text="Không có dữ liệu"
                >
                  <template #prepend-item>
                    <common-select-all
                      v-model="details.ageRanges"
                      :item="details.ageRanges"
                      :items="ageRanges.map((s) => s.id)"
                    />
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>

          <div class="service-form-pictures"></div>

          <div class="mt-2 text-center">
            <v-btn
              class="mb-2"
              color="primary"
              @click.prevent="handleSubmit(save)"
            >
              {{ isNew ? "Tạo" : "Cập nhật" }}
            </v-btn>
            <v-btn class="mb-2 mr-2" color="default" @click="goToList">
              Hủy bỏ
            </v-btn>
          </div>
        </v-form>
      </validation-observer>
    </v-card>
  </v-container>
</template>
<script>
import {
  regex,
  questionStatusOptions,
  PAGE_LIMIT,
  FIRST_PAGE,
  STATUS_ACTIVE,
} from "../../../../helpers/constants";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import { VueEditor, Quill } from "vue2-editor";
import htmlEditButton from "quill-html-edit-button";
Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

const QuestionRepository = RepositoryFactory.get("question");
const FieldRepository = RepositoryFactory.get("field");
const GroupRepository = RepositoryFactory.get("group");
const OrganizationRepository = RepositoryFactory.get("organization");
const CategoryRepository = RepositoryFactory.get("category");
const LanguageRepository = RepositoryFactory.get("language");
const AgeRepository = RepositoryFactory.get("age");
const PositionRepository = RepositoryFactory.get("position");
const TagRepository = RepositoryFactory.get("tag");
const CountryRepository = RepositoryFactory.get("country");
const SubjectRepository = RepositoryFactory.get("subject");
const CustomerRepository = RepositoryFactory.get("customer");

import { VueMathjax } from "vue-mathjax";
const regexCurlyBracket =
  /\(\s([a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s!@#\$%\&*(+=,;._-]*)\s\)/g;
const MINIMUM = 6;
const regexSquareBracket =
  /\[\s[a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễếệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s!@#\$%\&*(+=,;._-]*\]\s/g;

export default {
  components: { VueEditor, VueMathjax },
  $_veeValidate: {
    validator: "new",
  },
  name: "EditQuestionForm",
  props: {},
  data() {
    return {
      regex,
      isNew: false,
      details: {
        title: "",
        code: "",
        question: "",
        explanation: "",
        key: null,
        answers: [],
        fields: [],
        tags: [],
        ageRanges: [],
        groupId: "",
        organizationId: "",
        countryId: "",
        languageId: "",
        categoryId: "",
        positionId: "",
        type: "",
        point: null,
        status: "",
        subjects: [],
        userId: "",
      },
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
      customers: [],
      fields: [],
      groups: [],
      ageRanges: [],
      categories: [],
      languages: [],
      organizations: [],
      positions: [],
      countries: [],
      subjects: [],
      types: [
        {
          code: "TRAC_NGHIEM",
          name: "Trắc nghiệm",
        },
        {
          code: "TU_LUAN",
          name: "Tự luận",
        },
      ],
      alertMessageText: null,
      alertMessageType: "success",
      alert: true,
      serviceTags: [],
      questionStatusOptions,
      editorConfig: {
        // The configuration of the editor.
        fontSize: {
          options: [12, 14, 16, 18, 20, 22, 24],
          default: 16,
        },
        // extraPlugins: "justify,font,colorbutton,embed",
        extraPlugins: "justify,font,colorbutton",
        height: 400,
        simpleUpload: {
          uploadUrl: "/your-upload-endpoint", // Replace this with your actual upload endpoint
        },
        toolbarGroups: [
          { name: "document", groups: ["mode", "document", "doctools"] },
          { name: "clipboard", groups: ["clipboard", "undo"] },
          { name: "editing", groups: ["find", "selection", "spellchecker"] },
          { name: "forms" },
          "/",
          { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
          {
            name: "paragraph",
            groups: ["list", "indent", "blocks", "align", "bidi"], // 'align' -> 'justify' plugin
          },
          { name: "links" },
          { name: "insert" },
          "/",
          { name: "styles" },
          { name: "colors" },
          { name: "tools" },
          { name: "others" },
        ],
      },
      editorSettings: {
        modules: {
          htmlEditButton: {
            buttonTitle: "View source",
            msg: 'Chỉnh sửa HTML ở đây, khi bạn click "Đồng ý", nội dung sẽ được cập nhật!',
            okText: "Đồng ý",
            cancelText: "Hủy bỏ",
          },
        },
      },
    };
  },
  async created() {
    await Promise.all([
      this.getListField(),
      this.getListTags(),
      this.getListSubjects(),
      this.getListGroups(),
      this.getListPositions(),
      this.getListOrganizations(),
      this.getListCountries(),
      this.getListLanguages(),
      this.getListAgeRanges(),
      this.getListCategories(),
      this.getListCustomers(),
    ]);

    // if (this.$route?.params?.id) {
    //   this.isNew = false;
    //   await this.getQuestionDetail(this.$route?.params?.id)
    // }
  },
  computed: {},
  async mounted() {
    let id = 0;
    if ("id" in this.$route.params) {
      id = this.$route?.params?.id || "";
    }
    this.details.id = id;
    this.isNew = !id;

    if (this.details?.id) {
      await this.getQuestionDetail(this.details.id);
    }
    const element = document.getElementById("cke_editor2");
    element?.remove();
  },
  methods: {
    async save() {
      const details = {
        title: this.details.title,
        code: this.details.code,
        question: this.details.question,
        explanation: this.details.explanation,
        key: this.details.type == "TRAC_NGHIEM" ? +this.details.key : undefined,
        answers:
          this.details.type == "TRAC_NGHIEM" ? this.details.answers : undefined,
        fields: this.details.fields,
        tags: this.details.tags,
        ageRanges: this.details.ageRanges,
        userId: this.details.userId,
        groupId: this.details.groupId,
        organizationId: this.details.organizationId,
        countryId: this.details.countryId,
        languageId: this.details.languageId,
        categoryId: this.details.categoryId,
        positionId: this.details.positionId,
        type: this.details.type,
        point: +this.details.point,
        status: this.details.status,
        subjects: this.details.subjects,
      };
      this.loading = this.$loading.show();
      if (this.isNew) {
        const response = await QuestionRepository.create(details);
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Thêm câu hỏi thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.goToList();
          });
        }
      } else {
        const response = await QuestionRepository.update(
          _.get(this.details, "id", 0),
          details
        );
        this.loading.hide();
        if (response.error) {
          this.setAlertMessage(true, "error", _.get(response, "message"));
        } else {
          this.$swal({
            title: "Cập nhật câu hỏi thành công!",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            this.goToList();
          });
        }
      }
    },
    setAlertMessage(alert, type, text) {
      this.alert = alert;
      this.alertMessageText = text;
      this.alertMessageType = type;
    },
    async getQuestionDetail(id) {
      this.loading = this.$loading.show();
      const response = await QuestionRepository.getDetail(id);
      this.loading.hide();
      if (response.success) {
        this.details = {
          ...response?.data,
          tags: response?.data?.tags.map((e) => e.id),
          fields: response?.data?.fields.map((e) => e.id),
          subjects: response?.data?.subjects.map((e) => e.id),
          ageRanges: response?.data?.ageRanges.map((e) => e.id),
        };
      }
    },
    goToList() {
      this.$router.push({
        name: "QuestionList",
      });
    },
    async getListCustomers() {
      let response = await CustomerRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.customers = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListSubjects() {
      const response = await SubjectRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.subjects = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListField() {
      const response = await FieldRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.fields = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListGroups() {
      const response = await GroupRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.groups = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListPositions() {
      const response = await PositionRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.positions = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListOrganizations() {
      const response = await OrganizationRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.organizations = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListCategories() {
      const response = await CategoryRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.categories = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListCountries() {
      const response = await CountryRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.countries = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListLanguages() {
      const response = await LanguageRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.languages = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListAgeRanges() {
      const response = await AgeRepository.list({
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (response.success) {
        this.ageRanges = _.get(response, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
    async getListTags() {
      const listTag = await TagRepository.list({
        status: STATUS_ACTIVE,
        page: FIRST_PAGE,
        perPage: PAGE_LIMIT,
      });
      if (listTag.success) {
        this.serviceTags = _.get(listTag, "data.items", []);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(listTag, "message", ""),
        });
      }
    },
    deleteRow(index) {
      this.details?.answers.splice(index, 1);
    },
    addRow() {
      this.details?.answers.push({
        content: "",
      });
    },

    isValidStringCurlyBrackets(characters) {
      if (!characters || characters.length < MINIMUM) return false;
      // (a, b ; c)
      if (
        characters === "(a, b ; c)" ||
        characters === "(a; b , c)" ||
        characters === "(a, b , c)" ||
        characters === "(a,b,c)" ||
        characters === "(a;b;c)" ||
        characters === "(a; y; z)" ||
        characters === "(x, y ; z)" ||
        characters === "(x; y , z)" ||
        characters === "(x, y , z)" ||
        characters === "(x,y,z)" ||
        characters === "(x;y;z)" ||
        characters === "(x; y; z)"
      )
        return false;
      // (Oxy), (Oxyz), (Oyz)
      if (
        (characters[1] === "O" && characters[2] === "x") ||
        characters[2] === "y" ||
        characters[2] === "z"
      )
        return false;
      // (SBC)
      if (
        characters[1] === "S" &&
        characters[2] === "B" &&
        characters[3] === "C"
      )
        return false;
      // (ABCD)
      if (
        characters[1] === "A" &&
        characters[2] === "B" &&
        characters[3] === "C" &&
        characters[4] === "D"
      )
        return false;
      let numberOfNumberCharacter = 0;
      let numberOfAlphabetCharacter = 0;
      for (const character of characters) {
        if (character === "(" || character === ")" || character === " ")
          continue;
        if ("0123456789+-".includes(character)) {
          numberOfNumberCharacter++;
          continue;
        }
        numberOfAlphabetCharacter++;
      }
      if (numberOfNumberCharacter >= numberOfAlphabetCharacter) {
        return false;
      }
      return true;
    },

    preProcessingCurlyBrackets(text) {
      const matches = text ? text.match(regexCurlyBracket) : null;
      if (!matches) return text;
      const textMatches = matches
        ?.map((o) => {
          return this.isValidStringCurlyBrackets(o) ? o : null;
        })
        .filter((t) => t);

      const tTextMatches = [];
      for (const item of textMatches) {
        if (item[1] === "$") {
          tTextMatches.push(item.replace("(", " $($ ").replace(")", " $)$ "));
        } else {
          tTextMatches.push(item.replace("(", "$($").replace(")", "$)$"));
        }
      }
      for (let i = 0; i < textMatches.length; i++) {
        text = text.replace(textMatches[i], tTextMatches[i]);
      }
      return text;
    },

    isValidStringSquareBrackets(characters) {
      if (!characters || characters.length < MINIMUM) return false;
      // [a, b ; c]
      if (
        characters === "[a, b ; c]" ||
        characters === "[a; b , c]" ||
        characters === "[a, b , c]" ||
        characters === "[a,b,c]" ||
        characters === "[a;b;c]" ||
        characters === "[a; y; z]" ||
        characters === "[x, y ; z]" ||
        characters === "[x; y , z]" ||
        characters === "[x, y , z]" ||
        characters === "[x,y,z]" ||
        characters === "[x;y;z]" ||
        characters === "[x; y; z]"
      )
        return false;
      // [Oxy], [Oxyz], [Oyz]
      if (
        (characters[1] === "O" && characters[2] === "x") ||
        characters[2] === "y" ||
        characters[2] === "z"
      )
        return false;
      // [SBC]
      if (
        characters[1] === "S" &&
        characters[2] === "B" &&
        characters[3] === "C"
      )
        return false;
      // [ABCD]
      if (
        characters[1] === "A" &&
        characters[2] === "B" &&
        characters[3] === "C" &&
        characters[4] === "D"
      )
        return false;
      let numberOfNumberCharacter = 0;
      let numberOfAlphabetCharacter = 0;
      for (const character of characters) {
        if (character === "[" || character === "]" || character === " ")
          continue;
        if ("0123456789+-".includes(character)) {
          numberOfNumberCharacter++;
          continue;
        }
        numberOfAlphabetCharacter++;
      }
      if (numberOfNumberCharacter >= numberOfAlphabetCharacter) {
        return false;
      }
      return true;
    },

    preProcessingSquareBrackets(text) {
      const matches = text ? text.match(regexSquareBracket) : null;
      if (!matches) return text;
      const textMatches = matches
        ?.map((o) => {
          return this.isValidStringSquareBrackets(o) ? o : null;
        })
        .filter((t) => t);

      const tTextMatches = [];
      for (const item of textMatches) {
        if (item[1] === "$") {
          tTextMatches.push(item.replace("[", " $[$ ").replace("]", " $]$ "));
        } else {
          tTextMatches.push(item.replace("[", "$[$").replace("]", "$]$"));
        }
      }
      for (let i = 0; i < textMatches.length; i++) {
        text = text.replace(textMatches[i], tTextMatches[i]);
      }
      return text;
    },

    preProcessingText(text) {
      // TODO: Need to optimize for preProcessingSquareBrackets and preProcessingCurlyBrackets
      const content = this.preProcessingSquareBrackets(text);
      let result = this.preProcessingCurlyBrackets(content);
      if (result.includes(" (")) {
        result = result.split(" (").join("$ ($");
      }
      if (result.includes(" [ ")) {
        result = result.split(" [ ").join("$ [$");
      }
      return result;
    },

    preProcessingQuestionContent(question) {
      try {
        const text = this.preProcessingText(question);
        return text
          ?.replace(/&lt;/g, "<")
          ?.replace(/&gt;/g, ">")
          ?.replace(/\\&quot;/g, '"')
          ?.replace(/&quot;/g, '"');
      } catch (error) {
        console.log("preProcessingQuestionContent:", error);
        return question;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.service-form-pictures {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa !important;
  line-height: 1.4;
}
.display-image-block {
  position: relative;
  max-width: 230px;
  width: 100%;
  margin-right: 20px;
  border: 1px solid #999;
  padding: 5px;
  display: inline-block;
}
.delete-image-block {
  position: absolute;
  right: 5px;
  bottom: 32px;
  z-index: 9;
}
.v-card--material {
  overflow: hidden;
}
.clear-both {
  clear: both;
}
.display-none {
  display: none;
}
.thumbnail-validation {
  .v-text-field__slot {
    display: none !important;
  }
}
.v-input--radio-group__input .v-input--selection-controls__ripple {
  left: -12px;
}
.errors-span-required-content {
  display: inline-block;
  height: 20px;
  width: 100%;
  text-align: left;
  color: #ff5252;
  font-size: 12px;
}
.title-span-content {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  margin-bottom: 8px;
}
.group-input-close {
  position: absolute;
  right: 0px !important;
  top: 15px;
}
.list-input .theme--light.v-input {
  width: 97% !important;
}
</style>
