<template>
  <v-container id="user-profile" fluid tag="section">
    <filter-form :filter-form="filterForm" />
    <div class="text-sm-right mb-4">
      <v-btn
        v-if="checkAuthorization('manager_create')"
        class="primary mx-0"
        @click="addNewUser"
      >
        <v-icon>mdi-plus</v-icon>Thêm Mới
      </v-btn>
    </div>
    <v-card>
      <v-tabs v-model="tab" grow @change="getManagerList()">
        <v-tabs-slider></v-tabs-slider>

        <v-tab v-for="(item, key) in tabs" :key="key">
          {{ item }}
        </v-tab>

        <v-tab-item v-for="(item, key) in tabs" :key="key">
          <manager-table v-if="users.length" :users="users" />
          <common-no-result v-if="!loading && totalItem === 0" />
          <v-row class="mt-0">
            <v-col cols="12" sm="6" />
            <v-col cols="12" sm="6">
              <v-pagination
                v-if="totalItem > limit"
                v-model="currentPage"
                class="justify-sm-end"
                :length="getTotalItem(totalItem)"
                prev-icon="mdi-menu-left"
                next-icon="mdi-menu-right"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <reset-password-form />
    <manager-form />
  </v-container>
</template>

<script>
import ResetPasswordForm from "./components/ResetPasswordForm";
import ManagerForm from "./components/ManagerForm";
import FilterForm from "./components/FilterForm";
import ManagerTable from "./components/ManagerTable";
import RepositoryFactory from "../../repositories/RepositoryFactory";

const ManagerRepository = RepositoryFactory.get("manager");

export default {
  components: {
    ResetPasswordForm,
    ManagerTable,
    ManagerForm,
    FilterForm,
  },
  data() {
    return {
      filterForm: {},
      currentPage: 1,
      limit: 10,
      totalItem: 0,
      users: [],
      loading: false,
      tabs: ["Đang hoạt động", "Ngừng hoạt động"],
      tab: 0,
    };
  },
  watch: {
    currentPage: {
      handler() {
        this.getManagerList();
      },
    },
    limit: {
      handler() {
        if (this.currentPage === 1) {
          this.getManagerList();
        } else {
          this.currentPage = 1;
        }
      },
    },
  },
  created() {
    this.getManagerList();
    Bus.$on("manager-list-filter", (filterForm) => {
      this.filterForm = filterForm;
      this.currentPage = 1;
      this.getManagerList();
    });

    Bus.$on("manager-list-reload", () => {
      this.getManagerList();
    });
  },
  beforeDestroy() {
    Bus.$off("manager-list-filter");
  },
  methods: {
    addNewUser() {
      this.selectedUser = {};
      Bus.$emit("manager-show-manager-form", this.selectedUser);
    },
    async getManagerList() {
      this.loading = true;
      const params = {
        perPage: this.limit,
        page: this.currentPage,
        name: this.filterForm.name,
        position: this.filterForm.position,
        status: this.tab == 0 ? "ACTIVE" : "INACTIVE",
        phoneNumber: this.filterForm.phoneNumber,
      };
      const loader = this.$loading.show();
      const response = await ManagerRepository.list(params);
      loader.hide();
      this.loading = false;
      if (response.success) {
        this.users = _.get(response, "data.items", []);
        this.totalItem = _.get(response, "data.headers.x-total-count", 0);
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(response, "message", ""),
        });
      }
    },
  },
};
</script>
