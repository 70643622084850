<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate">Tạo yêu cầu</template>
          <template v-else>Cập nhật yêu cầu</template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Người dùng *"
          >
            <v-autocomplete
              v-model="transaction.userId"
              :items="customers"
              :error-messages="errors"
              item-text="userName"
              item-value="id"
              label="Người dùng *"
              color="primary"
              no-data-text="Không có dữ liệu"
            >
            </v-autocomplete>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:512"
            name="Tiêu đề"
          >
            <v-text-field
              v-model="transaction.title"
              label="Tiêu đề *"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" class="mb-2" name="Nội dung">
            <v-textarea
              v-model="transaction.content"
              label="Nội dung"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider v-slot="{ errors }" class="mb-2" name="Liên kết">
            <v-text-field
              v-model="transaction.url"
              label="Liên kết"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveTransaction)">
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  // transactionStatusOptions,
  PAGE_LIMIT,
} from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const TransactionRepository = RepositoryFactory.get("transaction");
const CustomerRepository = RepositoryFactory.get("customer");

export default {
  components: {},
  $_veeValidate: {
    validator: "new",
  },
  props: {},
  data() {
    return {
      showModal: false,
      transaction: {},
      isCreate: true,
      errorMessage: "",
      statusOptions: [],
      inputFile: [],
      icons: [],
      customers: [],
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6],
          },
        ],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          {
            align: "",
          },
          {
            align: "center",
          },
          {
            align: "right",
          },
          {
            align: "justify",
          },
        ],
        ["blockquote", "code-block"],
        [
          {
            list: "ordered",
          },
          {
            list: "bullet",
          },
          {
            list: "check",
          },
        ],
        [
          {
            indent: "-1",
          },
          {
            indent: "+1",
          },
        ], // outdent/indent
        [
          {
            color: [],
          },
          {
            background: [],
          },
        ], // dropdown with defaults from theme
        ["image", "code-block"],
      ],
    };
  },
  async mounted() {
    await this.getCustomerAll();
  },
  async created() {
    Bus.$on("transaction-show-form", (transaction) => {
      this.showModal = true;
      this.transaction = _.cloneDeep(transaction);
      if (transaction.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async getCustomerAll() {
      let res = await CustomerRepository.list({ perPage: PAGE_LIMIT });
      if (res.success) {
        const listCustomers = _.get(res, "data.items", []);
        this.customers = listCustomers.map((o) => {
          return {
            id: o.id,
            userName: `${o.userName} - ${o.phoneNumber} - ${o.email}`,
          };
        });
      } else {
        this.$swal({
          type: "error",
          title: "Lỗi !",
          text: _.get(res, "message", ""),
        });
      }
    },
    async saveTransaction() {
      const transaction = {
        title: _.trim(this.transaction.title),
        link: _.trim(this.transaction.link),
        content: _.trim(this.transaction.content),
        userId: this.transaction.userId,
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await TransactionRepository.create(transaction);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("transaction-reload");
          });
        }
      } else {
        response = await TransactionRepository.update(
          this.transaction.id,
          transaction
        );
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("transaction-reload");
          });
        }
      }
    },
  },
};
</script>
