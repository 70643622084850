<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
    :retain-focus="false"
  >
    <v-card class="text-center">
      <template v-if="errorMessage">
        <v-alert type="error">
          {{ errorMessage }}
        </v-alert>
      </template>
      <validation-observer ref="validator" v-slot="{ handleSubmit }">
        <v-card-title class="text-sm-left font-weight-bold">
          <template v-if="isCreate"> Thêm mạng xã hội </template>
          <template v-else> Cập nhật thông tin </template>

          <v-spacer />

          <v-icon aria-label="Close" @click="hideModal"> mdi-close </v-icon>
        </v-card-title>
        <v-card-text class="px-6">
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="required|max:255"
            name="Tên"
          >
            <v-text-field
              v-model="social.title"
              label="Tên"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:2000"
            name="Liên kết"
          >
            <v-text-field
              v-model="social.link"
              label="Liên kết"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            class="mb-2"
            rules="max:255"
            name="Thứ tự (tăng dần)"
          >
            <v-text-field
              v-model="social.stt"
              label="Thứ tự"
              :error-messages="errors"
              color="primary"
            />
          </validation-provider>

          <v-file-input
            v-model="inputFile"
            placeholder="Ảnh minh họa"
            label="Ảnh minh họa"
            prepend-icon="mdi-paperclip"
            :value="_.get(social, 'iconUrl', '')"
            @change="uploadOneFile"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="primary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>

          <validation-provider class="mb-2" name="Trạng thái">
            <v-select
              v-model="social.status"
              label="Trạng thái"
              color="primary"
              :items="statusOptions"
              item-text="text"
              item-value="value"
            />
          </validation-provider>
        </v-card-text>

        <v-card-actions class="px-0 group-btn--form">
          <v-btn color="success" @click.prevent="handleSubmit(saveSocial)">
            Lưu lại
          </v-btn>
          <v-btn class="ml-0" color="primary" @click="hideModal">
            Hủy bỏ
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { socialStatusOptions } from "../../../helpers/constants";
import RepositoryFactory from "../../../repositories/RepositoryFactory";
const ImageRepository = RepositoryFactory.get("image");
const SocialRepository = RepositoryFactory.get("social");

export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      social: {},
      isCreate: true,
      errorMessage: "",
      statusOptions: socialStatusOptions,
      inputFile: [],
    };
  },
  created() {
    Bus.$on("social-show-form", (social) => {
      this.showModal = true;
      this.social = _.cloneDeep(social);
      if (social.id) this.isCreate = false;
      else this.isCreate = true;
    });
  },
  methods: {
    hideModal: function () {
      this.showModal = false;
      this.isCreate = true;
      this.errorMessage = "";
      this.$refs.validator.reset();
    },
    async saveSocial() {
      const social = {
        title: _.trim(this.social.title),
        stt: _.trim(this.social.stt),
        link: _.trim(this.social.link),
        iconUrl: _.trim(this.social.iconUrl),
        status: this.social.status,
      };
      const loader = this.$loading.show();
      let response = {};
      if (this.isCreate) {
        response = await SocialRepository.create(social);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Thêm thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("social-reload");
          });
        }
      } else {
        response = await SocialRepository.update(this.social.id, social);
        loader.hide();
        if (!response.success) {
          this.errorMessage = _.get(response, "message", "");
        } else {
          this.errorMessage = "";
          this.showModal = false;
          this.$swal({
            title: "Cập nhật thành công",
            confirmButtonText: "Đồng ý",
          }).then(() => {
            Bus.$emit("social-reload");
          });
        }
      }
    },
    async uploadOneFile(file) {
      const loading = this.$loading.show();
      const uploadResponse = await ImageRepository.upload(file);
      loading.hide();
      if (_.get(uploadResponse, "success", false)) {
        const imageUrl = _.get(uploadResponse, "data.data.path", "");
        if (imageUrl) this.$set(this.social, "iconUrl", imageUrl);
      } else {
        this.$swal("Upload ảnh không thành công");
      }
    },
  },
};
</script>
